import React, { Component } from 'react';
import ComponentView from './view';
import preProcess from '../../preprocess';
import { Form, message } from 'antd';
import { getObjectsDiff } from 'utils/common';
import { PROFILE_IMAGES_PATH } from '../../../config'
import { getEmail, uploadImage } from 'utils/firebase';
import { createAction, ActionNames } from 'app-redux/actions'

const spinningSelector = ".new-form"

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {

    /**
     * Container
     * @param props
     */
    constructor(props) {
        super(props);
        this.onChange=this.onChange.bind(this);

    }

    /**
     * on select all clicked
     * @param {*} e 
     */
    onChange(e){
        const {form}=this.props;
        const {setFieldsValue}=form;
        if(e.target.checked){
            setFieldsValue({
                permissions:['Create', 'Read', 'Update', 'Delete']
            })
        } else {
            setFieldsValue({
                permissions:[]
            })
        }
    }

    /**
     * Sets Validations for fields
     */
    setValidations() {
        const { translate, data } = this.props;
        this.validations = {
            key: {
                rules: [{ required: true, message: translate('permission.key.required') }],
                initialValue: data && data.key
            },
            description: {
                rules: [{ required: true, message: translate('permission.description.required') }],
                initialValue: data && data.description
            },
            type: {
                rules: [{ required: true, message: translate('permission.type.required') }]
            },
            permissions: {
                rules: [{ required: true, message: translate('permission.permissions.required') }]
            },
            entity: {
                rules: [{ required: true, message: translate('permission.entity.required') }]
            }
        }
    }


    

    /**
     * ComponentDidMount Hook
     */
    componentDidMount() {

    }

    /**
     * handleSubmit 
     * @param {*} event 
     */
    handleSubmit(event) {
        event.preventDefault();
        const { form, translate, createRecord, hideModal, updateRecord, data, getTableData } = this.props;
        const { validateFieldsAndScroll } = form;
        validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return;
            }
            window.startSpinning(spinningSelector);
            try {
                // in case of edit
                if (data) {
                    const { description } = values;
                    const { error, payload } = await updateRecord({
                        entityId: data.key,
                        description
                    });
                    if (error) {
                        throw payload.response;
                    }
                } else {
                    values.entity = values.entity.toUpperCase();
                    const { key, type, permissions, entity,description } = values;
                    if (type === 'CRUD') {
                        await Promise.all(permissions.map(async op=>{
                            const { error, payload } = await createRecord({
                                key: (entity + '_' + op).toUpperCase(),
                                description: (op + ' for ' + entity)
                            });
                            if (error) {
                                throw payload.response;
                            }
                        }));
                    }
                    if(type==='Custom'){
                        const { error, payload } = await createRecord({
                            key:key.toUpperCase(),
                            description
                        });
                        if (error) {
                            throw payload.response;
                        }
                    }
                }
                message.success(translate("common.changes.save.success"));
                if (hideModal instanceof Function) {
                    hideModal();
                }
                if (getTableData instanceof Function) {
                    getTableData();
                }
            } catch (e) {
                if (e && e.status === 409) {
                    message.error(translate('permission.already.exist'));
                } else {
                    message.error(translate("common.changes.save.error"));
                }
            }
            window.stopSpinning(spinningSelector);
        });
    }


    /**
     * Render Method
     * @returns {*}
     */
    render() {
        return (ComponentView.bind(this))();
    }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {
        createRecord: (data) => {
            return dispatch(createAction(ActionNames.CREATE_ENTITY, {
                entityName: "permission",
                ...data
            }))
        },
        updateRecord: ({ entityId, ...data }) => {
            return dispatch(createAction(ActionNames.UPDATE_ENTITY, {
                entityName: "permission",
                entityId,
                ...data
            }))
        }
    }
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {

    return {}
};
Main.displayName = "Media-Form";
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
    connect: [mapStateToProps, bindAction],
    localize: true
});
