import React, { useEffect, useState } from 'react'
import './styles.scss'
import organic from '../../images/organic5.jpg'
import preProcess from 'containers/preprocess'
import Share from '../../components/share'
import PostActions from '../../containers/postActions'
import {
    createAction,
    ActionNames,
    getApiActionExecuter,
} from '../../redux/actions'
import { goToRoute } from '../../routes'

const tags = ['tag1', 'tag2']
/**
 * @description Newsfeed Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({newsfeed, newsfeed_single, getData, params: {id}}) => {
    const [data, setData] = useState(newsfeed_single && newsfeed_single.key === id ? newsfeed_single : null)
    useEffect(() => {
        if (data) {
            return
        }
        window.startSpinning('.post')
        let post = newsfeed.find((post) => {
            return id === post.key
        })
        if (post) {
            setData(post)
            window.stopSpinning('.post')
        } else {
            (async () => {
                try {
                    post = await getApiActionExecuter(getData)(id)
                    setData(post)
                } catch (e) {
                    goToRoute('error404')
                }
                window.stopSpinning('.post')
            })()
        }
    }, [])
    return data ? (
        <div className="post">
            <div className="content">
                <div className="card">
                    <div className="image">
                        <img src={data.image}/>
                    </div>
                    <div className="project">
                        <div className="hashtags">
                            {(data.tags || []).map((tag, index) => {
                                return (
                                    <p key={index} className="hashtag">
                                        {'#' + tag}
                                    </p>
                                )
                            })}
                        </div>
                        <div className="title">
                            <p className="text">
                                {data.title}
                            </p>
                        </div>
                        <div className="description" dangerouslySetInnerHTML={{__html: data.description}}></div>
                        <div className="share">
                            <Share link={window.location.href}/>
                        </div>
                    </div>
                    {/* <PostActions/> */}
                </div>
            </div>
        </div>
    ) : null
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {
        getData: (entityId) => {
            const action = createAction(ActionNames.GET_ENTITY, {
                entityName: 'newsfeed',
                entityId,
            })
            action.type = ActionNames.GET_ENTITY + '_newsfeed'
            return dispatch(action)
        },
    }
}
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({newsfeed, newsfeed_single}) => {
    return {
        newsfeed: newsfeed.data || [],
        newsfeed_single
    }
}
Main.displayName = 'Newsfeed-Post-Container'
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, bindAction],
    localize: true,
})
