import React from "react";
import { FileViewComponent } from "core-components/file-picker";
import DuplicateForm from 'containers/duplicate-entity';
export default {
  entity: "product-category",
  entityName: "Product Categories",
  form : {
    elements: [
      {
        type: "text",
        name: "name",
        label: "product-category.title.name",
        required: true,
      },
      {
        type: "file",
        name: "image",
        label: "product-category.title.image",
        required: true,
        inputProps: {
          accept: "image/*",
          multiple: false,
        },
        fileCode: "productImage",
      },
      {
        type: "text",
        name: "description",
        label: "product-category.title.description",
        required: true,
      },
    ]
  },
  //db: "mysql",
  pagination: {
    type: "server",
  },
  defaultSort: {
    sort: "createdAt",
    sortType: "asc",
  },
  rowActions:[
    props=>{
      const {record,getTableData}=props;
      const {key}=record;      
      return(
        <DuplicateForm
          entityName='product-category'
          entityId={key.toString()}
          getData={getTableData}
          auto = {true}
        />
      )
    }
  ],
  columns: ({ sharedState: { translate } }) => [
    {
      title: translate("product-category.title.name"),
      dataIndex: "name",
    },
    {
      title: translate("product-category.title.image"),
      dataIndex: "image",
      render: (value, { type }) => {
        return (
          <div className="image">
            <FileViewComponent url={value} type={"image"} />
          </div>
        );
      },
    }
  ],
  search: {
    filters: [
      {
        title: "Name",
        type: "input",
        key: "name",
      }
    ],
    searchOnSubmit: true,
  },
};
