import Link from 'core-components/link'
import React from 'react'
import { Icon } from 'antd'

var menuKeys = [
    {
        key: 'landing',
        name: 'Home',
    },
    {
        key: 'concept',
        name: 'Concept',
    },
    {
        key: 'services',
        name: 'Services',
    },
    {
        key: 'newsfeed',
        name: 'Good NewsFeed',
    },
     {
        key: 'newsfeed',
        name: 'shop',
        url : "https://site.ecopence.com/"
    },
    {
        key: 'support',
        url : 'https://site.ecopence.com/contact/',
        name: 'Contact Us',
    },
]

export default menuKeys
