import React from 'react'
import './styles.scss'
import { Form, Button, Input, Icon, Tooltip } from 'antd'


const {Item} = Form
/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
    const {partsIndex} = this.state
    const {hideModal, form, getRules, data, translate,title,subtitle,formTitle} = this.props
    let {fields} = this.props
    if (fields instanceof Function) {
        fields = fields(translate)
    }
    const {getFieldDecorator} = form
    return (
        <div className='meta-tags-form'>
            <Form onSubmit={this.handleSubmit}>
                <div className="new-form seo-form">
                    <div className="form">
                        <div className="form-section">
                            <p className="section-label">{title}</p>
                            <div className="content">
                                <div className='crud'>
                                    <div className='header'>
                                        <p></p>
                                        <Button className="green-btn" onClick={this.addPart}>
                                            <Icon type="plus"/>Add New
                                        </Button>
                                    </div>
                                    <div className="crud-content">
                                        {
                                            partsIndex.map((num, index) => {
                                                return (
                                                    <div key={num} className="inline">
                                                        {
                                                            fields.map((field, index2) => {
                                                                const {key, label, placeholder} = field
                                                                const rules = getRules(key, num, translate)
                                                                const initialValue = data && data[num] && data[num][key]
                                                                return (
                                                                    <Item key={index2} hasFeedback={true} label={label}>
                                                                        {
                                                                            getFieldDecorator('data[' + num + '].' + key, {
                                                                                rules,
                                                                                initialValue
                                                                            })(
                                                                                <Input.TextArea placeholder={placeholder}/>
                                                                            )
                                                                        }
                                                                    </Item>
                                                                )
                                                            })
                                                        }
                                                        <Tooltip title={'Delete'}>
                                                            <Button className="red-btn-text delete-btn"
                                                                    onClick={() => { this.removePart(index) }}>
                                                                <Icon type="delete"/>
                                                            </Button>
                                                        </Tooltip>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="actions">
                            <Button htmlType={'submit'} type="primary" className="btn green-btn">Save</Button>
                            <Button htmlType={'button'} onClick={hideModal} className="btn red-btn-text">Cancel</Button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
export default view
