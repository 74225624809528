export function getAmountInCurrency(amount) {
  return window.app.currency + " " + amount.toString();
}

export function getAmountInCents(amount) {
  return window.app.subCurrency + " " + amount.toString();
}

export function convertCentsToCurrency(amount) {
  return amount / 100;
}

export function getSinceYear(date) {
  return new Date(!isNaN(date) ? parseInt(date) : date).getFullYear().toString();
}


export function getGuaranteedPointValue(gradingValue){
  return Math.round((0.2 - (0.1 * gradingValue/100))*100);
}

export function getPointsFromLoyalty(amount,loyalty){
  return Math.floor((amount * loyalty * 10) / 100);
}