import React from "react";
import Paypal from "./gateways/paypal";
import Razorpay from "./gateways/razorpay";
import Stripe from "./gateways/stripe";
import Braintree from "./gateways/braintree";
//import './styles.scss';
/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
  const { name } = this.props;
  const { sdkLoaded } = this.state;
  let Component = null;
  if (sdkLoaded) {
    switch (name.toLowerCase()) {
      case "paypal": {
        Component = Paypal;
        break;
      }
      case "razorpay": {
        Component = Razorpay;
        break;
      }
      case "stripe": {
        Component = Stripe;
        break;
      }
      case "braintree": {
        Component = Braintree;
        break;
      }
    }
  }
  return (
    <div className="method">
      {sdkLoaded ? (
        <div className="pay">{Component && <Component {...this.props} />}</div>
      ) : (
        <div>
          <p>Loading...</p>
        </div>
      )}
    </div>
  );
};
export default view;
