import React, { Component } from "react";
import preProcess from "containers/preprocess";
import "./styles.scss";
import { Icon, Select, Form, Input, Button, message } from "antd";
import { createAction, ActionNames } from "app-redux/actions";
const spinningSelector = ".section.newsletter";
const FormItem = Form.Item;
/**
 * @description Subscribe Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ form, subscribe, translate }) => {
  const { validateFieldsAndScroll, resetFields, getFieldDecorator } = form;
  const handleSubmit = (event) => {
    event.preventDefault();
    validateFieldsAndScroll(async (errors, { email }) => {
      if (errors) {
        return;
      }
      window.startSpinning(spinningSelector);
      try {
        const { error, payload } = await subscribe({ email });
        if (error) {
          throw error;
        }
        resetFields();
        message.success(translate("landing.subscribe.success"));
      } catch (e) {
        message.error(translate("common.server.error.unexpected"));
      }
      window.stopSpinning(spinningSelector);
    });
  };
  return (
    <div className="section newsletter">
      <p className="title">SUBSCRIBE TO RECEIVE THE LATEST PRODUCT TRENDS.</p>
      <div className="form">
        <Form onSubmit={handleSubmit.bind(this)}>
          <FormItem hasFeedback={true}>
            {getFieldDecorator("email", {
              rules: [
                {
                  required: true,
                  message: translate("common.email.error.required"),
                },
                {
                  type: "email",
                  message: translate("common.email.error.invalid"),
                },
              ],
            })(<Input placeholder="Enter your email" className="input" />)}
          </FormItem>
          <div className="action">
            <Button htmlType={"submit"} className="green-btn">
              SUBSCRIBE
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    subscribe: (data) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "subscribe",
          ...data,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};
Main.displayName = "Sample-Container";
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
