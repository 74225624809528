import React, { Component } from "react";
import "./styles.scss";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Checkbox,
  Select,
  Switch,
} from "antd";
import FilePicker from "../file-picker";
import Editor from "../CKEditorComponent";
/**
 * @description Form Component
 * @type component
 * @author Inderdeep
 */
const Main = ({
  elements,
  form,
  actions,
  submitText,
  onSubmit,
  onError,
  requiredMessage,
  formProps,
}) => {
  const { getFieldDecorator, getFieldsValue } = form;
  if (!elements instanceof Array) {
    elements = [];
    console.warn("Elements should be a array");
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    const { validateFieldsAndScroll } = form;
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        onError instanceof Function && onError(errors, values, form);
        return;
      }
      onSubmit instanceof Function && onSubmit(values, form);
    });
  };

  const renderElement = (element, index) => {
    if (!element) {
      return null;
    }
    let input = null;
    let {
      type,
      label,
      name,
      options,
      inputProps,
      optionProps,
      Component,
      data,
      required,
    } = element;
    let hideLabel = false;
    type = (type || "text").toLowerCase();
    inputProps = inputProps || {};
    options = {
      ...options,
    };
    options.rules = [...(options.rules || [])];
    inputProps.placeholder = inputProps.placeholder || label;

    switch (type) {
      case "text":
        input = (
          <Input
            className={`input ${inputProps.className || ""}`}
            {...inputProps}
          />
        );
        break;
      case "textarea":
        input = (
          <Input.TextArea
            className={`input ${inputProps.className || ""}`}
            {...inputProps}
          />
        );
        break;
      case "number":
        input = (
          <InputNumber
            className={`input ${inputProps.className || ""}`}
            {...inputProps}
          />
        );
        break;
      case "select":
        input = (
          <Select
            className={`select ${inputProps.className || ""}`}
            {...inputProps}
          >
            {(data || []).map(({ label, value }, index) => {
              return (
                <Select.Option {...optionProps} key={index} value={value}>
                  {label}
                </Select.Option>
              );
            })}
          </Select>
        );
        break;
      case "switch":
        input = <Switch {...inputProps}></Switch>;
        options.valuePropName = "checked";
        break;
      case "checkbox":
        hideLabel = true;
        input = <Checkbox {...inputProps}>{element.label}</Checkbox>;
        break;
      case "html":
        input = <Editor {...inputProps}>{element.label}</Editor>;
        break;
      case "file":
        input = (
          <FilePicker
            uploadProps={{
              accept: "*/*",
              multiple: false,
              ...inputProps,
            }}
          ></FilePicker>
        );
        break;
      case "custom":
        input = <Component {...inputProps} />;
    }
    if (required) {
      options.rules.push({
        required: true,
        message: requiredMessage || "This field is required",
      });
    }
    input = getFieldDecorator(name, options)(input);
    return (
      <Form.Item
        key={typeof index === undefined ? Math.random() : index}
        hasFeedback={true}
        label={!hideLabel ? element.label || "" : undefined}
        {...element.formItemProps}
      >
        {input}
      </Form.Item>
    );
  };

  return (
    <Form onSubmit={handleSubmit} {...formProps}>
      <div className="form">
        {elements.map((element, index) => {
          if (element.condition instanceof Function) {
            element = {
              ...element,
              ...element.condition({
                element,
                values: getFieldsValue(),
              }),
            };
          }
          if (
            element.type === "inline" &&
            element.elements instanceof Array &&
            element.elements.length
          ) {
            return (
              <div className="inline" key={index}>
                {element.elements.map((obj, i) => {
                  return renderElement(obj, i);
                })}
              </div>
            );
          } else {
            return renderElement(element, index);
          }
        })}
      </div>
      <div className="actions">
        <Button htmlType={"submit"} className="btn green-btn">
          {submitText || "Save"}
        </Button>
        {actions}
      </div>
    </Form>
  );
};
Main.displayName = "Form-Component";
export default Form.create()(Main);
