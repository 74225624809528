import React from 'react';
import './styles.scss';
import {Layout, Button, Card, Form, Input} from 'antd';
var view = function () {
    const {translate}=this.props;
    return (
        <div className="page confirm-email">
            <div className="general-form">
                <div className="content">
                    <div className="form">
                        <div className="fields">
                            <p className="title">{translate('common.notice')}</p>
                            <p className="description">{translate('common.account.not.approved')}</p>
                            {/*<Form>*/}
                                {/*<div className="action">*/}
                                    {/*<Button className="button blue submit-btn" htmlType={'button'} onClick={this.resendEmail}>Resend Email</Button>*/}
                                {/*</div>*/}
                            {/*</Form>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default view;
