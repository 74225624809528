import React, { useState, useEffect } from "react";
import preProcess from "containers/preprocess";
import Form from "core-components/form";
import CrudForm from "../../crud-form";
import { shippingTypes, productStatus } from "../../../config";
import {
  createAction,
  ActionNames,
  getApiActionExecuter,
} from "app-redux/actions";
import "./styles.scss";
import { parseJSON } from "utils/common";

/**
 * @description Product Form Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ getCategories, translate, data, role, ...props }) => {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    (async () => {
      const data = await getApiActionExecuter(getCategories)();
      setCategories(data);
    })();
  }, []);

  if (data && data.image) {
    data = {
      ...data,
    };
    data.image = parseJSON(data.image);
  }
  const formElements = [
    {
      type: "text",
      name: "name",
      label: "product.title.name",
      required: true,
    },
    {
      type: "text",
      name: "sku",
      label: "product.title.sku",
      required: true,
    },
    {
      type: "textarea",
      name: "shortDescription",
      label: "product.title.shortDescription",
      required: true,
    },
    {
      type: "textarea",
      name: "description",
      label: "product.title.description",
      required: true,
    },
    {
      type: "file",
      name: "image",
      label: "product.title.image",
      required: true,
      inputProps: {
        accept: "image/*",
        multiple: true,
      },
      fileCode: "productImage",
      maxFileCount: 5,
    },
    {
      type: "select",
      name: "category",
      label: "product.title.category",
      required: true,
      data: categories.map(({ name, key }) => {
        return {
          label: name,
          value: key.toString(),
        };
      }),
    },
    {
      type: "select",
      name: "shippingType",
      label: "product.title.shippingType",
      required: true,
      data: shippingTypes,
    },
    {
      label: "product.title.shippingValue",
      name: "shippingValue",
      required: true,
      condition: ({ values }) => {
        return {
          type:
            values.shippingType === shippingTypes[2].value ? "text" : "number",
          inputProps:
            values.shippingType === shippingTypes[1].value
              ? {
                  min: 0,
                  max: 100,
                }
              : undefined,
        };
      },
    },
    {
      type: "number",
      name: "price",
      label: "product.title.price",
      required: true,
      inputProps: {
        min: 1,
      },
    },
    ...(role === "admin"
      ? [
          {
            type: "select",
            name: "status",
            label: "product.title.status",
            required: true,
            data: productStatus,
          },
        ]
      : []),
  ];
  return (
    <CrudForm
      data={data}
      preSubmit={(values, update) => {
        values.image = JSON.stringify(values.image);
        // if (!update && !values.status) {
        //   values.status = productStatus[0].value;
        // }
        if (values.shippingValue) {
          values.shippingValue = values.shippingValue.toString();
        }
      }}
      entity="product"
      elements={formElements}
      {...props}
    />
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    getCategories: (data) => {
      return dispatch(
        createAction(ActionNames.GET_ENTITIES, {
          entityName: "product-category",
          all: true,
          ...data,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user }) => {
  return {
    role: user.role,
  };
};
Main.displayName = "Product-Form-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
