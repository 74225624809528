import React, { useState, useRef } from "react";
import preProcess from "containers/preprocess";
import { Button, Tooltip, Icon, message } from "antd";
import "./styles.scss";
import { ModalTrigger } from "core-components/modal";
import Form from "core-components/form";
import { createAction, ActionNames } from "app-redux/actions";
import { getAmountInCurrency, getPointsFromLoyalty } from "../../container-helpers/common";
/**
 * @description Approve-Pending-Request Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ record, loyality, approve, getTableData,getPointBalance }) => {
  const modalRef = useRef(null);
  const { key: requestId, details: requestDetails } = record;

  let details = {};
  try {
    details = JSON.parse(requestDetails || {});
  } catch (e) {
    console.error("Error while parsing requestDetails");
  }

  const onSubmit = async (values) => {
    try {
      window.startSpinning(".form");
      const { error, payload } = await approve({ ...values, requestId });
      if (error) {
        throw payload;
      } else {
        getPointBalance();
        message.success("Request approved successfully");
        modalRef.current.hideModal();
        getTableData();
      }
    } catch (e) {
      console.error({ e });
      message.error((e.response && e.response.data && e.response.data.message) ||  "Error while approving this request. Please try again");
    }
    window.stopSpinning(".form");
  };
  const [points, setPoints] = useState(
    details.amount ? getPointsFromLoyalty(details.amount,loyality): 0    
  );
  return (
    <ModalTrigger
      ref={modalRef}
      content={
        <React.Fragment>
          {details.amount && (
            <p className="points-text">
              Amount entered by customer : {getAmountInCurrency(details.amount)}{" "}
            </p>
          )}
          <Form
            key="form"
            elements={[
              {
                type: "number",
                name: "amount",
                label: `Amount (${window.app.currency})`,
                inputProps: {
                  placeholder: "Amount paid for the order",
                  required: true,
                  onChange: (e) => {
                    setPoints(getPointsFromLoyalty(e,loyality));
                  },
                },
                required: true,
                options: {
                  initialValue: details.amount,
                },
              },
            ]}
            onSubmit={onSubmit}
            submitText="Approve"
          />
        </React.Fragment>
      }
      modalProps={{
        title: "Approve Request",
        footer: (
          <p className="points-text">
            Number of Coins to be credited : {points}
          </p>
        ),
      }}
    >
      <Tooltip placement="top" title={"Approve"}>
        <Button className="edit">
          <Icon type="check" />
        </Button>
      </Tooltip>
    </ModalTrigger>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    approve: (data) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "points-request",
          entityId: "approveRequest",
          ...data,
        })
      );
    },
    getPointBalance: () => {
      const action = createAction(ActionNames.GET_ENTITY, {
        entityName: "points-request",
        entityId: "getPointBalance",
      });
      action.type = action.type + "_points-request";
      return dispatch(action);
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user }) => {
  return {
    loyality: user.loyality,
  };
};
Main.displayName = "Approve-Pending-Request";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
