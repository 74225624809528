import React, { Component } from 'react';
import ComponentView from './view';
import preProcess from '../../preprocess';
import { Form, message } from 'antd';
import { getObjectsDiff } from '../../../utils/common';
import { PROFILE_IMAGES_PATH } from '../../../config'
import { getEmail, uploadImage } from 'utils/firebase';
import { createAction, ActionNames } from '../../../redux/actions'
import { getGuaranteedPointValue } from '../../container-helpers/common';

const spinningSelector = ".new-form"

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {

    /**
     * Container
     * @param props
     */
    constructor(props) {
        super(props);
        this.setValidations();
        this.state = {
            roles : []
        }
        
    }

    /**
     * Sets Validations for fields
     */
    setValidations() {
        const { translate, data } = this.props;
        this.validations = {
            name: {
                rules: [
                    { required: true, message: translate('common.firstName.error.required') }
                ],
                initialValue: data ? data.displayName : null
            },
            email: {
                rules: [
                    { required: !data && true, message: translate('common.email.error.required') },
                    { type: "email", message: translate('common.email.error.invalid') }
                ],
                initialValue: data ? getEmail(data) : null
            },
            password: {
                rules: [{ required: !data && true, message: translate('common.password.error.required') }]
            },
            photoURL: {
                initialValue: data ? data.photoURL : null
            },
            // role: {
            //     rules: [
            //         { required: true, message: translate('user.role.required') }
            //     ],
            //     initialValue: data ? ((data.customClaims || {}).role) : ""
            // },
            merchantType: {
                rules: [
                    { required: true, message: translate('user.merchantType.required') }
                ],
                initialValue: data ? (data.merchantType || "") : ""
            },
            b2bMerchant: {
                initialValue: data && data.b2bMerchant,
                valuePropName: "checked"
            },
            autoConfirmProduct: {
                initialValue: data && data.autoConfirmProduct,
                valuePropName: "checked"
            },
            loyality: {
                rules: [
                    { required: true, message: translate('user.loyality.required') }
                ],
                initialValue: data ? data.loyality : null
            },
            grading: {
                initialValue: data ? data.grading : null,
                rules: [
                    { required: true, message: translate('user.grading.required') }
                ],
            },
            setup: {
                initialValue: data ? data.setup : null
            },
            guaranteedPointValue: {
                initialValue: data ? data.guaranteedPointValue : null
            },
        }
    }

    /**
     * ComponentDidMount Hook
     */
    async componentDidMount() {
        // const { getRoles } = this.props;
        // try {
        //     const {payload,error} = await getRoles();
        //     if(error){
        //         throw payload;
        //     }
        //     this.setState({
        //         roles : payload.data.data
        //     })
        // } catch (e) {
        //     console.error("Error while fetching roles", e);
        //     message.error("Error while fetching roles");
        // }
    }

    handleSubmit(event) {
        event.preventDefault();
        const { validateFieldsAndScroll } = this.props.form;
        const { translate, createUser, getTableData, hideModal, updateUser, data, createStorage } = this.props;
        validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return;
            }
            values.role = this.props.role;
            window.startSpinning(spinningSelector);
            try {
                let action;
                values.guaranteedPointValue = getGuaranteedPointValue(values.grading);
                //If image has been changed                
                if (data) {
                    if (values.photoURL instanceof File) {
                        try {
                            values.photoURL = encodeURI((await createStorage(values.photoURL)).payload.data.url);
                        } catch (e) {
                            console.error("Photo Upload Error ", e);
                            message.error(translate("common.changes.save.error"));
                        }
                    }
                    let updatedValues = getObjectsDiff(values, data);
                    action = await updateUser({
                        ...updatedValues,
                        uid: data.uid,
                    });
                } else {
                    action = await createUser(values);
                }
                //Update profile

                if (!action.error) {
                    message.success(translate("common.changes.save.success"));
                    hideModal();
                } else {
                    throw action.payload.response;
                }
                if (getTableData instanceof Function) {
                    getTableData();
                }
            } catch (err) {
                if (err.status === 409) {
                    message.error(translate("signUp.duplicate.email"))
                } else {
                    message.error(translate("common.changes.save.error"));
                }
            }
            window.stopSpinning(spinningSelector);

        });
    }


    /**
     * Render Method
     * @returns {*}
     */
    render() {
        return (ComponentView.bind(this))();
    }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {
        createUser: (data) => {
            return dispatch(createAction(ActionNames.CREATE_ENTITY, {
                entityName: "users",
                type: 'local',
                ...data
            }))
        },
        updateUser: (data) => {
            const entityId = data.uid;
            delete data.uid;
            return dispatch(createAction(ActionNames.UPDATE_ENTITY, {
                entityName: "users",
                entityId,
                ...data
            }))
        },
        getRoles: () => {
            const action = createAction(ActionNames.GET_ENTITIES, {
                entityName: 'roles',
                from: -1
            },true);
            return dispatch(action)
        },
        createStorage: file => {
            return dispatch(createAction(ActionNames.CREATE_ENTITY, {
                entityName: 'storage',
                file: file.dataUri,
                name: file.name,
                type: 'image',
                fileCode: "userImage"
            }))
        }
    }
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ roles }) => {

    return {
        
    }
};
Main.displayName = "User-Form";
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
    connect: [null, bindAction],
    localize: true
});
