import React from 'react'
import './styles.scss'
import { Row, Col, Icon } from 'antd'
import about from '../../images/organic5.jpg'
import values from '../../images/organic4.jpg'
import choose from '../../images/organic2.jpg'
import Footer from '../footer'
import Banner from '../../components/banner'

var view = function () {
    return (
        <div className="concept">
            <Banner title={'Concept'}/>
            <div className="section discover">
                <div className="container">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10} className="column">
                            <div className="image">
                                <img src={about} alt={'About'}/>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={14} xl={14} className="column">
                            <div className="details">
                                <div className="heading">
                                    <div className="title">
                                        <p>A New Concept You Can Count On</p>
                                    </div>
                                </div>
                                <div className="content description">
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus,
                                        odio praesentium, ex ipsum deleniti repellat aut dignissimos,
                                        sunt vel quasi enim ad alias nam quod debitis voluptatem eum eius earum.
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus,
                                        odio praesentium, ex ipsum deleniti repellat aut dignissimos
                                    </p>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus,
                                        odio praesentium, ex ipsum deleniti repellat aut dignissimos,
                                        sunt vel quasi enim ad alias nam quod debitis voluptatem eum eius earum.
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus,
                                        odio praesentium, ex ipsum deleniti repellat aut dignissimos
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="section values fill-bg">
                <div className="container">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10} className="column">
                            <div className="image">
                                <img src={values} alt={'Our Values'}/>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={14} xl={14} className="column">
                            <div className="details">
                                <div className="heading">
                                    <div className="title">
                                        <p>Organic products for healthy life</p>
                                    </div>
                                </div>
                                <div className="content description">
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus,
                                        odio praesentium, ex ipsum deleniti repellat aut dignissimos,
                                        sunt vel quasi enim ad alias nam quod debitis voluptatem eum eius earum.
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus,
                                        odio praesentium, ex ipsum deleniti repellat aut dignissimos
                                    </p>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus,
                                        odio praesentium, ex ipsum deleniti repellat aut dignissimos,
                                        sunt vel quasi enim ad alias nam quod debitis voluptatem eum eius earum.
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus,
                                        odio praesentium, ex ipsum deleniti repellat aut dignissimos
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="section why">
                <div className="container">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={14} xl={14} className="column">
                            <div className="details">
                                <div className="heading">
                                    <div className="title">
                                        <p>Eco friendly products </p>
                                    </div>
                                </div>
                                <div className="content features">
                                    <p className="description">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus,
                                        odio praesentium, ex ipsum deleniti repellat aut dignissimos,
                                        sunt vel quasi enim ad alias nam quod debitis voluptatem eum eius earum
                                    </p>
                                    <Row>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="column">
                                            <div className="feature">
                                                <div className="name">
                                                    <p><Icon type="bulb"/>Innovative Solution</p>
                                                </div>
                                                <div className="text">
                                                    <p>We are comitted to offering you reliable import export goods in
                                                        an
                                                        expeditious manner.
                                                        We have solid track record of fullfilling the shipping of
                                                        logistical
                                                        needs</p>

                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="column">
                                            <div className="feature">
                                                <div className="name">
                                                    <p><Icon type="hourglass"/>Stratergic Thinking</p>
                                                </div>
                                                <div className="text">
                                                    <p>We are comitted to offering you reliable import export goods in
                                                        an
                                                        expeditious manner.
                                                        We have solid track record of fullfilling the shipping of
                                                        logistical
                                                        needs</p>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="column">
                                            <div className="feature">
                                                <div className="name">
                                                    <p><Icon type="team"/>Experienced Team</p>
                                                </div>
                                                <div className="text">
                                                    <p>We are comitted to offering you reliable import export goods in
                                                        an
                                                        expeditious manner.
                                                        We have solid track record of fullfilling the shipping of
                                                        logistical
                                                        needs</p>

                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="column">
                                            <div className="feature">
                                                <div className="name">
                                                    <p><Icon type="thunderbolt"/>Creative Approach</p>
                                                </div>
                                                <div className="text">
                                                    <p>We are comitted to offering you reliable import export goods in
                                                        an
                                                        expeditious manner.
                                                        We have solid track record of fullfilling the shipping of
                                                        logistical
                                                        needs</p>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10} className="column">
                            <div className="image">
                                <img src={choose} alt={'Why Us'}/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}
export default view
