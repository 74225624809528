import React, { useState } from 'react'
import preProcess from 'containers/preprocess'
import './styles.scss'
import { Row, Col, Breadcrumb, Select, Checkbox, Button, Icon, Drawer ,Radio} from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import ProductCard from '../productCard'

const { Option } = Select
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */

const Main = () => {
    const [showMobileFilters, setMobileFilter] = useState(false);
    const [showMobileSorting, setMobileSorting] = useState(false);
    return (

        <div className="catalogue">
            <Row>
                {console.log(!showMobileFilters)}
                <Col xs={0} sm={0} md={6} lg={6} xl={4}
                    className={'filter-column ' + (showMobileFilters ? 'mobile-show animated slideInUp' : '')}>
                    <div className="filters">
                        <p className="title">Filters</p>
                        <Button className="close" onClick={() => setMobileFilter(false)}><Icon
                            type="close" /></Button>
                        <div className="filter">
                            <p className="label">Price Range</p>
                            <div className="price-range block list">
                                <Checkbox>$20 - $80</Checkbox>
                                <Checkbox>$80 - $120</Checkbox>
                                <Checkbox>$120 - $250</Checkbox>
                                <Checkbox>$250 - $320</Checkbox>
                            </div>
                        </div>
                        <div className="filter">
                            <p className="label">Category</p>
                            <div className="price-range block list">
                                <Checkbox>Category 1</Checkbox>
                                <Checkbox>Category 2</Checkbox>
                                <Checkbox>Category 3</Checkbox>
                                <Checkbox>Category 4</Checkbox>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={18} lg={18} xl={20} className="column list">
                    <div className="header">
                        <div className="breadcrumb">
                            <Breadcrumb>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <a href="">Categories</a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Products</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <div className="sorting">
                            <div className="form">
                                {
                                    showMobileSorting ? (
                                        <Drawer
                                            placement="bottom"
                                            closable={true}
                                            onClose={() => setMobileSorting(false)}
                                            visible={true}
                                        >
                                            <Radio.Group defaultValue={1}>
                                                <Radio value={1}>Sort By: Most Popular</Radio>
                                                <Radio value={2}>Sort By: Recently Added</Radio>
                                                <Radio value={3}>Price: High to Low</Radio>
                                                <Radio value={4}>Price: Low to High</Radio>
                                            </Radio.Group>
                                        </Drawer>
                                    ) :
                                        (
                                            <Select className="select" defaultValue={'Popularity'}>
                                                <Option value="Popularity">Sort By: Most Popular</Option>
                                                <Option value="recentlyAdded">Sort By: Recently Added</Option>
                                                <Option value="highToLow">Price: High to Low</Option>
                                                <Option value="lowToHigh">Price: Low to High</Option>
                                            </Select>
                                        )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="products">
                        <InfiniteScroll dataLength={10}>
                            <Row gutter={24}>
                                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                                    <ProductCard />
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                                    <ProductCard />
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                                    <ProductCard />
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                                    <ProductCard />
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                                    <ProductCard />
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                                    <ProductCard />
                                </Col>
                            </Row>
                        </InfiniteScroll>
                    </div>
                </Col>
            </Row>
            <div className="mobile-actions">
                <Button className="filter-btn" onClick={() => setMobileSorting(showMobileSorting => !showMobileSorting)}><Icon
                    type="control" /> Sort</Button>
                <span className="separator" />
                <Button className="filter-btn" onClick={() => setMobileFilter(showMobileFilters => !showMobileFilters)}><Icon
                    type="filter" /> Filter</Button>
            </div>
        </div>
    )
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {}
}
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {

    return {}
}
Main.displayName = 'Sample-Container'
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, null],
    localize: true
})
