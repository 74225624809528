import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Icon, Select } from "antd";

const Option = Select.Option;
/**
 * @description Change Language Component
 * @type Container
 * @author Inderdeep
 */
const Main = ({ defaultValue }) => {
  const [value, setValue] = useState(defaultValue || "en");
  useEffect(()=>{
    document.querySelector("select.goog-te-combo") && document.querySelector("select.goog-te-combo").value !== "" ? setValue(document.querySelector("select.goog-te-combo").value) : null
  })
  return (
    <div className="language">
      <Icon type="global" />
      <Select
        className="change-language"
        defaultValue={value}
        value={value}
        onSelect={(value) => {
          setValue(value);
          window.changeGoogleTranslateLanguage instanceof Function &&
            window.changeGoogleTranslateLanguage(value);
        }}
        //getPopupContainer={(trigger) => trigger.parentNode}
      >
        <Option value="en">English</Option>
        <Option value="fr">French</Option>
        <Option value="it">Italian</Option>
        <Option value="de">German</Option>
        <Option value="es">Spanish</Option>
        <Option value="pt">Portuguese</Option>
        <Option value="ru">Russian</Option>
      </Select>
    </div>
  );
};

Main.displayName = "Change-Language";
//Pre process the container with Redux Plugins
export default Main;
