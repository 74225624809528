import React, { useEffect, useState } from "react";
import "./styles.scss";
import Banner from "../../components/banner";
import { Row, Col, Avatar, Button, Icon } from "antd";
import preProcess from "containers/preprocess";
import star from "../../images/star.png";
import MerchantCard from "../../containers/merchantCard";
import { getSinceYear } from "../container-helpers/common";

/**
 * @description Performers Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ merchants }) => {
  return (
    <div className="performers">
      <div className="content">
        {/* <Banner title={'Top Performers'}/> */}
        <div className="section">
          <div className="container">
            <div className="heading">
              <p className="title">Meet our top performers</p>
            </div>
            <div className="list">
              <Row gutter={24}>
                {(merchants || []).map((item, index) => {
                  return (
                    <Col key={index} xs={24} sm={12} md={8} lg={8} xl={6}>
                      <MerchantCard
                        merchant={{
                          name: item.displayName,
                          rating: "Top 1%",
                          image: item.photoURL,
                          since: getSinceYear(item.createdAt),
                          profile: item.uid,
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ config }) => {
  const { merchants } = config || {};
  return {
    merchants: merchants ? merchants.merchants : [],
  };
};
Main.displayName = "Performers-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
