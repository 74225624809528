import React from 'react'
import './styles.scss'
import { Input, Button, Form, Checkbox } from 'antd'
import Link from '../../../components/link'
import SocialSignIn from '../../social-signin'
const {Item} = Form
/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
    const {translate, form, ui} = this.props
    const {getFieldDecorator} = form
    this.setValidations()
    const {password, email} = this.validations;
    return (
        <div className="general-form login">
            <div className="content">
                <div className="form">
                    <div className="fields">
                        <p className="title">Log In</p>
                        <Form onSubmit={this.handleSubmit.bind(this)}>
                            <Item hasFeedback={true}>
                                {
                                    getFieldDecorator('email', email)
                                    (
                                        <Input placeholder={translate('common.email.placeholder')}
                                               className="input"
                                        />
                                    )
                                }
                            </Item>
                            <Item hasFeedback={true}>
                                {
                                    getFieldDecorator('password', password)
                                    (
                                        <Input
                                            type={'password'}
                                            placeholder={translate('common.password.placeholder')}
                                            className="input"
                                        />
                                    )
                                }
                            </Item>
                            <div className="more-links inline">
                                <div className="remember">
                                    <Item hasFeedback={true}>
                                        {
                                            getFieldDecorator('rememberMe', {valuePropName: 'checked'})
                                            (
                                                <Checkbox>Remember Me</Checkbox>
                                            )
                                        }
                                    </Item>
                                </div>
                                <div className="forgot">
                                    <Link onClick={ui.activeTab ? this.toggleForm.bind(this, 'forgot') : null}
                                          routeKey={ui.activeTab ? null : 'forgotPassword'}
                                    >
                                        Forgot Password ?
                                    </Link>
                                </div>
                            </div>
                            <div className="action">
                                <Button htmlType={'submit'} className="btn green-btn">
                                    Log In
                                </Button>
                            </div>
                        </Form>
                    </div>
                    <div className="options shared">
                        <div className="option">
                            <p className="optionLabel">Need new account?</p>
                            <Link
                                className="textLink"
                                onClick={ui.activeTab ? this.toggleForm.bind(this, 'Register') : null}
                                routeKey={ui.activeTab ? null : 'Register'}
                            >
                                Register
                            </Link>
                        </div>
                        <div className="option">
                            <p className="optionLabel second">Continue with</p>
                           <SocialSignIn/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default view
