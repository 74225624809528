import React, {Component} from 'react';
import ComponentView from './view';
import preProcess from 'containers/preprocess';
import {createAction,ActionNames} from 'app-redux/actions';
import {goToRoute } from "../../routes";
import { mobileAndTabletcheck } from "utils/web";


/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {

    /**
     * Container
     * @param props
     */
    constructor(props) {
        super(props);
        

    }

    
    

    /**
     * ComponentDidMount Hook
     */
    componentDidMount() {

    }
    
    closeDrawer() {
        const { setDrawer } = this.props;
        setDrawer(false);
      }
    
    /**
     * Render Method
     * @returns {*}
     */
    handleMenuClick(e) {
        
       // goToRoute(e.key);
        mobileAndTabletcheck() && this.closeDrawer();
    }

    render() {
        return (ComponentView.bind(this))();
    }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {
        setDrawer: (drawer) => {
            return dispatch(
              createAction(ActionNames.UI_SET_PROPERTY, {
                name: "drawer",
                value: drawer,
              })
            );
          },
    }
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {

    return {}
};
Main.displayName = "Sample-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, bindAction],
    localize: true
});
