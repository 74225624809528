import React from "react";
import "./styles.scss";
import { Row, Col, Icon } from "antd";
import Config, { TYPE_1, TYPE_2, TYPE_3 } from "./config";
import Banner from "../../components/banner";

var view = function () {
  const { route } = this.props;
  const { pageConfiguration } = route;
  const { title, bannerImage } = pageConfiguration;
  const sections = Config[route.routeKey] || [];
  return (
    <div className="about">
      <Banner title={title} background={bannerImage} />
      {sections.map(({ type, count, data }, index) => {
        index = index + 1;
        let markup = null;
        let className = "";
        switch (type) {
          case TYPE_1:
            className = "discover";
            markup = (
              <div className="content description">
                <p>{pageConfiguration[`section${index}Content`]}</p>
              </div>
            );
            break;
          case TYPE_2:
            className = "values fill-bg";
            markup = (
              <div className="content values">
                {new Array(count).fill(1).map((val,index2) => {
                  val = index2 + 1;
                  return (
                    <div className="value" key={val}>
                      <p className="name">
                        {pageConfiguration[`section${index}Subtitle${val}`]}
                      </p>
                      <p>
                        {
                          pageConfiguration[
                            `section${index}Subtitle${val}Content`
                          ]
                        }
                      </p>
                    </div>
                  );
                })}
              </div>
            );
            break;
          case TYPE_3:
            className = "why";
            markup = (
              <div className="content features">
                <p className="description">
                  {pageConfiguration[`section${index}Content`]}
                </p>
                <Row>
                  {(data || []).map((val, index2) => {
                    index2 = index2 + 1;
                    return (
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                        key={index}
                        className="column"
                      >
                        <div className="feature">
                          <div className="name">
                            <p>
                              <Icon type={val} />
                              {
                                pageConfiguration[
                                  `section${index}Subtitle${index2}`
                                ]
                              }
                            </p>
                          </div>
                          <div className="text">
                            <p>
                              {
                                pageConfiguration[
                                  `section${index}Subtitle${index2}Content`
                                ]
                              }
                            </p>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            );
            break;
        }
        const imageMarkUp = (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={10}
                    xl={10}
                    className="column"
                  >
                    <div className="image">
                      <img
                        src={pageConfiguration[`section${index}Image`]}
                        alt={pageConfiguration[`section${index}Title`]}
                      />
                    </div>
                  </Col>
                );
        return (
          <div className={`section ${className}`}>
            <div className="container">
              <Row className={type === TYPE_3 ? "flex" : ""}>
                {type !== TYPE_3 && imageMarkUp}
                <Col xs={24} sm={24} md={24} lg={14} xl={14} className="column">
                  <div className="details">
                    <div className="heading">
                      <div className="title">
                        <p>{pageConfiguration[`section${index}Title`]}</p>
                      </div>
                    </div>
                    {markup}
                  </div>
                </Col>
                {type === TYPE_3 && imageMarkUp}
              </Row>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default view;
