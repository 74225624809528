import React, { Component } from 'react'
import { Modal, Input, Form, Button } from 'antd'
import './styles.scss'

/**
 * Confirm Form Component
 */
class ConfirmFormComponent extends Component {
    /**
     * On Form Submit
     */
    onSubmit (event) {
        event.preventDefault()
        const {onSubmit, hideModal} = this.props
        const {validateFieldsAndScroll} = this.props.form
        validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return
            }
            if (onSubmit instanceof Function) {
                onSubmit(values, {
                    hideModal,
                    startSpinning: () => {
                        window.startSpinning('.confirm-form-modal .ant-modal-body')
                    },
                    stopSpinning: () => {
                        window.stopSpinning('.confirm-form-modal .ant-modal-body')
                    }
                })
            }
        })
    }

    /**
     * Render Method
     * @returns {*}
     */
    render () {
        const {
            form,
            validationRules,
            okText,
            inputLabel,
            className,
            hideModal,
            disableCancel,
            actions,
            placeholder,
            extraItems,
            inputProps
        } = this.props
        const {getFieldDecorator} = form
        return (
            <Form className={className} onSubmit={this.onSubmit.bind(this)}>
                <div className="confirm-input new-form">
                    <div className="form">
                        <Form.Item label={inputLabel || 'Value'}>
                            {getFieldDecorator('value', {
                                rules: validationRules || [
                                    {required: true, message: 'Please enter a value'}
                                ]
                            })(
                                <Input
                                    className="input"
                                    placeholder={placeholder || 'Enter Value'}
                                    {...inputProps}
                                />
                            )}
                        </Form.Item>
                        {(extraItems || []).map(
                            ({label, key, validationRules, element, placeholder, value,inputProps}, index) => {
                                return (
                                    <Form.Item label={label} key={index}>
                                        {getFieldDecorator(key, {
                                            rules: validationRules || [
                                                {required: true, message: 'Please enter a value'}
                                            ],
                                            initialValue: value
                                        })(
                                            element ? element : (
                                                <Input className="input" placeholder={placeholder} {...inputProps}/>
                                            )
                                        )}
                                    </Form.Item>
                                )
                            }
                        )}
                    </div>
                    <div className="actions">
                        <Button htmlType={'submit'} className="green-btn">
                            {'Save' || {okText}}
                        </Button>
                        {
                            !disableCancel && (
                                <Button
                                    onClick={hideModal}
                                    type={'default'}
                                    htmlType={'button'}
                                    className="red-btn-text"
                                >
                                    Cancel
                                </Button>
                            )
                        }
                        {actions}
                    </div>
                </div>
            </Form>
        )
    }
}

export const ConfirmForm = Form.create()(ConfirmFormComponent)

/**
 * Wrapped Modal ConfirmForm
 * @param config
 * @returns {{destroy: () => void; update: (newConfig: ModalFuncProps) => void}}
 */
export default function (config, hoc) {
    const defaultConfig = {}
    config = config || {}
    let WrappedConfirmForm = Form.create()(ConfirmFormComponent)

    config = {
        ...defaultConfig,
        modalConfig: {
            icon: null,
            ...defaultConfig.modalConfig,
            ...config.modalConfig,
            content: (
                <WrappedConfirmForm
                    {...config}
                    hideModal={() => {
                        ConfirmModal.destroy()
                    }}
                />
            ),
            footer: null,
            closable: true,
            className: `confirm-form-modal ${(config.modalConfig &&
                config.className) ||
            ''}`,
            renderFooter: () => {
                return null
            }
        }
    }
    const ConfirmModal = Modal.info(config.modalConfig)
}
