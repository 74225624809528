import React, { Component } from 'react';
import ComponentView from './view';
import preProcess from '../../preprocess';
import { Form, message } from 'antd';
import { getObjectsDiff } from '../../../utils/common';
import { PROFILE_IMAGES_PATH } from '../../../config'
import { getEmail, uploadImage } from 'utils/firebase';
import { createAction, ActionNames } from '../../../redux/actions'

const spinningSelector = ".new-form"

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {

    /**
     * Container
     * @param props
     */
    constructor(props) {
        super(props);
        this.setValidations();
    }

    /**
     * Sets Validations for fields
     */
    setValidations() {
        const { data , translate} = this.props;
        this.validations = {
            routeUrl: {
                rules: [{
                    required: true,
                    message: translate('systemPage.routeUrl.required')
                }],
                ...data && data.routeUrl && {
                    initialValue: data.routeUrl
                }
            },
            pageTitle: {                
                initialValue: data ? data.pageTitle : undefined
            }
        }
    }

    /**
     * ComponentDidMount Hook
     */
    componentDidMount() {
        
    }

    handleSubmit(event) {
        event.preventDefault();
        const { form, translate, createRecord, hideModal, updateRecord, data, getTableData } = this.props;
        const { validateFieldsAndScroll } = form;
        validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return;
            }
            window.startSpinning(spinningSelector);
            try {
                if (data) {
                    const { key } = data;
                    const { error, payload } = await updateRecord({
                        entityId: key,
                        ...getObjectsDiff(values, data),
                    });
                    if (error) {
                        throw payload.response;
                    }
                } else {
                    const { error, payload } = await createRecord(values);
                    if (error) {
                        throw payload.response;
                    }
                }
                if (hideModal instanceof Function) {
                    hideModal();
                }
                if (getTableData instanceof Function) {
                    getTableData();
                }
            } catch (e) {
                console.log(e);
                message.error(translate("common.changes.save.error"));
            }
            window.stopSpinning(spinningSelector);

        });
    }


    /**
     * Render Method
     * @returns {*}
     */
    render() {
        return (ComponentView.bind(this))();
    }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {
        createRecord: (data) => {
            return dispatch(createAction(ActionNames.CREATE_ENTITY, {
                entityName: "page-configuration",
                ...data
            }))
        },
        updateRecord: ({ entityId, ...data }) => {
            return dispatch(createAction(ActionNames.UPDATE_ENTITY, {
                entityName: "page-configuration",
                entityId,
                ...data
            }))
        }
    }
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
    const { data } = state['page-configuration']||{};
    return {
        pageConfiguration:data||[]
    }
};
Main.displayName = "User-Form";
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
    connect: [mapStateToProps, bindAction],
    localize: true
});
