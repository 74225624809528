/**
 * Common configuration for each environment
 * @type {{}}
 */
export default {
    HOST: process.env.SERVER || "http://localhost:8080",
    encryptionKey:process.env.ENCRYPTION_KEY || 'hor22kida',
    API_PREFIX: "/api/",
    socialLogin: {
        providers : ["google","facebook","twitter"],
        defaults: {
            protocol: "http",
            host: "localhost:3000",
            transport: "session",
            state: true,
            nonce: true,
            scope: [
                'openid'
            ],
            callback: '/oauth/callback'
        },
        google: {
            key: "",
            secret: "",
            scope: [
                'https://www.googleapis.com/auth/userinfo.email',
                'https://www.googleapis.com/auth/userinfo.profile'
            ],
            responseFormatter: function ({ raw }) {
                const { access_token, id_token } = raw;
                return {
                    accessToken: access_token, idToken: id_token
                };
            }
        },
        facebook: {
            key: '',
            secret: '',
            scope: [
                "public_profile",
                "email"
            ],
            responseFormatter: function ({ access_token }) {
                return {
                    token: access_token 
                };
            }
        },
        twitter: {
            key: '',
            secret: '',
            responseFormatter: function ({ access_token, access_secret }) {
                return {
                    token: access_token,
                    secret: access_secret
                };
            }
        }
    }
};
