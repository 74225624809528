import React from "react";
import "./styles.scss";
import { Input, Button, Form, Checkbox } from "antd";
import Link from "../../../components/link";
import SocialSignIn from "../../social-signin";
const FormItem = Form.Item;
/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
  const { translate, form, ui,merchant } = this.props;
  const { getFieldDecorator } = form;
  const {
    password,
    email,
    firstName,
    lastName,
    confirmPassword,
    agreement,
  } = this.validations;
  
  return (
    <div className="general-form register">
      <div className="content">
        <div className="form">
          <div className="fields">
            <p className="title">{translate("common.register.title")}</p>
            <Form onSubmit={this.handleSubmit.bind(this)}>
              <FormItem hasFeedback={true}>
                {getFieldDecorator(
                  "firstName",
                  firstName
                )(
                  <Input
                    type={"text"}
                    placeholder={merchant ? "Business Name" : "Name"}
                    className="input"
                  />
                )}
              </FormItem>
              {/* <FormItem hasFeedback={true}>
                {getFieldDecorator(
                  "lastName",
                  lastName
                )(
                  <Input
                    type={"text"}
                    placeholder={translate("common.lastName.placeholder")}
                    className="input"
                  />
                )}
              </FormItem> */}
              <FormItem hasFeedback={true}>
                {getFieldDecorator(
                  "email",
                  email
                )(
                  <Input
                    placeholder={translate("common.email.placeholder")}
                    className="input"
                  />
                )}
              </FormItem>
              <FormItem hasFeedback={true}>
                {getFieldDecorator(
                  "password",
                  password
                )(
                  <Input
                    type={"password"}
                    placeholder={translate("common.password.placeholder")}
                    className="input"
                  />
                )}
              </FormItem>
              <FormItem hasFeedback={true}>
                {getFieldDecorator(
                  "confirmPassword",
                  confirmPassword
                )(
                  <Input
                    type={"password"}
                    placeholder={translate(
                      "common.confirm-password.placeholder"
                    )}
                    className="input"
                  />
                )}
              </FormItem>
             
              <div className="more-links">
                {/* <Form.Item>
                  {getFieldDecorator("merchant", {}, {
                    valuePropName: "checked",
                  })(
                    <Checkbox>
                      {translate("signUp.check.merchant")}
                    </Checkbox>
                  )}
                </Form.Item> */}
                <Form.Item>
                  {getFieldDecorator("agreement", agreement, {
                    valuePropName: "checked",
                  })(
                    <Checkbox>
                      {translate("common.agree")}
                      <Link
                        target="_blank"
                        routeKey="staticPages.terms"
                        className="brand-color-text"
                      >
                        {" "}
                        {translate("common.terms")}{" "}
                      </Link>{" "}
                      {translate("common.agreement")}
                    </Checkbox>
                  )}
                </Form.Item>
              </div>
              <div className="action">
                <Button
                  htmlType={"submit"}
                  className="btn green-btn"
                  id="sign-in-btn"
                >
                  {translate("common.register.title")}
                </Button>
              </div>
            </Form>
          </div>
          <div className="options shared">
            <div className="option">
              <p className="optionLabel">Already have a account?</p>
              <Link
                className="textLink"
                onClick={
                  ui.activeTab ? this.toggleForm.bind(this, "login") : null
                }
                routeKey={ui.activeTab ? null : "login"}
              >
                {translate("common.login")}
              </Link>
            </div>
            <div className="option">
              <p className="optionLabel second">Continue with</p>
              <SocialSignIn />
            </div>
          </div> 
        </div>
      </div>
    </div>
  );
};
export default view;
