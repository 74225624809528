import React from 'react'
import './styles.scss'
import Header from '../header'
import Footer from '../footer'
import { Layout, Button } from 'antd'
import Navigation from '../navigation'
import Link from 'core-components/link'
import { mobileAndTabletcheck } from 'utils/web'
import HeaderTopLink from '../headerTopLink'

const {Sider} = Layout

var view = function () {
    const {children, isReady, auth, horizontalMenu} = this.props

    const {pageReady} = this.state
    const route = this.getCurrentRoute()
    let {header, footer} = route
    const {ui} = this.props
    const {drawer} = ui
    const isAdminRoute =
        route && route.routeKey && route.routeKey.startsWith('home.')
    const isEmailNotConfirmedPage = route.routeKey === 'emailNotConfirm'
    return (
        <div className="app-container">
            {route.public || pageReady ? (
                <div>
                    {header !== false ? (
                        <Header
                            type={header}
                            currentRoute={route}
                            drawer={drawer}
                            toggleDrawer={this.toggleDrawer.bind(this)}
                        />
                    ) : null}

                    <div
                        className={
                            'main-container ' +
                            (!route.public ? 'secured ' : '') +
                            (isAdminRoute ? 'admin ' : ' ') +
                            (isEmailNotConfirmedPage ? 'no-space ' : ' ') +
                            (header == false ? ' no-header' : (header && header.type) || '') +
                            (horizontalMenu ? ' with-horizontal-menu' : {})
                        }
                    >
                        {route.public || isReady
                            ? !auth.uid && !route.public
                                ? null
                                : children
                            : null}
                    </div>
                    {/*<MobileFooter currentRoute={route}/>*/}
                </div>
            ) : null}
            {drawer && !isAdminRoute && mobileAndTabletcheck() ? (
                <Sider
                    collapsible={true}
                    collapsed={!drawer}
                    className="sider public"
                    collapsedWidth={0}
                    breakpoint="md"
                    width={250}
                >
                    <Navigation/>
                    <div className="actions">
                        <ul>
                            {!(auth && auth.uid) && (
                                <li className="merchant-register">
                                    <Link routeKey="Register" queryParams={{merchant: true}}>
                                        <Button className="green-btn small-btn">
                                            Merchant Registration
                                        </Button>
                                    </Link>
                                </li>
                            )}
                            {!(auth && auth.uid) && (
                                <li className="user-register">
                                    <Link onClick={this.toggle} routeKey="Register">
                                        <Button className="green-btn small-btn">Sign Up</Button>
                                    </Link>
                                </li>
                            )}
                            {!(auth && auth.uid) && (
                                <li className="user-login">
                                    <Link onClick={this.toggle} routeKey="login">
                                        <Button className="green-btn-text">Login</Button>
                                    </Link>
                                </li>
                            )}
                            {(auth && auth.uid) && (
                                <li className="logout">
                                    <Link onClick={() => {
                                        this.toggle()
                                        this.logout()
                                    }}>
                                        <Button className="green-btn-text">Logout</Button>
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                </Sider>
            ) : null}
            {drawer && !isAdminRoute && mobileAndTabletcheck() ? (
                <Link className="sider-overlay" onClick={this.toggle}/>
            ) : null}
            {!isAdminRoute && route.footer !== false && <Footer/>}
        </div>
    )
}
export default view
