import React from 'react'
import { ModalTrigger } from 'core-components/modal'
import { Button, Icon, Tooltip } from 'antd'
import SeoForm from '../containers/seo-form'

export default {
    entity: 'language',
    entityName: 'Languages',
    selection: null,
    formId: 'LanguageForm',
    pagination: {
        type: 'server'
    },
    defaultSort: {
        sort: 'createdAt',
        sortType: 'asc'
    },
    search: {
        searchOnSubmit: true,
        filters: [{
            title: 'Name',
            type: 'input',
            key: 'name'
        }]
    },
    columns: ({sharedState: {translate}}) => [{
        title: translate('language.key.label'),
        dataIndex: 'key'
    }, {
        title: translate('language.name.label'),
        dataIndex: 'name'
    }],
    rowActions: [props => {
        const {record, getData} = props
        const {key, translations} = record
        return (
            <ModalTrigger
                modalProps={{
                    title: 'Manage Translation',
                    footer: null,
                    className: ('have-form-sections'),
                }}
                content={
                    <SeoForm
                        getData={getData}
                        entityId={key}
                        data={translations}
                        getRules={(key, num, translate) => ([{
                            required: true,
                            message: translate('language.translations.' + key + '.required') + num
                        }])}
                        entityName='language'
                        fieldName='translations'
                        fields={translate => [{
                            key: 'key',
                            label: translate('language.translations.key.label'),
                            placeholder: translate('language.translations.key.placeholder')
                        }, {
                            key: 'value',
                            label: translate('language.translations.value.label'),
                            placeholder: translate('language.translations.value.placeholder')
                        }]}
                    />
                }
            >
                <Tooltip title="Manage Translations">
                    <Button className="btn yellow-btn-text">
                        <Icon type="file-word"/>
                    </Button>
                </Tooltip>
            </ModalTrigger>
        )
    }]
}