import React from "react";
import { formatDate } from "utils/date-util";
export default {
  entity: "points-request/transactionHistory",
  entityName: "Transaction History",
  create: false,
  selection: false,
  deleteAction: false,
  db: "mysql",
  defaultSort: {
    sort: "createdAt",
    sortType: "desc",
  },
  pagination: {
    type: "server",
  },
  reducer: {
    entity: "transaction-history",
    stateKeys: [
      {
        key: "uid",
        fn: (state) => {
          return state.firebase.auth && state.firebase.auth.uid;
        },
      },
    ],
  },
  search: false,
  columns: ({ sharedState }) => {
    return [
      {
        title: "Date",
        dataIndex: "createdAt",
        render: (createdAt) => {
          return <p>{formatDate(createdAt)}</p>;
        },
      },
      {
        title: "Coins/Leaves",
        dataIndex: "points",
      },
      // {
      //   title: "Point Type",
      //   dataIndex: "pointType",
      //   className : "captialize"
      // },
      {
        title: "Type",
        dataIndex: "status",
        render: (createdAt, record) => {
          return record.transferToUser === sharedState.uid ? "Credit" : "Debit";
        },
      },
      {
        title: "Remarks",
        dataIndex: "transactionType",
        render: (type, record) => {
          switch (type) {
            case "purchase":
              return `Purchased ${record.points} ${record.pointType=== 'paid' ? 'Coins' : 'Leaves'} `;
            case "transfer":
              if(record.transactionId === "-1") {
                return `${record.points} Coins Added by Administrator`;
              } else if (record.transferToUser === sharedState.uid) {
                return `Transferred ${record.points} ${record.pointType=== 'paid' ? 'Coins' : 'Leaves'} from ${record.otherName}`;
              }  else {
                return `Transferred ${record.points} ${record.pointType=== 'paid' ? 'Coins' : 'Leaves'}  to ${record.otherName}`;
              }
            default:
              return "";
          }
        },
      },
    ];
  },
};
