import React from "react";
import { Icon, Tooltip } from "antd";
import Link from "core-components/link";

export default {
  entity: "newsfeed",
  entityName: "Posts",
  formId: "NewsfeedForm",
  pagination: {
    type: "server",
  },
  defaultSort: {
    sort: "createdAt",
    sortType: "desc",
  },
  columns: ({ sharedState: { translate } }) => [
    {
      title: translate("newsfeed.title.label"),
      dataIndex: "title",
    },
    {
      title: translate("newsfeed.title.shortDescription"),
      dataIndex: "shortDescription",
    },
    {
      title: "Type",
      dataIndex: "type",
      render : (val)=><span className = "captialize">{val}</span>
    },
  ],
  search: {
    filters: [
      {
        title: "Title",
        type: "input",
        key: "title",
      }
    ],
    searchOnSubmit: true,
  },
};
