import React from "react";
import ApiTrigger from '../containers/api-trigger';
import { api } from '../redux/config';
export default {
  entityName: "Configurations",
  entity: "configuration",
  pagination: {
    type: "server"
  },
  selection: false,
  //addAction: false,
  defaultSort: {
    sort: "createdAt",
    sortType: "desc"
  },
  deleteAction: false,
  columns: ({sharedState:{translate}}) => {
    return [
      {
        dataIndex: "key",
        title: translate('configuration.key.label')
      },
      {
        title: translate('configuration.type.label'),
        dataIndex: 'type'
      },
      {
        title: translate('configuration.value.label'),
        dataIndex: "value",
        render: (value, { type }) => {
          return type === "file" ? (
            <div>
              <a target="_blank" href={value}>
                Download
              </a>
              <div className="image">
                <img src={value} />
              </div>
            </div>
          ) : (
              <div>{value || 'false'}</div>
            );
        }
      },
      {
        title:  translate('configuration.description.label'),
        dataIndex: 'description'
      }
    ]
  },
  search: {
    filters: [
      {
        title: "Key",
        type: "input",
        key: "key"
      }
    ],
    searchOnSubmit: true
  },
  headerActions: [
    () => <ApiTrigger
      url={api.REFRESH_INDEX}
      title="Refresh Indexes"
      icon="sync"
      successMessage="Refreshing indexes initiated. Might take about a minute"
    />,
    () => <ApiTrigger
      url={api.REFRESH_CACHE}
      title="Refresh Cache"
      icon="sync"
      successMessage="Refreshing Cache initiated. Might take about a minute"
    />
  ],
  formId: "ConfigurationForm"
}