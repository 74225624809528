import React, { Component } from "react";
import ComponentView from "./view";
import preProcess from "containers/preprocess";
import { Form, message } from "antd";
import { createAction, ActionNames } from "app-redux/actions";
import { goToRoute } from "../../routes";
const spinningSelector = ".verify";
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.setValidations();
  }

  setValidations() {
    this.validations = {
      password: {
        rules: [{ required: true, message: "Password is Required" }],
      },
      verificationCode: {
        rules: [
          {
            required: true,
            message: "Code is Required",
          },
        ],
      },
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    const { form, code, verifyOtp } = this.props;
    const { validateFieldsAndScroll } = form;
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      const { password, verificationCode } = values;
      window.startSpinning(spinningSelector);
      try {
        const { error, payload } = await verifyOtp({
          entityId: code,
          password,
          verificationCode: parseInt(verificationCode),
        });
        if (error) {
          throw payload.response;
        }
        message.success("Password Changed Successfully");
        goToRoute("login");
      } catch (e) {
        console.log(e);
        if (e.status === 400) {
          message.error("Security Code is incorrect");
        } else {
          message.error("server error");
        }
      }
      window.stopSpinning(spinningSelector);
    });
  }

  /**
   * ComponentDidMount Hook
   */
  componentDidMount() {
    if (!this.props.code) {
      goToRoute("login");
    }
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    verifyOtp: ({ entityId, ...data }) => {
      return dispatch(
        createAction(ActionNames.UPDATE_ENTITY, {
          entityId,
          entityName: "password-reset",
          ...data,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ ui }) => {
  const { code } = ui;

  return {
    code,
  };
};
Main.displayName = "Sample-Container";
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
  connect: [mapStateToProps, bindAction],
  localize: true,
  firebase: true,
});
