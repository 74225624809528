import React from 'react'
import { Icon, Tooltip } from 'antd'
import Link from 'core-components/link'

export default {
    entity: 'page',
    entityName: 'Static Pages',
    formId: 'StaticPageForm',
    search: false,
    pagination: {
        type: 'server'
    },
    selection: false,
    deleteAction : false,
    addAction : false,
    defaultSort: {
        sort: 'createdAt',
        sortType: 'desc'
    },
    columns: ({sharedState: {translate}}) => [
        {
            title: translate('staticPage.title.label'),
            dataIndex: 'title'
        },
        {
            title: translate('staticPage.route.label'),
            dataIndex: 'route'
        }
    ],
    rowActions: [
        props => {
            const {record} = props
            const {route} = record || {}
            return (
                <Tooltip title="Visit">
                    <Link
                        className="ant-btn btn orange-btn-text visit"
                        target="_blank"
                        to={'/staticPages/' + route}
                    >
                        <Icon type="eye"/>
                    </Link>
                </Tooltip>
            )
        }
    ]
}