import React, { Component } from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Select, Button } from 'antd';
import ItemImage from '../../images/organic1.jpg'
const { Option } = Select;

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
    return (
        <div className="order">
            <div className="order-heading">
                <p className="number-of-orders"><span>14 orders </span>cancelled in</p>
                <div className="selection">
                    <Select defaultValue="6months">
                        <Option value="6months">Past 6 months</Option>
                        <Option value="1year">Past 1 year</Option>
                        <Option value="2year">Past 2 years</Option>
                        <Option value="3year">Past 3 years</Option>
                    </Select>
                </div>
            </div>
            <div className="orders">
                <div className="order-summary">
                    <div className="sections first-block">
                        <div className="flex">
                            <p className="type">orders placed</p>
                            <p>19 july 2020</p>
                        </div>
                        <div className="flex">
                            <p className="type">total</p>
                            <p>₹00.00</p>
                        </div>
                        <div className="flex">
                            <p className="type">ship to</p>
                            <p>Customer's Name</p>
                        </div>
                    </div>
                    <div className="sections">
                        <div className="flex">
                            <p className="type">order # 000-000-000</p>
                            <p>Order details</p>
                        </div>
                    </div>
                </div>

                <div className="order-placed">
                    <div className="sections">
                        <div className="details">
                            <div className="image">
                                <img src={ItemImage} />
                            </div>
                            <div className="about-order">
                                <p className="item-name">Item name in detail</p>
                                <p className="price">₹00.00</p>
                            </div>
                        </div>
                    </div>
                    <div className="sections">
                        <Button className="green-btn-text">Buy item</Button>
                    </div>
                </div>

                <div className="order-placed">
                    <div className="sections">
                        <div className="details">
                            <div className="image">
                                <img src={ItemImage} />
                            </div>
                            <div className="about-order">
                                <p className="item-name">Item name in detail Item name in detail Item name in detailItem name in detail Item name in detail Item name in detail Item name in detail Item name in detail</p>
                                <p className="price">₹00.00</p>
                            </div>
                        </div>
                    </div>
                    <div className="sections">
                        <Button className="green-btn-text">Buy item</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {}
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {

    return {}
};
Main.displayName = "Sample-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, null],
    localize: true
});
