import React, { useEffect, useState } from "react";
import "./styles.scss";
import Banner from "../../components/banner";
import { createAction, ActionNames } from "app-redux/actions";
import preProcess from "containers/preprocess";
import PaymentGateways from "core-components/payment-gateways";
import { getAmountInCurrency } from "../container-helpers/common";
/**
 * @description Newsfeed Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({
  gateway,
  userId,
  onSuccess: onSuccessFn,
  createClientToken,
  amount,
}) => {
  const [loading, setLoading] = useState(false);
  const getClientToken = async () => {
    window.startSpinning(".payment");
    const { payload, error } = await createClientToken({
      method: "braintree",
    });
    setTimeout(() => {
      window.stopSpinning(".payment");
    }, 500);
    if (!error) {
      return payload.data.clientToken;
    } else {
      throw {
        error,
        payload,
      };
    }
  };

  const onSuccess = async (key, data) => {
    setLoading(true);
    window.startSpinning(".payment");
    try {
      if (onSuccessFn instanceof Function) {
        await onSuccessFn({ key, data });
      }
    } catch (e) {
      console.error("Error while processing the payment ", { e });
    }
    window.stopSpinning(".payment");
    setLoading(false);
  };

  return (
    <div className="payment">
      <div className="content">
        <div className="section">
          {!loading && (
            <div>
              <p className="pay-amount">Payable Amount : {getAmountInCurrency(amount)}</p>
              <PaymentGateways
                onSuccess={onSuccess}
                gateway={(gateway || []).map((item) => ({
                  ...item,
                  additionalData: {
                    getClientToken,
                    options: {
                      customerId: userId,
                    },
                    //   amount: total,
                    //   CURRENCY
                  },
                }))}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    createClientToken: ({ method }) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "transaction/createClientToken",
          method,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ config, user }) => {
  const { payment, configuration } = config || {};
  const { gateway } = payment || {};
  const { CURRENCY, WEBSITELOGO } = configuration || {};
  return { gateway, CURRENCY, userId: user.uid };
};
Main.displayName = "Payment-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
