import React, { useEffect, useState } from 'react'
import './styles.scss'
import { Button } from 'antd'
import { createAction, ActionNames } from 'app-redux/actions'
import preProcess from 'containers/preprocess'
import { ModalTrigger } from 'core-components/modal'

/**
 * @description Newsfeed Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({label, ...props}) => {
    const button = (
        <div className="actions">
            <Button className="green-btn">{label || 'Buy'}</Button>
        </div>
    )
    return (
        <ModalTrigger
            modalId="Payment"
            modalProps={{
                title: 'Make Payment',
                footer: null,
                className: 'payment-modal',
                contentProps: {
                    ...props,
                },
            }}
        >
            {button}
        </ModalTrigger>
    )
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {}
}
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({config, user}) => {
    return {}
}
Main.displayName = 'Payment-Trigger'
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, bindAction],
    localize: true,
})
