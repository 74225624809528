import React, { Component } from 'react'
import preProcess from 'containers/preprocess'
import './styles.scss'
import { Button, Row, Col, Icon, Input } from 'antd'
import Link from 'core-components/link'
import paymentCards from '../../images/payment-cards.png'
import organic from '../../images/organic1.jpg'

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
    return (
        <div className="cart">
            <div className="container">
                <div className="heading">
                    <div className="title">
                        <p>Your Bag</p>
                    </div>
                    <div className="actions">
                        <Button className="continue green-btn-text">Continue Shopping</Button>
                        <Button className="checkout green-btn">Checkout Now</Button>
                    </div>
                </div>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <div className="item-details flex">
                            <div className="image section">
                                <img src={organic}/>
                            </div>
                            <div className="display flex">
                                <div className=" details flex">
                                    <div className="detail section">
                                        <p className="name">Product Name</p>
                                        <div className="product-details">
                                            <p className="price">$ 20</p>
                                        </div>
                                        <div className="save">
                                            <Link><Icon type="heart"/> Save</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="delete section">
                                <Link>
                                    <Icon type="delete"/>
                                </Link>
                            </div>
                        </div>
                        <div className="item-details flex">
                            <div className="image section">
                                <img src={organic}/>
                            </div>
                            <div className="display flex">
                                <div className=" details flex">
                                    <div className="detail section">
                                        <p className="name">Product Name</p>
                                        <div className="product-details">
                                            <p className="price">$ 20</p>
                                        </div>
                                        <div className="save">
                                            <Link><Icon type="heart"/> Save</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="delete section">
                                <Link>
                                    <Icon type="delete"/>
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <div className="total-amount">
                            <div className="promocode form">
                                <Input type="text" placeholder="Coupon Code" className="input"/>
                                <Button type="text" className="green-btn-text">Apply</Button>
                            </div>
                            <div className="border-bottom">
                                <div className="subtotal flex">
                                    <p>Item Subtotal</p>
                                    <p>$ 2000</p>
                                </div>
                                <div className="savings flex">
                                    <p>Your Savings</p>
                                    <p className="savings-amount">-$100</p>
                                </div>
                            </div>
                            <div className="border-bottom">
                                <div className="delivery flex">
                                    <p>Estimated Shipping</p>
                                    <p>N/A</p>
                                </div>
                            </div>
                            <div className="total flex">
                                <p>Total</p>
                                <p>$2000</p>
                            </div>
                            <div className="checkOut">
                                <Link routeKey="checkoutMethod">
                                    <Button type="text" className="green-btn">Proceed to checkout</Button>
                                </Link>
                            </div>
                        </div>
                        <div className="payment-method">
                            <p>Accepted Payment Methods :</p>
                            <img src={paymentCards} alt={'Payment Cards'}/>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {}
}
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {

    return {}
}
Main.displayName = 'Sample-Container'
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, null],
    localize: true
})
