import React from "react";
import "./styles.scss";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Checkbox,
  Select,
  Switch,
} from "antd";
import FilePicker from "../../../components/file-picker";
import { merchantTypes } from "../../../config";
import { getGuaranteedPointValue } from "../../container-helpers/common";
const FormItem = Form.Item;
var view = function () {
  const { translate, form, hideModal, data } = this.props;
  const { roles } = this.state;
  const { providerData } = data || {};
  const [provider] = providerData || [];
  const { providerId } = provider || {};
  const isSocial = providerId && providerId !== "password";
  const {
    name,
    email,
    password,
    role,
    photoURL,
    merchantType,
    b2bMerchant,
    loyality,
    setup,
    grading,
    guaranteedPointValue,
    autoConfirmProduct
  } = this.validations;
  const { getFieldDecorator, getFieldValue,setFieldsValue } = form;
  const isMerchant = this.props.role === "MERCHANT";
  

  return (
    <Form onSubmit={this.handleSubmit.bind(this)}>
      <div className="new-form user">
        <div className="form">
          {data ? (
            <FormItem
              hasFeedback={true}
              label={translate("user.phototURL.label")}
            >
              <div className="image">
                {getFieldDecorator("photoURL", photoURL)(<FilePicker />)}
              </div>
            </FormItem>
          ) : null}
          <FormItem hasFeedback={true} label={translate("user.name.label")}>
            {getFieldDecorator(
              "name",
              name
            )(
              <Input
                maxLength={200}
                type={"text"}
                placeholder={translate("common.firstName.placeholder")}
                className="input"
              />
            )}
          </FormItem>
          {!isSocial && (
            <div>
              <FormItem
                hasFeedback={true}
                label={translate("user.email.label")}
              >
                {getFieldDecorator(
                  "email",
                  email
                )(
                  <Input
                    maxLength={100}
                    placeholder={translate("common.email.placeholder")}
                    className="input"
                  />
                )}
              </FormItem>
              <FormItem
                hasFeedback={true}
                label={translate("user.password.label")}
              >
                {getFieldDecorator(
                  "password",
                  password
                )(
                  <Input
                    maxLength={200}
                    type={"password"}
                    placeholder={translate("common.password.placeholder")}
                    className="input"
                  />
                )}
              </FormItem>
            </div>
          )}
          {/* <FormItem hasFeedback={true} label={translate("user.role.label")}>
            {getFieldDecorator(
              "role",
              role
            )(
              <Select className="select">
                <Select.Option value={""}>Select Role</Select.Option>
                {roles.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.code}>
                      {item.description}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </FormItem> */}

          {isMerchant && (
            <div className="inline">
              <FormItem
                hasFeedback={true}
                label={translate("user.merchantType.label")}
              >
                {getFieldDecorator(
                  "merchantType",
                  merchantType
                )(
                  <Select className="select">
                    <Select.Option value={""}>
                      Select Merchant Type
                    </Select.Option>
                    {merchantTypes.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.value}>
                          {item.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
              <Form.Item label={translate("user.b2b.label")}>
                {getFieldDecorator(
                  "b2bMerchant",
                  b2bMerchant
                )(<Switch></Switch>)}
              </Form.Item>
              <Form.Item label={translate("user.autoConfirmProduct.label")}>
                {getFieldDecorator(
                  "autoConfirmProduct",
                  autoConfirmProduct
                )(<Switch></Switch>)}
              </Form.Item>
            </div>
          )}

          {isMerchant && (
            <div className="inline">
              <Form.Item label={translate("user.grading.label")}>
                {getFieldDecorator(
                  "grading",
                  grading
                )(
                  <InputNumber
                    maxLength={5}
                    min={0}
                    max={100}
                    onChange = {(e)=>{
                      setFieldsValue({"guaranteedPointValue" : getGuaranteedPointValue(e)})
                    }}
                    placeholder={translate("user.grading.label")}
                    className="input"
                  />
                )}
              </Form.Item>
              <Form.Item label={translate("user.setup.label")}>
                {getFieldDecorator(
                  "setup",
                  setup
                )(
                  <InputNumber
                    maxLength={5}
                    min={0}
                    max={100}
                    placeholder={translate("user.setup.label")}
                    className="input"
                  />
                )}
              </Form.Item>
              <Form.Item label={translate("user.loyality.label")}>
                {getFieldDecorator(
                  "loyality",
                  loyality
                )(
                  <InputNumber
                    maxLength={5}
                    min={0}
                    max={100}
                    placeholder={translate("user.loyality.label")}
                    className="input"
                  />
                )}
              </Form.Item>
              <Form.Item label={translate("user.guaranteedPointValue.label")}>
                {getFieldDecorator(
                  "guaranteedPointValue",
                  guaranteedPointValue
                )(
                  <InputNumber
                    maxLength={6}
                    disabled={true}
                    placeholder={translate("user.guaranteedPointValue.label")}
                    className="input"
                    // value={gradingValue ? Math.round((0.2 - (0.1 * gradingValue/100))*100) : ""}
                  />
                )}
              </Form.Item>
            </div>
          )}
        </div>
        <div className="actions">
          <Button htmlType={"submit"} className="btn green-btn save">
            {translate("user.save")}
          </Button>
          <Button
            htmlType={"button"}
            className="btn cancel"
            onClick={hideModal}
          >
            {translate("user.cancel")}
          </Button>
        </div>
      </div>
    </Form>
  );
};
export default view;
