import React from "react";
import "./styles.scss";
import { Layout, Button, Card } from "antd";
import Localize from "../localize";

const { Content } = Layout;
var view = function() {
  const { html, title } = this.props;
  return (
    <div className="public">
      <div className="container">
        {title && title !== "" && (
          <div className="heading">
            <p className="page-title">{title}</p>
          </div>
        )}
        <div
          className="content static-page"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      </div>
    </div>
  );
};
export default view;
