import React from "react";
import { Upload, Icon } from "antd";
//import './styles.scss';
const imageRegex = /\.(ase|art|bmp|blp|cd5|cit|cpt|cr2|cut|dds|dib|djvu|egt|exif|gif|gpl|grf|icns|ico|iff|jng|jpeg|jpg|jfif|jp2|jps|lbm|max|miff|mng|msp|nitf|ota|pbm|pc1|pc2|pc3|pcf|pcx|pdn|pgm|PI1|PI2|PI3|pict|pct|pnm|pns|ppm|psb|psd|pdd|psp|px|pxm|pxr|qfx|raw|rle|sct|sgi|rgb|int|bw|tga|tiff|tif|vtf|xbm|xcf|xpm|3dv|amf|ai|awg|cgm|cdr|cmx|dxf|e2d|egt|eps|fs|gbr|odg|svg|stl|vrml|x3d|sxd|v2d|vnd|wmf|emf|art|xar|png|webp|jxr|hdp|wdp|cur|ecw|iff|lbm|liff|nrrd|pam|pcx|pgf|sgi|rgb|rgba|bw|int|inta|sid|ras|sun|tga)$/;
const videoRegex = /\.(3g2|3gp|aaf|asf|avchd|avi|drc|flv|m2v|m4p|m4v|mkv|mng|mov|mp2|mp4|mpe|mpeg|mpg|mpv|mxf|nsv|ogg|ogv|qt|rm|rmvb|roq|svi|vob|webm|wmv|yuv)$/;
const audioRegex = /\.(mid|midi|rm|ram|wma|aac|wav|ogg|mp3|mp4)$/;
export function isImage(url) {
  return url.match(imageRegex) != null;
}
export function isAudio(url) {
  return url.match(audioRegex) != null;
}
export function isVideo(url) {
  return url.match(videoRegex) != null;
}
export const FileViewComponent = ({ url, type,disableDownload,...rest }) => {
  url = url || "";
  type = (type || "").toLowerCase();
  let icon, markup;
  if (type.indexOf("image") !== -1 || isImage(url)) {
    markup = <img width="200" src={url} {...rest}/>;
  } else if (type.indexOf("video") !== -1 || isVideo(url)) {
    markup = <video  controls width="200" height="200" src={url} {...rest}/>;
  } else if (type.indexOf("audio") !== -1 || isAudio(url)) {
    markup = <audio controls width="200" height="200" src={url} {...rest}/>;
  } else {
    switch (type) {
      case "application/pdf":
        icon = "file-pdf";
        break;
      case "text/html":
        icon = "global";
        break;
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        icon = "copy";
        break;
      default:
        icon = "file";
        break;
    }
    markup = <Icon type={icon} />;
  }
  return (
    <div className="uploaded-file">
      {markup}
      {!disableDownload && (
        <a className="download" href={url} target="_blank">
          <Icon type="download" />
        </a>
      )}
    </div>
  );
};
export const FileComponent = ({
  file,
  renderExtra,
  index,
  clearFile,
  exposedConfig,
  disableDownload,
}) => {
  let dataUri, type;
  if(typeof file === "string"){
    dataUri = file;
  } else {
    file = file || {};
    dataUri = file.dataUri || file.url;
    type = file.type;
  }

  return (
    <div className="file">
      <div className="data">
        <FileViewComponent url={dataUri} type={type} disableDownload = {disableDownload}/>
        {renderExtra instanceof Function
          ? renderExtra(file, index, exposedConfig)
          : null}
      </div>
      <a
        className="remove"
        onClick={(e) => {
          e.preventDefault();
          clearFile(index);
        }}
      >
        <Icon type="close" />
      </a>
    </div>
  );
};

/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
  const { getChildren, renderExtra } = this.props;
  if (getChildren) {
    if (!getChildren instanceof Function) {
      console.error("getChildren should be of type function");
      return null;
    }
    return (
      <span
        ref={(ref) => {
          this.uploadRef = ref;
        }}
      >
        <Upload {...this.getUploadProps()}>
          {getChildren(this.getExposedConfig())}
        </Upload>
      </span>
    );
  } else {
    const { multiple } = this.getUploadProps();
    const { fileList } = this.state;    
    return (
      <span
        ref={(ref) => {
          this.uploadRef = ref;
        }}
      >
        <Upload {...this.getUploadProps()}>
          {(multiple || fileList.length === 0) && (
            <div className="upload-content">
              <div>
                <p>
                  <Icon type="plus" />
                </p>
              </div>
            </div>
          )}
        </Upload>
        <div className="file-list">
          {fileList.map((file, index) => {
            return file ? (
              <FileComponent
                key={index}
                file={file}
                index={index}
                renderExtra={renderExtra}
                clearFile={this.clearFile.bind(this)}
                getExposedConfig={this.getExposedConfig()}
                disableDownload={file.uid}
              />
            ) : null;
          })}
        </div>
      </span>
    );
  }
};
export default view;
