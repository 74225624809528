import React from "react";
import preProcess from "containers/preprocess";
import "./styles.scss";
import {Button ,Icon} from "antd";
import {getRouteUrl} from '../../routes'
/**
 * @description Social Sign In Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ routes,location }) => { 
  const role = (location.query.merchant === "true" || location.query.merchant === true) ? "MERCHANT" : "CUSTOMER";
  const referralCode = location.query.referralCode && location.query.referralCode.trim() !== '' ? location.query.referralCode.trim() : undefined; 
  const providers =
    window.app && window.app.socialLogin ? window.app.socialLogin : {};
  const providerLength = Object.keys(providers).length;
  const socialLogin = (id) => {
    const { guestOnly } = routes[routes.length - 1];    
    if (guestOnly) {
      window.location.href = `/connect/${id}?nextPage=${getRouteUrl("home")}&role=${role}${referralCode ? `&${referralCode}`:''}`;
    } else {
      window.location.href = `/connect/${id}?nextPage=${window.location.pathname}&role=${role}${referralCode ? `&${referralCode}`:''}`;
    }
  };

  return providerLength > 0 ? (
    <div className="social">
      {Object.keys(providers).map((name, index) => {
        name = name || "";
        return providers[name] ? (
          <Button
            size = "large"
            className={name}
            shape="circle"
            onClick={() => {
              socialLogin(name);
            }}
            key={index}
            icon = {name.toLowerCase()}
          >
            {/* <Icon type = {name.toLowerCase()}/> */}
          </Button>
        ) : null;
      })}
    </div>
  ) : null;
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};
Main.displayName = "Social-Login-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
  withRouter : true
});
