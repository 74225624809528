import React from 'react'
import './styles.scss'
import SmartTable from 'core-components/smart-table'
import { ModalTrigger } from 'core-components/modal'
import { Button, Icon, Tooltip } from 'antd'
import * as cloneDeep from 'lodash/cloneDeep'
var view = function () {
    const {translate, entityName,entity, columns, search, data, selection, total, modalClass, children, formProps, editAction, addAction, routes, deleteAction, shouldShowActions,form} = this.props
    let {pagination,formId} = this.props;
    formId = form ? (entity + "Form") : formId;
    let {additionalState} = this.state
    pagination = pagination || {
        type: 'client'
    }
    const addRecord = (props) => {        
        return formId ? (
            <ModalTrigger modalId={formId}
                          modalProps={{
                              title: 'Create',
                              className: ('add-record-modal ' + (formId) + ' ' + modalClass),
                              footer: null,
                              contentProps: {
                                  ...formProps,
                                  getTableData: () => {
                                      this.getTableData(this.currentPagination)
                                  }
                              }
                          }}>
                <Button className='add'>
                    <span className="icon"><Icon type="plus" theme="outlined"/></span>
                    <span className="text">{translate('crud.create')}</span>
                </Button>
            </ModalTrigger>
        ) : null
    }
    const editRecord = (props) => {
        return (
            <ModalTrigger modalId={formId} modalProps={{
                title: 'Edit',
                className: ('edit-record-modal ' + (formId) + ' ' + modalClass),
                footer: null,
                contentProps: {
                    data: cloneDeep(props.record),
                    ...formProps,
                    getTableData: () => {
                        this.getTableData(this.currentPagination)
                    }
                }
            }}>
                <Tooltip placement="top" title={translate('crud.edit')}>
                    <Button className='edit'><Icon type="edit"/></Button>
                </Tooltip>
            </ModalTrigger>
        )
    }
    let batchActions = []
    let rowActions = []
    let headerActions = []
    if (editAction != false && typeof formId == 'string') {
        rowActions.push(editRecord)
    }
    if (deleteAction != false) {
        rowActions.push('delete')
        batchActions.push('delete')
    }
    if (addAction != false && typeof formId == 'string') {
        headerActions.push(addRecord)
    }

    rowActions = rowActions.concat((this.props.rowActions || []).map((Action) => {
        return (props2) => <Action {...props2} additionalState={additionalState}
                                   getTableData={this.getTableData.bind(this)} sharedState = {this.props}/>
    }))
    headerActions = headerActions.concat(this.props.headerActions || [])
    let tableColumns = []
    if (columns instanceof Function) {
        tableColumns = columns({
            sharedState: this.props,
            additionalState,
            getTableData : this.getTableData.bind(this)
        })
    } else {
        tableColumns = columns
    }
    let searchProps = {}
    if (search instanceof Function) {
        searchProps = search(this.props, additionalState)
    } else {
        searchProps = search
    }
    let selectionProps
    if (selection instanceof Function) {
        selectionProps = selection(this.props)
    } else {
        selectionProps = selection
    }
    return children ? children : (
        <div className='smart-table'>
            <SmartTable
                listConfig={{
                    bordered: true,
                    dataSource: data,
                    columns: tableColumns,
                    actions: rowActions,
                    onDelete: (record) => {
                        return this.deleteRecord(record.key, record)
                    },
                    selection: selectionProps == false ? undefined : {...selectionProps},
                    pagination: (pagination && pagination.type === 'server') ? {
                        total,
                        pageSize: pagination.pageSize || this.pageSize
                    } : undefined,
                    rowKey: 'key',
                    shouldShowActions: shouldShowActions instanceof Function ? shouldShowActions({
                        sharedState: this.props,
                        additionalState
                    }) : null
                }}
                entityName={entityName}
                //displayType = {displayType}
                paginationType={pagination.type}
                getData={this.getTableData.bind(this)}
                headerConfig={{
                    actions: headerActions,
                    batchActions,
                    onDeleteSelected: ({keys, rows}) => {
                        return this.deleteRecord(keys.join(','), rows)
                    },
                    search: searchProps
                }}
                title={entityName}
            />
        </div>
    )
}
export default view
