import React, { Component } from 'react'
import preProcess from 'containers/preprocess'
import './styles.scss'
import {} from 'antd'
import { Icon, Tooltip } from 'antd'
import Link from 'core-components/link'
import { getProfilePicture } from 'utils/firebase'
import { Avatar } from 'antd'
import { ConfirmForm } from 'core-components/confirm-input'
import { ModalTrigger } from 'core-components/modal'
import ShareModal from '../../containers/share-modal'

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({data}) => {
    return (
        <div className="post-actions">
            <ul>
                <li>
                    <Link>
                        <Tooltip title="Like">
                            <div className="action like">
                                <Icon type="like"/>
                                <span className="count">24</span>
                            </div>
                        </Tooltip>
                    </Link>
                </li>
                <li>
                    <Link>
                        <Tooltip title="Adopt">
                            <div className="action adopt">
                                <Icon type="block"/>
                                <span className="count">24</span>
                            </div>
                        </Tooltip>
                    </Link>
                </li>
                <li>
                    <ModalTrigger
                        content={<ShareModal/>}
                        modalProps={{
                            title: 'Share Post',
                            footer: null,
                            className: 'public-modal share-modal',
                        }}
                    >
                        <Link>
                            <Tooltip title="Share">
                                <div className="action share">
                                    <Icon type="share-alt"/>
                                    <span className="count">24</span>
                                </div>
                            </Tooltip>
                        </Link>
                    </ModalTrigger>
                </li>
            </ul>
        </div>

    )
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {}
}
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {

    return {}
}
Main.displayName = 'Sample-Container'
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, null],
    localize: true
})
