var menuKeys = [
  {
    key: "home.dashboard",
    name: "Dashboard",
    icon: "desktop",
    role: "admin",
  },
  // {
  //     key: 'home.permission',
  //     name: 'Permissions',
  //     icon: 'carry-out',
  //     role: 'admin'
  // },
  // {
  //     key: 'home.sms-template',
  //     name: 'SMS Templates',
  //     icon: 'mobile',
  //     role: 'admin'
  // },
  // {
  //     key: 'home.language',
  //     name: 'Language',
  //     icon: 'font-colors',
  //     role: 'admin'
  // },

  {
    key: "home.customers",
    name: "Customers",
    icon: "user",
    role: "admin",
  },
  {
    key: "home.merchants",
    name: "Merchants",
    icon: "crown",
    role: "admin",
  },
  {
    key: "home.packages",
    name: "Packages",
    icon: "thunderbolt",
    role: "admin",
  },
  {
    key: "home.levels",
    name: "Levels",
    icon: "book",
    role: "admin",
  },
  {
    key: "home.product",
    name: "Products",
    icon: "skin",
    role: (role) => {
      role = role.toUpperCase();
      return role === "ADMIN" || role === "MERCHANT";
    },
  },
  {
    key: "home.product-category",
    name: "Product Categories",
    icon: "apartment",
    role: "admin",
  },
  {
    key: "home.newsfeed",
    name: "Posts",
    icon: "picture",
    role: "admin",
  },
  {
    key: "home.pending-requests",
    name: "Pending Requests",
    icon: "crown",
    role: "merchant",
  },
  {
    key: "home.transaction-history",
    name: "Transaction History",
    icon: "thunderbolt",
    role: "merchant",
  },
  //   {
  //     key: "home.roles",
  //     name: "Roles",
  //     icon: "idcard",
  //     role: "admin",
  //   },
  {
    key: "home.contact",
    name: "Enquiries",
    icon: "question-circle",
    role: "admin",
  },
  {
    key: "home.configuration",
    name: "Configurations",
    icon: "file-protect",
    role: "admin",
  },
  {
    key: "home.media",
    name: "Media",
    icon: "picture",
    role: "admin",
  },
  {
    key: "home.page-configuration",
    name: "System Pages",
    icon: "snippets",
    role: "admin",
  },
  {
    key: "home.page",
    name: "Static Pages",
    icon: "file",
    role: "admin",
  },
  {
    key: "home.gateway",
    name: "Payment Gateways",
    icon: "dollar",
    role: "admin",
  },
  //   {
  //     key: "home.demos",
  //     name: "Demos",
  //     icon: "trademark",
  //     role: "admin",
  //     children: [
  //       {
  //         key: "home.demos",
  //         icon: "form",
  //         name: "Field Demos",
  //       },
  //       {
  //         key: "home.payment",
  //         icon: "credit-card",
  //         name: "Payment Demos",
  //       },
  //     ],
  //   },
  {
    key: "home.email-template",
    name: "Email Templates",
    icon: "mail",
    role: "admin",
  },
  {
    key: "home.sms-template",
    name: "SMS Templates",
    icon: "mobile",
    role: "admin",
  },
  //   {
  //     key: "home.theme",
  //     name: "Theming",
  //     icon: "block",
  //     role: "admin",
  //   },
  //   {
  //     key: "home.pdf-template",
  //     name: "PDF Templates",
  //     icon: "file-pdf",
  //     role: "admin",
  //   },
  {
    key: "home.settings",
    name: "Settings",
    icon: "setting",
  },
];

export default menuKeys;
