import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  Row,
  Col,
  Input,
  AutoComplete,
  Checkbox,
  Button,
  message,
  Select,
} from "antd";
import Banner from "../../components/banner";
import preProcess from "containers/preprocess";
import MerchantCard from "../merchantCard";
import Form from "core-components/form";
import { createAction, ActionNames } from "app-redux/actions";
import { getSinceYear } from "../container-helpers/common";
/**
 * @description Newsfeed Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ requestPoints, getMerchants }) => {
  const [merchants, setMerchants] = useState([]);
  const [merchant, setMerchant] = useState(null);
  const handleSubmit = async (values, form) => {
    try {
      window.startSpinning(".request .form");
      const { error, payload } = await requestPoints({ ...values });
      if (error) {
        throw payload;
      } else {
        message.success("Your request has been placed successfully");
        form.resetFields();
        setMerchant(null);
      }
    } catch (e) {
      console.error({ e });
      message.error("Error while placing your request. Please try again");
    }
    window.stopSpinning(".request .form");
  };
  const getMerchantsRequest = async () => {
    try {
      const { payload, error } = await getMerchants();
      if (!error) {
        setMerchants(payload.data);
      } else {
        throw payload;
      }
    } catch (e) {
      console.error("Error while getting merchants ", { e });
    }
  };
  useEffect(() => {
    getMerchantsRequest();
  }, []);
  return (
    <div className="request">
      <div className="content">
        <Banner title={"Request Coins"} />
        <div className="section">
          <div className="container">
            {/* <div className="heading">
                            <p className="title">Request Coins from Merchant</p>
                        </div> */}
            <div className="details">
              <Row gutter={48}>
                <Col xs={24} sm={24} md={16} lg={18} xl={18}>
                  <div className="form">
                    <p className="label">
                      Request Coins for your purchases done with the merchants
                    </p>
                    <p className="message">
                      Please fill the form and submit it. To complete your
                      request, Please enter Order Id or Bill Number correctly
                      and details as below.
                    </p>
                    <Form
                      onSubmit={handleSubmit}
                      submitText="Request"
                      elements={[
                        {
                          type: "inline",
                          elements: [
                            {
                              type: "select",
                              name: "merchantId",
                              label: "Merchant",
                              inputProps: {
                                placeholder: "Search Merchant",
                                onChange: (value) => {
                                  setMerchant(
                                    merchants.find(({ uid }) => {
                                      return uid === value;
                                    })
                                  );
                                },
                                filterOption: (input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0,
                                showSearch: true,
                              },
                              required: true,
                              data: merchants.map(
                                ({ displayName, uid }, index) => {
                                  return {
                                    label: displayName,
                                    value: uid,
                                  };
                                }
                              ),
                            },
                            {
                              type: "text",
                              name: "transactionId",
                              label: "Order Id / Bill Number",
                              required: true,
                            },
                            {
                              type: "number",
                              name: "amount",
                              label: `Amount (${window.app.currency})`,
                              required: true,
                            },
                          ],
                        },
                      ]}
                    />
                  </div>
                  {/* <div className="form">
                                        <p className="label">Confirm Your Request</p>
                                        <p className="message">Please check details and confirm your request by entering
                                            the OTP which your merchant has shared with you.</p>
                                        <div className="reward">
                                            <div className="points">
                                                <img src={point}/>
                                                <span className="value">250</span>
                                            </div>
                                            <div className="text">
                                                <p>For your order ID 345678, you will be earning 250 Loyalty Points.
                                                    Enter
                                                    OTP to confirm it.</p>
                                            </div>
                                        </div>
                                        <Form onSubmit={handleSubmit.bind(this)}>
                                            <div className="inline">
                                                <FormItem hasFeedback={true}>
                                                    {
                                                        getFieldDecorator('otp', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: 'This field is required',
                                                                }
                                                            ],
                                                        })
                                                        (
                                                            <Input placeholder="Enter OTP" className="input"/>
                                                        )
                                                    }
                                                </FormItem>
                                            </div>
                                            <div className="actions">
                                                <Button htmlType={'submit'} className="red-btn-text">Cancel</Button>
                                                <Button htmlType={'submit'} className="green-btn">Confirm</Button>
                                            </div>
                                        </Form>
                                    </div> */}
                </Col>
                <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                  {merchant && (
                    <div className="merchant-details">
                      <p className="label">Merchant Details</p>
                      <p className="message">
                        Merchant will confirm your request by reviewing your
                        order details.
                      </p>
                      <MerchantCard
                        merchant={{
                          name: merchant.displayName,
                          //rating: "Top 1%",
                          image: merchant.photoURL,
                          since: getSinceYear(merchant.createdAt),
                          profile: merchant.uid,
                        }}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    getMerchants: () => {
      const action = createAction(ActionNames.GET_ENTITY, {
        entityName: "users",
        entityId: "getMerchants",
      });
      action.type = action.type + "_getMerchants";
      return dispatch(action);
    },
    requestPoints: (data) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "points-request",
          entityId: "requestPointTransfer",
          ...data,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};
Main.displayName = "Request-Points-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
