import React, { useEffect, useState } from "react";
import "./styles.scss";
import Banner from "../../components/banner";
import { Row, Col, Icon } from "antd";
import Link from "../../components/link";
import Footer from "../footer";
import preProcess from "containers/preprocess";
import {
  createAction,
  ActionNames,
  getApiActionExecuter,
} from "../../redux/actions";
/**
 * @description Newsfeed Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ data, getData,type,title }) => {
  useEffect(() => {
    window.startSpinning(".blog");
    try {
      getApiActionExecuter(getData)(type);
    } catch(e){
      console.error("error while getting newsfeed - ",{e})
    }
    window.stopSpinning(".blog");
  }, []);
  return (
    <div className="blog">
      <Banner title={title} />
      <div className="section blog-list">
        <div className="container">
          <div className="tabs">
            <div className="content">
              <Row gutter={24}>
                {data.map((child, index) => {
                  const { image, tags, title, shortDescription, key } = child;
                  return (
                    <Col
                      key={index}
                      xs={24}
                      sm={12}
                      md={12}
                      lg={8}
                      xl={8}
                      className="column"
                    >
                      <Link routeKey="postDetails" routeParams={{ id: key }}>
                        <div className="card">
                          <div className="image">
                            <img src={image} />
                          </div>
                          <div className="project">
                            <div className="hashtags">
                              {tags.map((tag, index) => {
                                return (
                                  <p key={index} className="hashtag">
                                    {"#" + tag}
                                  </p>
                                );
                              })}
                            </div>
                            <p className="title">{title}</p>
                            <p className="description">{shortDescription}</p>
                          </div>
                          <div className="action">
                            <p className="view">View</p>
                            <Icon type="arrow-right" />
                          </div>
                        </div>
                      </Link>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    getData: (type) => {
      const action = createAction(ActionNames.GET_ENTITIES, {
        entityName: "newsfeed",
        search : type,
        searchField : "type",
        from: -1,
        sort : "createdAt",
        sortType : "desc"
      });
      action.type = ActionNames.GET_ENTITIES + "_newsfeed";
      return dispatch(action);
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ newsfeed }) => {
  return {
    data: newsfeed.data,
  };
};
Main.displayName = "Newsfeed-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
