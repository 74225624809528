import React from 'react'
import './styles.scss'
import { Row, Col, Icon } from 'antd'
import Link from '../../../components/link'

var view = function () {
    const {dashboard, translate} = this.props
    const {users, roles} = dashboard || {}
    // const total = Object.keys(users || {}).reduce((sum, key) => {
    //     return sum + users[key]
    // }, 0)
    const array = [{
        count: users ? users.MERCHANT : 0,
        label: 'Merchants',
        route: 'home.merchants',
        icon: 'team'
    },{
        count: users ? users.CUSTOMER : 0,
        label: 'Customers',
        route: 'home.customers',
        icon: 'team'
    } /*{
        count: roles,
        label: 'Roles',
        route: 'home.roles',
        icon: 'safety-certificate'
    }*/]
    return (
        <div className="dashboard">
            <div className="content">
                <p className="page-title mobile-only">{translate('dashboard.title')}</p>
                <Row gutter={16}>
                    {
                        array.map((item, index) => {
                            const {count, label, route, icon} = item
                            return (
                                <Col key={index} xs={24} sm={12} md={12} lg={6} xl={6}>
                                    <Link routeKey={route}>
                                        <div className="card">
                                            <div className="info">
                                                <div className="icon">
                                                    <Icon type={icon}/>
                                                </div>
                                                <div className="details">
                                                    <p className="count">{count || 0}</p>
                                                    <p className="label">{label}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </div>
    )
}
export default view
