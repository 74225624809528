import React from 'react'
import './styles.scss'
import { Col, Row, Icon, Select, Form, Input, Button } from 'antd'
import Link from 'core-components/link'
import ChangeLanguage from 'core-components/change-language'
import logo from '../../images/logo.png'
import fb from '../../images/social/fb.png'
import tw from '../../images/social/tw.png'
import pin from '../../images/social/pin.png'
import yt from '../../images/social/youtube.png'
import google from '../../images/social/google.png'
import Instagram from '../../images/social/insta.png'
import Linkedin from '../../images/social/linkedIn.png'
import badge from '../../images/badge.png'
import paymentCards from '../../images/payment-cards.png'
import { getPageConfiguration } from '../../routes'
import Subscribe from '../subscribe'

const {Option} = Select
const FormItem = Form.Item
var view = function () {
    const {form, WEBSITELOGO} = this.props
    const {getFieldDecorator} = form
    const {email} = this.validations
    const {
        facebook,
        twitter,
        pinterest,
        google: googleLink,
        youtube,
    } = getPageConfiguration('/landing')
    return (
        <div className="footer">
            <div className="main">
                <Subscribe/>
                <div className="container">
                    <div className="content">
                        <Row gutter={48}>
                            <Col xs={12} sm={8} md={8} lg={6} xl={6}>
                                <p className="label">Useful Info</p>
                                <ul>
                                    <li>
                                        <Link routeKey="about">About us</Link>
                                    </li>

                                    <li>
                                        <Link routeKey="concept">Our Concept</Link>
                                    </li>
                                    <li>
                                        <Link routeKey="services">Services</Link>
                                    </li>
                                    <li>
                                        <Link routeKey="guides">Campaigns</Link>
                                    </li>
                                    <li>
                                        <a href="https://site.ecopence.com/competitions/">Competitions</a>
                                    </li>
                                </ul>
                            </Col>
                            <Col xs={12} sm={8} md={8} lg={6} xl={6}>
                                <p className="label">Join Ecopence</p>
                                <ul>
                                    <li>
                                        <Link routeKey="Register">User Sign Up</Link>
                                    </li>
                                    <li>
                                        <Link routeKey="Register" queryParams={{merchant: true}}>
                                            Merchant Registration
                                        </Link>
                                    </li>
                                    <li>
                                        <a href="https://site.ecopence.com/partners/">Our Partners</a>
                                    </li>
                                    <li>
                                        <a href="https://site.ecopence.com/join/">Join Our Team</a>
                                    </li>
                                    <li>
                                        <a href="https://site.ecopence.com/green-aware/">Become a Green Hero</a>
                                    </li>
                                </ul>
                            </Col>
                            <Col xs={12} sm={8} md={8} lg={6} xl={6}>
                                <p className="label">Customer Services</p>
                                <ul>
                                    <li>
                                        <Link routeKey="staticPages.terms">Terms & Conditions</Link>
                                    </li>
                                    <li>
                                        <Link routeKey="staticPages.privacy-policy">
                                            Privacy Policy
                                        </Link>
                                    </li>
                                    <li>
                                        <a href="https://site.ecopence.com/help/">Help Centre</a>
                                    </li>
                                    <li>
                                        <a href="https://site.ecopence.com/contact/">Contact Us</a>
                                    </li>
                                </ul>
                            </Col>
                            <Col xs={12} sm={24} md={24} lg={6} xl={6}>
                                <p className="label">Social</p>
                                <ul className="social-links">
                                    <li>
                                        <a target="_blank" href={facebook}>
                                            <img src={fb} alt="Facebook"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href={googleLink}>
                                            <img src={google} alt="Google"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href={twitter}>
                                            <img src={tw} alt="Twitter"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href={youtube}>
                                            <img src={yt} alt="Youtube"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href={pinterest}>
                                            <img src={pin} alt="Pinterest"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.instagram.com/eco.pence/">
                                            <img src={Instagram} alt="Instagram"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.linkedin.com/company/ecopence/">
                                            <img src={Linkedin} alt="Linked In"/>
                                        </a>
                                    </li>
                                </ul>
                                <a target="_blank" href="https://businesses.supportthegoals.org/businesses/ecopence">
                                    <img src={badge} alt="Badge" className="badge"/>
                                </a>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="others">
                    <div className="container">
                        <div className="left">
                            <ul>
                                <li className="logo">
                                    <img src={WEBSITELOGO} alt={'logo'}/>
                                </li>
                                <li>
                                    <ChangeLanguage/>
                                </li>
                                {/* <li>
                                    <Icon type="dollar"/>
                                    <Select defaultValue="CAD">
                                        <Option value="USD">USD</Option>
                                        <Option value="CAD">CAD</Option>
                                    </Select>
                                </li> */}
                            </ul>
                        </div>
                        <div className="payment-method">
                            {/* <p>Accepted Payment Methods :</p>
                            <img src={paymentCards} alt={'Payment Cards'}/> */}
                        </div>
                        <div className="right">
                            <div className="copyright">
                                <p>Ecopence © 2020 All Rights Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default view
