import React, { Component } from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Tabs,Input} from 'antd';
import OrdersPlaced from '../ordersPlaced';
import BuyAgain from '../ordersBuyAgain';
import OpenedOrders from '../ordersOpened';
import CancelledOrders from '../ordersCancelled';

const { TabPane } = Tabs;
const { Search } = Input;


/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
    return (
        <div className="order-details">
            <div className="container">
                <div className="heading">
                    <p className="title">Orders History</p>
                    <div className="search">
                        <Search placeholder="Search all orders" onSearch={value => console.log(value)} enterButton />
                    </div>
                </div>
                <div className="tabs">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Orders" key="1">
                            <OrdersPlaced />
                        </TabPane>
                        <TabPane tab="Buy Again" key="2">
                            <BuyAgain />
                        </TabPane>
                        <TabPane tab="Open Orders" key="3">
                            <OpenedOrders />
                        </TabPane>
                        <TabPane tab="Cancelled Orders" key="4">
                            <CancelledOrders />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {}
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {

    return {}
};
Main.displayName = "Sample-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, null],
    localize: true
});
