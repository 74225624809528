import React from 'react'
import './styles.scss'
import { Menu } from 'antd'
import config from './config'
import Link from 'core-components/link'

const { SubMenu, Item } = Menu
var view = function () {
    const { categories } = this.props;
    let data = [];
    (categories || []).forEach(category => {
        const { parent } = category;
        if (parent) {
            const index = data.findIndex(item => item.id === parent);
            if (index !== -1) {
                if (data[index].children) {
                    data[index].children.push(category);
                } else {
                    data[index].children = [category];
                }
            }
        } else {
            data.push(category);
        }
    });
    return (
        <div className="categories">
            <Menu onClick={this.handleMenuClick}>
                {
                    data.map((item, index) => {
                        const { name, children, id } = item
                        if (children) {
                            return (
                                <SubMenu key={index} title={name}>
                                    {
                                        children.map((child, index) => {
                                            const { name, id } = child
                                            return (
                                                <Item key={index}>
                                                    <Link routeKey='catalogue' queryParams={{
                                                        category: id
                                                    }}>
                                                        <span>{name}</span>
                                                    </Link>
                                                </Item>
                                            )
                                        })
                                    }
                                </SubMenu>
                            )
                        } else {
                            return (
                                <Item key={index}>
                                    <Link routeKey='catalogue' queryParams={{
                                        category: id
                                    }}>
                                        <span>{name}</span>
                                    </Link>
                                </Item>
                            )
                        }
                    })
                }
            </Menu>
        </div>
    )
}
export default view
