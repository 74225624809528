import React, { Component } from 'react';
import ComponentView from './view';
import preProcess from '../../preprocess';
import { Form, message, Button } from 'antd/lib/index';
import { goToRoute } from '../../../routes';
import { createAction, ActionNames } from '../../../redux/actions';
import { formatDate } from '../../../utils/date-util';
import { getVal } from 'react-redux-firebase';
import ConfirmInput from '../../../components/confirm-input';
import { MERCHANT_PRE_CONFIRMATION_LINK } from '../../../config';
const spinningSelector = ".general-form.register";

/**
 * @description Login Form Container
 * @type Container
 * @author Jasjot
 */
class Main extends Component {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
        this.setValidations();
        this.inviteChecked = false;
    }


    /**
     * toggleForm function switches between modals of join form
     * @param value
     */
    toggleForm(value) {
        const { setUiProperty } = this.props;
        setUiProperty({
            name: 'activeTab',
            value
        })
    }

    /**
     * Sets Validations for fields
     */
    setValidations() {
        const { translate } = this.props;
        this.validations = {
            email: {
                rules: [
                    { required: true, message: translate('common.email.error.required') },
                    { type: "email", message: translate('common.email.error.invalid') }
                ],
                //initialValue : "t"+Math.random()+"@t.com"
            },
            password: {
                rules: [
                    { required: true, message: translate('common.password.error.required') },
                    { min: 6, message: translate('common.password.error.invalid') }
                ],
                //initialValue : "123456"
            },
            confirmPassword: {
                rules: [
                    { required: true, message: translate('common.confirmPassword.error.required') },
                    {
                        validator: (rule, value, callback) => {
                            const { getFieldValue } = this.props.form;
                            if (value !== getFieldValue('password')) {
                                callback(translate('common.error.password.match'))
                            } else {
                                callback();
                            }
                        }
                    }
                ],
            },
            firstName: {
                rules: [
                    { required: true, message: translate('common.firstName.error.required') }
                ],
            },
            lastName: {
                rules: [
                    { required: true, message: translate('common.lastName.error.required') }
                ],
                //initialValue : "Test "+Math.random()
            },
            agreement: {
                rules: [
                    {
                        validator: (rule, value, callback) => {
                            const { getFieldValue } = this.props.form;
                            if (!getFieldValue('agreement')) {
                                callback(translate('common.terms.error.required'))
                            } else {
                                callback();
                            }
                        }
                    }
                ],
            },
        }
    }


    /**
     * ComponentDidMount Hook
     */
    componentDidMount() {
    }

    /**
     * componentWillReceiveProps Hook
     * @param newProps
     */
    componentWillReceiveProps(newProps) {
    }

    /**
     * On Submit of  Form
     * @param event
     */
    handleSubmit(event) {
        event.preventDefault();
        const { validateFieldsAndScroll, resetFields } = this.props.form;
        const { translate, onSuccess,referralCode, signUp, verifyEmail,merchant } = this.props;
        validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return;
            }
            window.startSpinning(spinningSelector);
            /**
             * Signup the user
             */
            try {
                delete values.agreement;
                delete values.confirmPassword;
                values.type='local';
                const isMerchant = !!merchant;
                values.referralCode = referralCode && referralCode.trim() !== "" ? referralCode : undefined ;
                if(isMerchant){
                    values.role = "MERCHANT";
                } else {
                    values.role = "CUSTOMER";
                }
                delete values.merchant;
                const action = await signUp(values);
                if (action.error) {
                    throw action.payload.response.data;
                }
                if (onSuccess instanceof Function) {
                    onSuccess();
                }
                resetFields();
                /**
                 * If Confirm New Registration is enabled
                 */
                if (window.app.confirmNewRegistration) {
                    /**
                     * If its OTP Based
                     */
                    if (action.payload.data.confirmationKey) {
                        message.success(translate("signUp.accountVerification.sent.success"));
                        this.key = action.payload.data.confirmationKey;
                        ConfirmInput({
                            inputLabel: translate("signUp.securityCode.placeholder"),
                            className: "verify-otp",
                            disableCancel: true,
                            onSubmit: async ({ value }, hideModal) => {
                                window.startSpinning(".verify-otp");
                                try {
                                    const { error, payload } = await verifyEmail({
                                        entityId: this.key,
                                        verificationCode: value
                                    });
                                    if (error) {
                                        throw payload.response;
                                    }
                                    hideModal();
                                    goToRoute('login');
                                    message.success(translate("signUp.accountVerification.success"));
                                } catch (e) {
                                    message.error(translate("signUp.accountVerification.incorrectCode"));
                                }
                                window.stopSpinning(".verify-otp");
                            },
                            actions: [
                                <Button
                                    onClick={this.resend.bind(this)}
                                >{translate("signUp.resendOtp.placeholder")}</Button>
                            ]
                        });
                    } else {                        
                        message.success(translate('signUp.success'));
                        if(values.role === "MERCHANT"){
                            window.location.href = MERCHANT_PRE_CONFIRMATION_LINK
                        } else {
                            goToRoute('login');
                        }                        
                    }
                } else {
                    message.success(isMerchant ? translate('signUp.successMerchant'):translate('signUp.success'));
                    if(values.role === "MERCHANT"){
                        window.location.href = MERCHANT_PRE_CONFIRMATION_LINK;
                    } else {
                        goToRoute('login');
                    } 
                }

            } catch (e) {
                message.error(e.message || '');
            }
            window.stopSpinning(spinningSelector);
        })
    }


    async resend() {
        const { resendEmail, translate } = this.props;
        window.startSpinning(".verify-otp");
        try {
            const { error, payload } = await resendEmail(this.key);
            if (error) {
                throw payload.response;
            }
            this.key = payload.data.key;
            message.success(translate("signUp.accountVerification.sent.success"));
        } catch (e) {
            message.error(translate("signUp.accountVerification.sent.error"));
            console.error(e);
        }
        window.stopSpinning(".verify-otp");
    }

    /**
     * Render Method
     * @returns {*}
     */
    render() {
        return (ComponentView.bind(this))();
    }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {
        setUiProperty: data => {
            return dispatch(createAction(ActionNames.UI_SET_PROPERTY, data));
        },
        signUp: data => {
            return dispatch(createAction(ActionNames.REGISTER, data));
        },
        verifyEmail: ({ entityId, verificationCode }) => {
            return dispatch(createAction(ActionNames.UPDATE_ENTITY, {
                entityId,
                entityName: 'confirm-email',
                verificationCode
            }))
        },
        resendEmail: entityId => {
            return dispatch(createAction(ActionNames.GET_ENTITY, {
                entityId: entityId,
                entityName: 'confirm-email/resend'
            }))
        }
    }
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state, props) => {
    const { location } = props;
    return {
        ui: state.ui,
        emitter: state.emitter,
        invite: location ? getVal(state.firebase, `data/invites/${location.query.inviteKey}`) : null,
        loaded: location ? state.firebase.requested[`invites/${location.query.inviteKey}`] : true && true
    }
};
Main.displayName = "Register-Form";
//Pre process the container with Redux Plugins
export default preProcess((Form.create())(Main), {
    connect: [mapStateToProps, bindAction],
    localize: true,
    withRouter: true
});
