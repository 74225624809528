import React, { Component } from 'react'
import preProcess from 'containers/preprocess'
import './styles.scss'
import { Row, Col, Carousel, Icon, Button, InputNumber } from 'antd'
import organic1 from '../../images/organic1.jpg'
import organic2 from '../../images/organic2.jpg'
import organic3 from '../../images/organic3.jpg'
import organic4 from '../../images/organic4.jpg'

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
    const images = [organic1, organic2, organic3, organic4]
    return (
        <div className="product">
            <div className="content container">
                <Row>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className="column slides">
                        <div className="slider">
                            <Carousel
                                effect='fade'
                                customPaging={i => <a><img src={images[i]}/></a>}
                                slidesToScroll={1}
                                slidesToShow={1}
                                speed={500}
                                dotsClass='slick-dots slick-thumb'
                                dots
                                infinite
                                arrows={true}
                                nextArrow={<Icon type="right-circle-o"/>}
                                prevArrow={<Icon type="left-circle-o"/>}
                            >
                                {
                                    images.map((item, index) => {
                                        return (
                                            <div className="slide" key={index}>
                                                <img src={item}/>
                                                <div className="wish-list">
                                                    <Button className="btn">
                                                        <Icon type="heart"/>
                                                    </Button>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </Carousel>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className="column right">
                        <div className="details">
                            <p className="name">Product Name</p>
                            <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            <p className="price">$ 20</p>
                            <div className="item quantity">
                                <p className="label">Quantity</p>
                                <div className="content">
                                    <div className="form">
                                        <InputNumber className="input" max={10} defaultValue={1}/>
                                    </div>
                                </div>
                            </div>
                            <div className="action">
                                <Button className="green-btn">Add to Cart</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {}
}
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {

    return {}
}
Main.displayName = 'Sample-Container'
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, null],
    localize: true
})
