import React, { Component } from "react";
import preProcess from "containers/preprocess";
import { Button, Tooltip, Icon, Modal, message } from "antd";
import { createAction, ActionNames } from "app-redux/actions";
import "./styles.scss";
/**
 * @description Reject-Pending-Request Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ record, reject, getTableData }) => {
  const { key: requestId, details: requestDetails } = record;
  const onClick = () => {
    Modal.confirm({
      closable: true,
      title: "Are you sure?",
      content: "This request will be rejected.",
      okButtonProps: {
        className: "btn green-btn",
      },
      cancelButtonProps: {
        className: "btn red-btn-text",
      },
      onOk: async () => {
        try {
          const { error, payload } = await reject({ requestId });
          if (error) {
            throw payload;
          } else {
            message.success("Request rejected successfully");
            getTableData();
          }
        } catch (e) {
          console.error({ e });
          message.error("Error while rejecting this request. Please try again");
        }
      },
    });
  };
  return (
    <Tooltip placement="top" title={"Reject"}>
      <Button className="delete" onClick={onClick}>
        <Icon type="close" />
      </Button>
    </Tooltip>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    reject: (data) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "points-request",
          entityId: "rejectRequest",
          ...data,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};
Main.displayName = "Reject-Pending-Request";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
