import React, { useState, useRef } from "react";
import preProcess from "containers/preprocess";
import { Button, Tooltip, Icon, message } from "antd";
import "./styles.scss";
import { ModalTrigger } from "core-components/modal";
import Form from "core-components/form";
import { createAction, ActionNames } from "app-redux/actions";
/**
 * @description Add Points Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ record, approve, getTableData }) => {
  const modalRef = useRef(null);

  const onSubmit = async (values) => {
    try {
      window.startSpinning(".form");
      const { error, payload } = await approve({
        ...values,
        merchantId: record.uid,
      });
      if (error) {
        throw payload;
      } else {
        message.success("Coins Added Successfully");
        modalRef.current.hideModal();
      }
    } catch (e) {
      console.error({ e });
      message.error(
        (e.response && e.response.data && e.response.data.message) ||
          "Error while approving this request. Please try again"
      );
    }
    window.stopSpinning(".form");
  };
  return record.emailVerified ? (
    <ModalTrigger
      ref={modalRef}
      content={
        <React.Fragment>
          <Form
            key="form"
            elements={[
              {
                type: "number",
                name: "points",
                label: `Number of coins/leaves to be added`,
                inputProps: {
                  placeholder: "Number of coins/leaves to be added",
                },
                required: true,
              },
              {
                type: "select",
                name: "pointType",
                label: `Coins/Leaves`,
                inputProps: {
                  placeholder: "Coins or Leaves",
                },
                required: true,
                data: [
                  {
                    label: "Coins",
                    value: "paid",
                  },
                  {
                    label: "Leaves",
                    value: "free",
                  },
                ],
              },
            ]}
            onSubmit={onSubmit}
            submitText="Save"
          />
        </React.Fragment>
      }
      modalProps={{
        title: "Add Coins/Leaves",
        footer: null,
      }}
    >
      <Tooltip placement="top" title={"Add Coins/Leaves"}>
        <Button className="edit">
          <Icon type="plus" />
        </Button>
      </Tooltip>
    </ModalTrigger>
  ) : null;
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    approve: (data) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "points-request",
          entityId: "addPoints",
          ...data,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user }) => {
  return {};
};
Main.displayName = "Add-Points-Request";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
