let obj = {};
export default obj;

function requireAll(r) {
  r.keys()
    .map((r) => {
      if (r !== "index.js") {
        const name = r.replace(".json", "");
        const file = require(`${r}`);
        if (file) {
          obj[name.replace("./", "")] = file;
        }
      }
      return r;
    })
    .forEach(r);
}
requireAll(require.context("./", true, /\.json$/));
