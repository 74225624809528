import React from 'react'
import './styles.scss'
import { Form, Button, Checkbox, Input } from 'antd'

const {Item} = Form
var view = function () {
    const {form, hideModal, data, translate} = this.props
    const {name, key, enabled} = this.validations
    const {getFieldDecorator} = form
    return (
        <Form onSubmit={this.handleSubmit.bind(this)}>
            <div className="new-form">
                <div className="form">
                    <Item hasFeedback={true} label={translate('language.key.label')}>
                        {
                            getFieldDecorator('key', key)(
                                <Input maxLength={100}
                                       disabled={!!data}
                                       className='input uppercase'
                                       placeholder={translate('language.key.placeholder')}
                                />
                            )
                        }
                    </Item>
                    <Item hasFeedback={true} label={translate('language.name.label')}>
                        {
                            getFieldDecorator('name', name)(
                                <Input maxLength={200}
                                       className='input'
                                       placeholder={translate('language.name.placeholder')}
                                />
                            )
                        }
                    </Item>
                    <Item hasFeedback={true} label={translate('language.enabled.label')}>
                        {
                            getFieldDecorator('enabled', enabled)(
                                <Checkbox/>
                            )
                        }
                    </Item>
                </div>
                <div className="actions">
                    <Button htmlType={'submit'} type="primary"
                            className="btn green-btn">{translate('media.submit')}</Button>
                    <Button htmlType={'button'} onClick={hideModal}
                            className="btn red-btn-text">{translate('media.cancel')}</Button>
                </div>
            </div>
        </Form>
    )
}
export default view
