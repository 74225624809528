import React from 'react'
import './styles.scss'
import {
    Form,
    Input,
    Avatar,
    Button,
    Checkbox,
    Select,
    InputNumber,
    AutoComplete,
    Radio,
    DatePicker,
    Switch
} from 'antd'
import FilePicker from '../../components/file-picker'
import logo from '../../images/logo.png'
import Sign from '../../components/signature-capture'
import PaymentGateways from '../../components/payment-gateways';
const CheckboxGroup = Checkbox.Group
const FormItem = Form.Item
const options = [
    { label: 'Give admin rights to this user', value: 'set-admin' }
]

var view = function () {
    const { dataSource, value } = this.state
    const { translate, form, hideModal, roles } = this.props;
    const { name, email, password, role, photoURL, number } = this.validations
    const { getFieldDecorator } = form
    const getFilePickerChildren = ({ getUri }) => {
        const uri = getUri()
        return <Avatar icon="user" src={uri.length > 0 ? uri[0] : null} />
    }

    return (
        <div className="demo">
            <div className="content">
                <p className="page-title mobile-only">{translate('demos.title')}</p>
                <div className="form">
                    <Form onSubmit={this.handleSubmit.bind(this)}>
                        <div className="new-form">
                            <div className="form">
                                <div className="field">
                                    <p className="label">{translate('demos.file.label')}</p>
                                    <FormItem hasFeedback={true} label="Add Image">
                                        <div className="image">
                                            {getFieldDecorator('photoURL', photoURL)(
                                                <FilePicker
                                                    getChildren={getFilePickerChildren}
                                                />
                                            )}
                                        </div>
                                    </FormItem>
                                </div>

                                <div className="field">
                                    <p className="label">{translate('demos.input.label')}</p>
                                    <FormItem hasFeedback={true} label={'Name'}>
                                        {
                                            getFieldDecorator('name', name)(<Input maxLength={200} type={'text'}
                                                placeholder={translate('common.firstName.placeholder')}
                                                className="input" />)
                                        }
                                    </FormItem>
                                </div>

                                <div className="field">
                                    <p className="label">{translate('demos.input.email.label')}</p>
                                    <FormItem hasFeedback={true} label={'Email Address'}>
                                        {
                                            getFieldDecorator('email', email)(<Input maxLength={100}
                                                placeholder={translate('common.email.placeholder')}
                                                className="input" />)
                                        }
                                    </FormItem>
                                </div>

                                <div className="field">
                                    <p className="label">{translate('demos.input.password.label')}</p>
                                    <FormItem hasFeedback={true} label={'Password'}>
                                        {
                                            getFieldDecorator('password', password)(<Input maxLength={200} type={'password'}
                                                placeholder={translate('common.password.placeholder')}
                                                className="input" />)
                                        }
                                    </FormItem>
                                </div>

                                <div className="field">
                                    <p className="label">{translate('demos.input.number.label')}</p>
                                    <FormItem hasFeedback={true} label={'Input Number'}>
                                        {
                                            getFieldDecorator('number', number)(<Input maxLength={200}Number className="input"
                                                placeholder="Enter Number"
                                                min={1}
                                                max={10} />)
                                        }
                                    </FormItem>

                                </div>

                                <div className="field">
                                    <p className="label">{translate('demos.input.auto.label')}</p>
                                    <FormItem hasFeedback={true}>
                                        <AutoComplete
                                            value={value}
                                            dataSource={dataSource}
                                            onSelect={this.onSelect.bind(this)}
                                            onSearch={this.onSearch.bind(this)}
                                            onChange={this.onChange.bind(this)}
                                            placeholder="Search"
                                        />
                                    </FormItem>
                                </div>

                                <div className="field">
                                    <p className="label">{translate('demos.input.switch.label')}</p>
                                    <FormItem hasFeedback={true}>
                                        <Switch defaultChecked onChange={this.onChangeSwitch.bind(this)} />
                                    </FormItem>
                                </div>

                                <div className="field">
                                    <p className="label">{translate('demos.input.select.label')}</p>
                                    <FormItem hasFeedback={true} label={'Role'}>
                                        {
                                            getFieldDecorator('role', role)(
                                                <Select className='select'>
                                                    <Select.Option
                                                        value={''}
                                                    >
                                                        Select Role
                                                    </Select.Option>
                                                    {roles.map((item, index) => {
                                                        return (
                                                            <Select.Option
                                                                key={index}
                                                                value={item.code}
                                                            >
                                                                {item.description}
                                                            </Select.Option>
                                                        )
                                                    })}
                                                </Select>
                                            )
                                        }
                                    </FormItem>
                                </div>

                                <div className="field">
                                    <p className="label">{translate('demos.input.date.label')}</p>
                                    <FormItem hasFeedback={true} label={'Select Date'}>
                                        <DatePicker onChange={this.onChangeDatePicker.bind(this)} />
                                    </FormItem>
                                </div>

                                <div className="field">
                                    <p className="label">{translate('demos.input.checkbox.label')}</p>
                                    <FormItem hasFeedback={false} label={'Check'}>
                                        <div className="options">
                                            {
                                                getFieldDecorator('options')(
                                                    <CheckboxGroup options={options} />
                                                )
                                            }
                                        </div>
                                    </FormItem>
                                </div>

                                <div className="field">
                                    <p className="label">{translate('demos.input.radio.label')}</p>
                                    <FormItem hasFeedback={false} label={'Select Radio'}>
                                        <Radio.Group onChange={this.onChangeRadio.bind(this)} value={this.state.value}>
                                            <Radio value={1}>A</Radio>
                                            <Radio value={2}>B</Radio>
                                            <Radio value={3}>C</Radio>
                                            <Radio value={4}>D</Radio>
                                        </Radio.Group>
                                    </FormItem>
                                </div>

                                <div className="field">
                                    <p className="label">{translate('deoms.input.sign.label')}</p>
                                    <FormItem hasFeedback={false} label={'Mark Signature'}>
                                        <Sign />
                                    </FormItem>
                                </div>

                                <div className="field">
                                    <p className="label">Inline Field Elements</p>
                                    <div className="inline">
                                        <FormItem hasFeedback={true} label={translate('demos.name')}>
                                            {
                                                getFieldDecorator('name', name)(<Input maxLength={200} type={'text'}
                                                    placeholder={translate('common.firstName.placeholder')}
                                                    className="input" />)
                                            }
                                        </FormItem>
                                        <FormItem hasFeedback={true} label={translate('demos.email')}>
                                            {
                                                getFieldDecorator('email', email)(<Input maxLength={100}
                                                    placeholder={translate('common.email.placeholder')}
                                                    className="input" />)
                                            }
                                        </FormItem>
                                        <FormItem hasFeedback={true} label={translate('demos.input.number.label')}>
                                            {
                                                getFieldDecorator('number', number)(<Input maxLength={200}Number className="input"
                                                    placeholder="Enter Number"
                                                    min={1}
                                                    max={10} />)
                                            }
                                        </FormItem>
                                    </div>
                                </div>

                                <div className="field">
                                    <p className="label">Section Wise Fields</p>
                                    <div className="form-section">
                                        <p className="section-label">Section Name</p>
                                        <div className="content">
                                            <div className="inline">
                                                <FormItem hasFeedback={true} label={'Name'}>
                                                    {
                                                        getFieldDecorator('name', name)(<Input maxLength={200} type={'text'}
                                                            placeholder={translate('common.firstName.placeholder')}
                                                            className="input" />)
                                                    }
                                                </FormItem>
                                                <FormItem hasFeedback={true} label={'Email Address'}>
                                                    {
                                                        getFieldDecorator('email', email)(<Input maxLength={100}
                                                            placeholder={translate('common.email.placeholder')}
                                                            className="input" />)
                                                    }
                                                </FormItem>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-section">
                                        <p className="section-label">Section Name</p>
                                        <div className="content">
                                            <div className="inline">
                                                <FormItem hasFeedback={true} label={'Name'}>
                                                    {
                                                        getFieldDecorator('name', name)(<Input maxLength={200} type={'text'}
                                                            placeholder={translate('common.firstName.placeholder')}
                                                            className="input" />)
                                                    }
                                                </FormItem>
                                                <FormItem hasFeedback={true} label={'Email Address'}>
                                                    {
                                                        getFieldDecorator('email', email)(<Input maxLength={100}
                                                            placeholder={translate('common.email.placeholder')}
                                                            className="input" />)
                                                    }
                                                </FormItem>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="actions">
                                <Button htmlType={'submit'} className="btn green-btn" type="primary">Save</Button>
                                <Button htmlType={'button'} className="btn red-btn-text" onClick={hideModal}>Cancel</Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}
export default view
