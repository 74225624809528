import React from 'react';
import ApprovePendingRequest from "../containers/forms/approve-pending-request";
import RejectPendingRequest from "../containers/forms/reject-pending-request";
import { getAmountInCurrency } from "containers/container-helpers/common";
import { formatDate } from "utils/date-util";
export default {
  entity: "points-request/pendingRequests",
  entityName: "Pending Requests",
  selection : false,
  create: false,
  deleteAction: false,
  reducer: {
    entity: "pending-requests",
  },
  db: "mysql",
  defaultSort: {
    sort: "createdAt",
    sortType: "asc",
  },
  pagination: {
    type: "server",
  },
  search: false,
  columns: [
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (createdAt) => {
        return <p>{formatDate(createdAt)}</p>;
      },
    },
    {
      title: "Name",
      dataIndex: "transferToName",
    },
    {
      title: "Email",
      dataIndex: "transferToEmail",
    },
    {
      title: "Phone",
      dataIndex: "transferToPhoneNumber",
    },
    {
      title: "Order Id",
      dataIndex: "transactionId",
    },
    {
      title: "Amount",
      dataIndex: "details",
      render: (details) => {
        try {
          details = JSON.parse(details || {});
        } catch (e) {
          details = {};
          console.error("Error while parsing requestDetails");
        }
        return details.amount ? getAmountInCurrency( details.amount ) : "";
      },
    },
  ],
  rowActions: [ApprovePendingRequest, RejectPendingRequest],
};
