import React, { useState, useRef } from "react";
import preProcess from "containers/preprocess";
import { Button, Tooltip, Icon, message } from "antd";
import "./styles.scss";
import { createAction, ActionNames } from "app-redux/actions";
import point from '../../../images/point.png'
import freePoint from '../../../images/leaf.png'
/**
 * @description View Points Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ record, getBalance }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const onClick = async () => {
    setLoading(true);
    try {
      const { error, payload } = await getBalance({
        userId: record.uid,
      });
      if (error) {
        throw payload;
      }
      setData(payload.data);
    } catch (e) {
      console.error({ e });
      message.error(
        (e.response && e.response.data && e.response.data.message) ||
          "Error while getting point balance. Please try again"
      );
    }
    setLoading(false);
  };
  const markUp = (
    <Tooltip placement="top" title={data ? "Refresh" : "View Coins/Leaves"}>
      <Button className={"table-btn " + (data ? "yellow-btn-text" : "orange-btn-text")} onClick={onClick}>
        <Icon type={data ? "sync" : "eye"} spin={data? loading:""} />
      </Button>
    </Tooltip>
  );
  return record.emailVerified ? (
    data ? (
      <div className="points-balance">
        <ul>
          <li>
            <div className="points-count">
              <div className="points">
                <img src={point} />
                <span className="value">{data.balance}</span>
              </div>
            </div>
          </li>
          <li>
            <div className="points-count">
              <div className="points free">
                <img src={freePoint} />
                <span className="value">{data.freeBalance}</span>
              </div>
            </div>
          </li>
        </ul>
        {markUp}
      </div>
    ) : (
      markUp
    )
  ) : null;
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    getBalance: (data) => {
      const action = createAction(ActionNames.GET_ENTITY, {
        entityName: "points-request",
        entityId: "getPointBalance",
        ...data,
      });
      return dispatch(action);
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user }) => {
  return {};
};
Main.displayName = "View-Points";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
