import React from 'react'
import './styles.scss'
import RegisterForm from './registerForm'
import { Row, Col } from 'antd'
import organic from '../../images/organic1.jpg'

/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
    const {route,location} = this.props;
    const {query} = location;
    const { content, image,title,merchantContent, merchantImage,merchantTitle } = route.pageConfiguration || {};
    query.merchant  = query.merchant === "true" || query.merchant === true;
    return (
        <div className="join">
            <Row>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className="column text">
                    <div className="details">
                        <div className="image">
                            <img src={query.merchant ? merchantImage:image}/>
                        </div>
                        <div className="description">
                            <p className="title">{query.merchant ?merchantTitle : title}</p>
                            <p className="text">{query.merchant ? merchantContent : content}</p>
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className="column">
                    <RegisterForm referralCode = {query.referralCode} merchant = {query.merchant}/>
                </Col>
            </Row>
        </div>
    )
}
export default view
