import React from "react";
import "./styles.scss";
import { Form, Input, Button, Select, Checkbox, Icon, Tooltip } from "antd";
import FilePicker from "core-components/file-picker";
import CKEditor from "core-components/CKEditorComponent";
import TagInput from "core-components/tagInput";
const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;
const types = [
  {
    label : "Newsfeed",
    value : "newsfeed"
  },
  {
    label : "Guides",
    value : "concept"
  }
]
var view = function () {
  const { form, hideModal, data, translate } = this.props;
  const {
    title,
    shortDescription,
    description,
    image,
    tags,
    type
  } = this.validations;
  const { getFieldDecorator } = form;
  return (
    <Form onSubmit={this.handleSubmit.bind(this)}>
      <div className="new-form configuration-form">
        <div className="form">
          <Item hasFeedback={true} label={translate("newsfeed.title.image")}>
            {getFieldDecorator(
              "image",
              image
            )(
              <FilePicker
                uploadProps={{
                  accept: "*/*",
                  multiple: false,
                }}
              ></FilePicker>
            )}
          </Item>
          <Item hasFeedback={true} label={translate("newsfeed.title.label")}>
            {getFieldDecorator(
              "title",
              title
            )(
              <Input
                maxLength={200}
                className="input"
                placeholder={translate("newsfeed.title.label")}
              />
            )}
          </Item>
          <Item
              hasFeedback={true}
              label={translate("newsfeed.title.type")}
            >
              {getFieldDecorator(
                "type",
                type
              )(
                <Select className="select">
                  <Select.Option value={""}>{translate("newsfeed.placeholder.type")}</Select.Option>
                  {types.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            </Item>
          <Item
            hasFeedback={true}
            label={translate("newsfeed.title.shortDescription")}
          >
            {getFieldDecorator(
              "shortDescription",
              shortDescription
            )(
              <TextArea
                maxLength={200}
                rows={3}
                placeholder={translate("newsfeed.title.shortDescription")}
                className="input"
              />
            )}
          </Item>
          <Item
            hasFeedback={true}
            label={translate("newsfeed.title.tags")}
          >
            {getFieldDecorator("tags", tags)(<TagInput />)}
          </Item>
          <Item
            hasFeedback={true}
            label={translate("newsfeed.title.description")}
          >
            {getFieldDecorator("description", description)(<CKEditor />)}
          </Item>
        </div>
        <div className="actions">
          <Button htmlType={"submit"} type="primary" className="btn green-btn">
            {translate("common.submit")}
          </Button>
          <Button
            htmlType={"button"}
            onClick={hideModal}
            className="btn red-btn-text"
          >
            {translate("common.cancel")}
          </Button>
        </div>
      </div>
    </Form>
  );
};
export default view;
