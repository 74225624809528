import React from "react";
import {FileViewComponent} from 'core-components/file-picker'
export default {
  entity: "media",
  entityName: "Media Library",
  columns: [
    {
      title: "File Name",
      dataIndex: "name",
    },
    {
      title: "Download",
      dataIndex: "src",
      render: (value, { type }) => {
        return (
          <div className="image">
            <FileViewComponent url = {value} type = {type} />
          </div>
        );
      },
    },
  ],
  defaultSort: {
    sort: "createdAt",
    sortType: "desc",
  },
  formId: "MediaForm",
  pagination: {
    type: "server",
  },
  search: {
    filters: [
      {
        title: "File Name",
        type: "input",
        key: "name",
      },
    ],
    searchOnSubmit: true,
  },
};
