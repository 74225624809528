import React from 'react';
import './styles.scss';
import { Layout, Card, Form, Button } from 'antd';
import Localize from '../localize';

const { Content } = Layout;
var view = function () {
    const { translate } = this.props;
    return (
        <div className="page">
            <div className="general-form forgetPassword">
                <div className="content">
                    <div className="form">
                        <div className="fields">
                            <p className="title">{translate('common.wait')}</p>
                            <p className="description">{translate('common.wait.confirm.email')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default view;
