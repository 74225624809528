import React, { Component } from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Row, Col, Input, Button} from 'antd';
import Link from '../../components/link'
import VisaCard from '../../images/visa-card.png'
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
    return (
        <div className="order-confirmation">
            <div className="container">
                <div className="heading">
                    <p className="title">Thank You For Your Order </p>
                </div>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <div className="section">
                            <p className="section-title">Order details</p>
                            <div className="details order-summary">
                                <div className="summary">
                                    <div className="flex">
                                        <p className="type">Order Number</p>
                                        <p className="detail">H411745</p>
                                    </div>
                                    <div className="flex">
                                        <p className="type">Order Date</p>
                                        <p className="detail">Thu Apr 04 2020</p>
                                    </div>
                                    <div className="flex">
                                        <p className="type">Customer Name</p>
                                        <p className="detail">Joseph Smith</p>
                                    </div>
                                    <p className="reference">
                                        Please keep the above numbers for your reference.
                                        We'll also send a confirmation to the email address you used for this order.
                                        Please allow up to 24 hours for us to process your order for shipment.
                                    </p>
                                </div>
                                <div className="summary flex">
                                    <p className="type">Shipping Address</p>
                                    <p className="detail">Address , Locality , city, state , pincode</p>
                                </div>
                                <div className="summary flex">
                                    <p className="type">Delivery Method</p>
                                    <p className="detail">Standard Delivery , $00.00</p>
                                </div>
                                <div className="summary flex">
                                    <p className="type">Payment Method</p>
                                    <div className="detail">
                                        <div className="flex">
                                            <div className="image">
                                                <img src={VisaCard} />
                                            </div>
                                            <div className="card-detail">
                                                <p>***********341</p>
                                                <p>Exp: 01/2026 </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section">
                            <p className="section-title">Subscribe to our newsletter</p>
                            <div className="details newsletter form">
                                <p className="label">Email</p>
                                <Input type="text" className="input" />
                                <p className="label">Date of birth (MM/DD/YYYY)</p>
                                <div className="flex">
                                    <Input type="text" className="input" /> <span>/</span>
                                    <Input type="text" className="input" /> <span>/</span>
                                    <Input type="text" className="input" />
                                </div>
                                <Button type="text" className="green-btn">Sign Up</Button>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <div className="section">
                            <div className="flex">
                                <p className="section-title">Your order</p>
                            </div>
                            <div className="total-amount details">
                                <div className="billing">
                                    <div className="subtotal flex">
                                        <p>Item Subtotal</p>
                                        <p>$12345</p>
                                    </div>
                                    <div className="savings flex">
                                        <p>Your Savings</p>
                                        <p className="savings-amount">-$100</p>
                                    </div>
                                    <div className="gift-card flex">
                                        <p>Gift Card </p>
                                        <p>$50</p>
                                    </div>
                                    <div className="promocode flex">
                                        <p>Promocode</p>
                                        <p>-$20</p>
                                    </div>
                                </div>
                                <div className="total flex">
                                    <p>Total</p>
                                    <p>$12345</p>
                                </div>
                            </div>
                        </div>

                        <div className="section">
                            <p className="section-title">Need Help?</p>
                            <div className="details help">
                                <Link>Send Feedback</Link>
                                <Link>Contact Us</Link>
                            </div>
                            <p className="continue-shopping">Continue Shopping</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>

    )
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {}
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {

    return {}
};
Main.displayName = "Sample-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, null],
    localize: true
});
