import React from 'react'
import TestForm from 'containers/test-form'
import DuplicateForm from 'containers/duplicate-entity'
import { ModalTrigger } from 'core-components/modal'
import { Button, Tooltip, Icon } from 'antd'

export default {
    entity: 'sms-template',
    entityName: 'SMS Templates',
    formId: 'SMSTemplateForm',
    rowActions: [
        props => {
            const {record, getData} = props
            const {key} = record
            return (
                <DuplicateForm
                    entityName='sms-template'
                    entityId={key}
                    getData={getData}
                />
            )
        },
        props => {
            const {record} = props
            const {key} = record
            return (
                <ModalTrigger
                    modalProps={{
                        footer: null
                    }}
                    content={
                        <TestForm
                            fieldName='to'
                            isPhone={true}
                            actionData={{
                                template: key,
                                entityName: 'sms',
                                data: {}
                            }}
                            rules={[{
                                required: true, message: 'Phone is Required'
                            }]}
                            placeholder='91**********'
                            label='Phone Number'
                        />
                    }
                >
                    <Tooltip title="Test">
                        <Button className="btn blue-btn-text"><Icon type="file-done"/></Button>
                    </Tooltip>
                </ModalTrigger>
            )
        }
    ],
    pagination: {
        type: 'server'
    },
    search: {
        searchOnSubmit: true,
        filters: [{
            title: 'From',
            type: 'input',
            key: 'from'
        }]
    },
    defaultSort: {
        sort: 'createdAt',
        sortType: 'asc'
    },
    columns: ({sharedState: {translate}}) => [{
        title: translate('sms.key.label'),
        dataIndex: 'key'
    }, {
        title: translate('sms.from.label'),
        dataIndex: 'from'
    }]
}