import React from "react";
import "./styles.scss";
import preProcess from "containers/preprocess";
import star from "../../images/star.png";
import { Avatar, Button } from "antd";
import Link from 'core-components/link'
/**
 * @description Newsfeed Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ merchant }) => {
  return (
    <div className="merchant">
      {merchant.rating ? (
        <div className="rating">
          <img src={star} />
          {merchant.rating && <span className="value">{merchant.rating}</span>}
        </div>
      ) : null}
      <div className="details">
        <div className="image">
          <Avatar size={100} icon="user" />
        </div>
        <div className="text">
          <p className="name">{merchant.name}</p>
          <p className="time">Merchant since {merchant.since}</p>
        </div>
      </div>
      {/* <div className="package-footer">
        <div className="actions">
          <Link>
            <Button className="green-btn">View Profile</Button>
          </Link>
        </div>
      </div> */}
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};
Main.displayName = "Payment-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
