import React from 'react'
import './styles.scss'
import {
    Layout,
    Icon,
    Popover,
    Avatar,
    Button,
    Form,
    AutoComplete,
    Select,
    Tooltip,
} from 'antd'
import Link from 'core-components/link'
import Categories from '../categories'
import categories from '../categories/config'
import ChangeLanguage from 'core-components/change-language'
import Navigation from '../../navigation'
import point from '../../../images/point.png'
import { getProfilePicture } from '../../../utils/firebase'
import HeaderTopLink from '../../headerTopLink'
import LoyaltyPoints from '../../loyalty-points'
import UserLevel from '../../user-level'
import ReferModal from '../../../containers/refer-modal'
import { ModalTrigger } from 'core-components/modal'

const FormItem = Form.Item
const {Header} = Layout
const {Option} = Select

var view = function () {
    const {scrolled} = this.state
    const {WEBSITELOGO, form, ui, user, translate} = this.props
    const {drawerMode} = ui
    const {getFieldDecorator} = form
    const route = this.getCurrentRoute()
    const formMarkUp = (
        <Form onSubmit={this.handleSubmit.bind(this)}>
            <FormItem>
                {getFieldDecorator(
                    'query',
                    {}
                )(
                    <AutoComplete
                        className="input"
                        onChange={this.handleSearch}
                        onSelect={this.handleSelect}
                        placeholder="Search Products"
                    />
                )}
            </FormItem>
            <div className="action">
                <Button htmlType={'submit'} className="green-btn">
                    <Icon type="search"/>
                </Button>
            </div>
        </Form>
    )

    const content = user ? (
        <div className="user-info">
            <ul>
                <li className="details">
                    <div className="info">
                        <div className="image">
                            <Avatar icon="user" src={WEBSITELOGO}/>
                            <UserLevel/>
                        </div>
                        <div className="right">
                            <p className="text">{user.displayName}</p>
                            {/* <Link className="wishlist">
                <Icon type="heart" />
                Wishlist
              </Link> */}
                        </div>
                    </div>
                </li>
                <li>
                    <Link routeKey="profile">
                        <Icon type="setting"/>
                        Profile
                    </Link>
                </li>
                <li>
                    <Link onClick={this.logout.bind(this)}>
                        <Icon type="logout"/>
                        {translate('common.logout')}
                    </Link>
                </li>
            </ul>
        </div>
    ) : null

    return (
        <Header
            className={
                'public-header fixed ' +
                (scrolled ? 'scrolled animated slideInDown ' : '') +
                (route.routeKey === 'landing' ? 'landing' : '')
            }
        >
            <div className="section top">
                <div className="container">
                    <div className="content">
                        <div className="list">
                            <ul>
                                <li className="register">
                                    <HeaderTopLink/>
                                </li>
                                <li>
                                    <ChangeLanguage/>
                                </li>
                                {user && (
                                    <li>
                                        <LoyaltyPoints/>
                                    </li>
                                )}
                                {/* <li className="refer">
                                    <ModalTrigger
                                        content={<ReferModal/>}
                                        modalProps={{
                                            title: 'Refer & Earn',
                                            footer: null,
                                            className: 'public-modal refer-modal',
                                        }}
                                    >
                                        <Button><Icon type="notification"/> Refer</Button>
                                    </ModalTrigger>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="content">
                        <div className="left">
                            <div className="logo-container">
                                <Link routeKey="landing" className="logo">
                                    {WEBSITELOGO && <img src={WEBSITELOGO} alt={'logo'}/>}
                                </Link>
                            </div>
                        </div>
                        <div className="center">
                            <div className="search">{/* {formMarkUp} */}</div>
                        </div>
                        <div className="right">
                            <div className="navigation">
                                {drawerMode !== 'inline' && <Navigation mode="horizontal"/>}
                                <ul>
                                    {user ? (
                                        <li className="secured user">
                                            <Popover
                                                placement="bottomRight"
                                                getPopupContainer={(node) => node.parentElement}
                                                ref={(ref) => {
                                                    this.profilePopoverMenu = ref
                                                }}
                                                content={content}
                                                trigger="click"
                                            >
                                                <Avatar
                                                    icon="user"
                                                    src={getProfilePicture(user, {
                                                        height: 100,
                                                    })}
                                                />
                                            </Popover>
                                        </li>
                                    ) : (
                                        <li>
                                            <ul>
                                                <li className="login">
                                                    <Link routeKey="login">
                                                        <Button className="green-btn-text">Login</Button>
                                                    </Link>
                                                </li>
                                                <li className="register">
                                                    <Link routeKey="Register">
                                                        <Button className="green-btn">Sign Up</Button>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    )}
                                    {/* <li className="desktop">
                                        <Link>
                                            <div className="cart">
                                                <span className="cart-icon">
                                                    <Icon type="shopping-cart"/>
                                                </span>
                                                <span className="counter">4</span>
                                            </div>
                                        </Link>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="sider-trigger">
                                <Icon className="trigger" type="menu" onClick={this.toggle}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="section bottom">
                <div className="container">
                    <div className="content">
                        <div className="left">
                            <div className="list">
                                 <Categories categories={categories}/>
                            </div>
                        </div>
                        <div className="right">
                            <ul>
                                <li>
                                    <Icon type="global"/>
                                    <ChangeLanguage/>
                                </li>
                                <li>
                                    <Icon type="dollar"/>
                                    <Select defaultValue="CAD">
                                        <Option value="USD">USD</Option>
                                        <Option value="CAD">CAD</Option>
                                    </Select>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>*/}
        </Header>
    )
}
export default view
