import React from "react";
import "./styles.scss";
import { Form, Button, Icon, Tooltip, Input } from "antd";
import FilePicker from "core-components/file-picker";
import Link from "core-components/link";
const { Item } = Form;
var view = function () {
  const { form, hideModal, data, translate } = this.props;
  const { file, fileName } = this.validations;
  const { getFieldDecorator } = form;
  return (
    <Form onSubmit={this.handleSubmit.bind(this)}>
      <div className="new-form">
        <div className="form">
          {data ? (
            <Item hasFeedback={true} label={translate("media.fileName.label")}>
              {getFieldDecorator(
                "name",
                fileName(data.name)
              )(<Input maxLength={200} className="input" />)}
            </Item>
          ) : (
            <Item hasFeedback={true} label={translate("media.file.label")}>
              {getFieldDecorator(
                "file",
                file
              )(
                <FilePicker
                  uploadProps={{
                    accept: "*/*",
                    multiple: true,
                  }}
                  renderExtra={(file,index) => {
                    const { name } = file;
                    return (
                        getFieldDecorator(
                          "names[" + index + "]",
                          fileName(name, index)
                        )(<Input maxLength={200} className="input" />)
                    );
                  }}
                ></FilePicker>
              )}
            </Item>
          )}
        </div>
        <div className="actions">
          <Button htmlType={"submit"} type="primary" className="btn green-btn">
            {translate("media.submit")}
          </Button>
          <Button
            htmlType={"button"}
            onClick={hideModal}
            className="btn red-btn-text"
          >
            {translate("media.cancel")}
          </Button>
        </div>
      </div>
    </Form>
  );
};
export default view;
