import React, { Component } from "react";
import preProcess from "containers/preprocess";
import "./styles.scss";
import CKEditor from "../../components/CKEditorComponent";
import { api} from "../../redux/config";
import axios from "axios";
/**a
 * @description Ckeditor Container
 * @type Container
 * @author Inderdeep
 */
const Main = props => {
  return (
    <CKEditor
      {...props}
      uploadHeaders={[
        {
          name: "authorization",
          value: axios.defaults.headers.common["authorization"]
        }
      ]}
      uploadUrl={api.STORAGE}
      uploadRequestData={[
        {
          name: "fileCode",
          value: "ckEditorFile"
        }
      ]}
    />
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = dispatch => {
  return {
  };
};
Main.displayName = "CKEditor-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [null, bindAction],
  localize: true
});
