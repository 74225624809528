import React, { useState } from "react";
import "./styles.scss";
import { Row, Col, Button, Icon } from "antd";
import Footer from "../../containers/footer";
import Link from "../../components/link";
import organic from "../../images/organic3.jpg";
import ProductCard from "../productCard";
import preProcess from "containers/preprocess";
import Performers from '../performers'
/**
 * @description Newsfeed Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ concept, newsfeed, route }) => {
  const {
    bannerImage,
    bannerTitle,
    bannerLink,
    bannerText,
    section1Title,
    section1Image,
    section1Description,
  } = route.pageConfiguration;

  return (
    <div className="landing">
      <div
        className="banner"
        style={{
          backgroundImage: "url(" + bannerImage + ")",
        }}
      >
        <div className="overlay" />
        <div className="content">
          <p className="title">{bannerTitle}</p>
          <a href={bannerLink || "https://site.ecopence.com"}>
            <Button className="green-btn">{bannerText || "SHOP NOW & GET REWARDED"}</Button>
          </a>
        </div>
      </div>
      <div className="section about">
        <div className="container">
          <Row>
            <Col xs={24} sm={24} md={24} lg={10} xl={10} className="column">
              <div className="image">
                <img src={section1Image} alt={"About"} />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={14} xl={14} className="column">
              <div className="details">
                <div className="heading">
                  <div className="title">
                    <p>{section1Title}</p>
                  </div>
                </div>
                <div className="content description">
                  <p>
                    {section1Description}
                  </p>
                </div>
                <Link routeKey="services">
                  <Button className="green-btn">Read More</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="section posts fill-bg">
        <div className="container">
          <p className="title">Trending</p>
          <Link className="link" routeKey="newsfeed">
            See All
          </Link>
          <Row gutter={24}>
            {(newsfeed || []).map((child, index) => {
              const { image, tags, title, key, shortDescription } = child;
              return (
                <Col
                  key={index}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={8}
                  className="column"
                >
                  <Link routeKey="postDetails" routeParams={{ id: key }}>
                    <div className="card">
                      <div className="image">
                        <img src={image} />
                      </div>
                      <div className="project">
                        <div className="hashtags">
                          {tags.map((tag, index) => {
                            return (
                              <p key={index} className="hashtag">
                                {"#" + tag}
                              </p>
                            );
                          })}
                        </div>
                        <p className="title no-center">{title}</p>
                        <p className="description">{shortDescription}</p>
                      </div>
                      <div className="action">
                        <p className="view">View</p>
                        <Icon type="arrow-right" />
                      </div>
                    </div>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
      <div className="section posts">
        <div className="container">
          <p className="title">Campaigns</p>
          <Link className="link" routeKey="guides">
            See All
          </Link>
          <Row gutter={24}>
            {(concept || []).map((child, index) => {
              const { image, tags, title, shortDescription, key } = child;
              return (
                <Col
                  key={index}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={8}
                  className="column"
                >
                  <Link routeKey="postDetails" routeParams={{ id: key }}>
                    <div className="card">
                      <div className="image">
                        <img src={image} />
                      </div>
                      <div className="project">
                        <div className="hashtags">
                          {tags.map((tag, index) => {
                            return (
                              <p key={index} className="hashtag">
                                {"#" + tag}
                              </p>
                            );
                          })}
                        </div>
                        <p className="title no-center">{title}</p>
                        <p className="description">{shortDescription}</p>
                      </div>
                      <div className="action">
                        <p className="view">View</p>
                        <Icon type="arrow-right" />
                      </div>
                    </div>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
      {/* <div className = "section performers-sc fill-bg">
        <Performers/>
      </div> */}
       {/*<div className="section products">
        <div className="container">
          <p className="title">Popular Products</p>
          <Link className="link">See All</Link>
          <Row gutter={24}>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} className="column">
              <ProductCard />
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} className="column">
              <ProductCard />
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} className="column">
              <ProductCard />
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} className="column">
              <ProductCard />
            </Col>
          </Row>
        </div>
      </div>*/}
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ config, ...state }) => {
  const { newsfeed } = config || {};
  const { concept } = config || {};
  return {
    newsfeed: newsfeed ? newsfeed.newsfeed : [],
    concept: concept ? concept.concept : []
  };
};
Main.displayName = "Landing-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
