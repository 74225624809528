import React, { Component } from 'react'
import preProcess from 'containers/preprocess'
import './styles.scss'
import PostCard from '../postCard'
import { Row, Col, Button, Input, Icon } from 'antd'
import Link from '../../components/link'
import Share from 'core-components/share'

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
    return (
        <div className="share-modal-content">
            <div className="share-post">
                <div className="content">
                    <div className="image">
                        <img src=""/>
                    </div>
                    <div className="post-details">
                        <div className="text">
                            <p className="title">Post Title</p>
                            <div className="author">
                                <p className="name">Author Name, Merchant</p>
                            </div>
                            <div className="stats">
                                <p>250 Shares</p>
                                <p>2 days ago</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="share">
                <Row>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <div className="option">
                            <p className="label">Share on Ecopense</p>
                            <Button className="green-btn">Share</Button>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                        <div className="option social">
                            <p className="label">Share on social network</p>
                            <Share/>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="copy-link">
                <p className="label">Copy Link</p>
                <div className="form">
                    <Input className="input" placeholder="Post share link"/>
                    <Link className="link">
                        <Icon type="copy"/>
                    </Link>
                </div>
            </div>
        </div>
    )
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {}
}
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {

    return {}
}
Main.displayName = 'Sample-Container'
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, null],
    localize: true
})
