import React, {Component} from 'react';
import ComponentView from './view';
import preProcess from 'containers/preprocess';
import {message} from 'antd';
import {createAction, ActionNames} from "app-redux/actions";
import {goToRoute} from "../../routes";

const spinSelector = '.confirm-email-form';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {

    /**
     * Container
     * @param props
     */
    constructor(props) {
        super(props);
    }

    resendEmail() {

    }

    /**
     * ComponentDidMount Hook
     */
    async componentDidMount() {
        const {translate, location, confirmEmail, auth} = this.props;
        const {token} = location.query;
        window.startSpinning(spinSelector);
        try {
            const {error, payload} = await confirmEmail(token);
            if (error) {
                throw payload.response;
            }
            message.success(translate('signUp.accountVerification.success'));
        } catch (e) {
            switch (e.status) {
                case 403: {
                    message.error(translate('signUp.accountVerification.link.expired'));
                    break;
                }
                case 404: {
                    message.error(translate('signUp.accountVerification.invalidLink'));
                    break;
                }
                case 409: {
                    message.error(translate('signUp.accountVerification.email.already.confirmed'));
                    break;
                }
                default: {
                    message.error(translate('signUp.accountVerification.error'));
                    break;
                }
            }
        }
        window.stopSpinning(spinSelector);
        if (!auth.uid) {
            goToRoute('login', {
                search: ''
            });
        } else {

            goToRoute('home', {
                search: ''
            });
        }
    }

    /**
     * Render Method
     * @returns {*}
     */
    render() {
        return (ComponentView.bind(this))();
    }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {
        confirmEmail: data => {
            return dispatch(createAction(ActionNames.UPDATE_ENTITY, {
                entityName: 'confirm-email',
                entityId: data
            }));
        }
    }
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({firebase}) => {
    return {
        auth: firebase.auth
    }
};
Main.displayName = "Confirm-Email";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, bindAction],
    localize: true
});
