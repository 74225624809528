import React, { useState, useEffect } from "react";
import preProcess from "containers/preprocess";
import CrudForm from "../../crud-form";
import "./styles.scss";
import { parseJSON } from "utils/common";

/**
 * @description Post Form Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ translate, data, role, ...props }) => {
  if (data && data.image) {
    data = {
      ...data,
    };
  }
  const formElements = [
    {
      type: "text",
      name: "title",
      label: "post.title.title",
      required: true,
    },
    {
      type: "file",
      name: "image",
      label: "post.title.image",
      required: true,
      inputProps: {
        accept: "image/*",
        multiple: false,
      },
      fileCode: "postImage"
    },
    {
      type: "html",
      name: "description",
      label: "post.title.description",
      required: true,
    }  
  ];
  return (
    <CrudForm
      data={data}
      entity="user-post"
      elements={formElements}
      {...props}
    />
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user }) => {
  return {};
};
Main.displayName = "Post-Form-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
