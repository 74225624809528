import React, { Component } from 'react';
import ComponentView from './view';
import preProcess from '../../preprocess';
import { Form, message } from 'antd';
import { getObjectsDiff } from 'utils/common';
import { PROFILE_IMAGES_PATH } from '../../../config'
import { getEmail, uploadImage } from 'utils/firebase';
import { createAction, ActionNames } from 'app-redux/actions'

const spinningSelector = ".new-form"

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {

    /**
     * Container
     * @param props
     */
    constructor(props) {
        super(props);
        this.setValidations();
    }

    /**
     * Sets Validations for fields
     */
    setValidations() {
        const { translate } = this.props;
        this.validations = {
            file: {
                rules: [{
                    required: true,
                    message: translate('media.file.required')
                }]
            },
            fileName: (initialValue, index) => ({
                rules: [{
                    required: true,
                    message: translate('media.fileName.required') + (index !== undefined && ' at ' + index)
                }],
                initialValue
            })
        }
    }

    /**
     * ComponentDidMount Hook
     */
    componentDidMount() {

    }

    handleSubmit(event) {
        event.preventDefault();
        const { form, translate, createRecord, hideModal, createStorage, updateRecord, data, getTableData } = this.props;
        const { validateFieldsAndScroll } = form;
        validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return;
            }
            window.startSpinning(spinningSelector);
            try {
                if (data) {
                    const { error, payload } = await updateRecord({
                        entityId: data.key,
                        ...values
                    });
                    if (error) {
                        throw payload.response;
                    }
                } else {
                    const { file, names } = values;
                    await Promise.all(file.map(async (item, index) => {
                        const name = names[index];
                        const { type, dataUri } = item;
                        let { src } = item;
                        if (dataUri) {
                            let { error, payload } = await createStorage({
                                file: dataUri,
                                name,
                                type
                            });
                            if (error) {
                                throw payload.response;
                            }
                            src = encodeURI(payload.data.url);
                        }
                        const { error, payload } = await createRecord({
                            name,
                            type,
                            src
                        });
                        if (error) {
                            throw payload.data;
                        }
                    }));
                }
                message.success(translate("common.changes.save.success"));
                if (hideModal instanceof Function) {
                    hideModal();
                }
                if (getTableData instanceof Function) {
                    getTableData();
                }
            } catch (e) {
                if (e && e.status === 404) {
                    message.error(translate('media.already.exist'));
                } else {
                    message.error(translate("common.changes.save.error"));
                }
            }
            window.stopSpinning(spinningSelector);
        });
    }


    /**
     * Render Method
     * @returns {*}
     */
    render() {
        return (ComponentView.bind(this))();
    }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {
        createRecord: (data) => {
            return dispatch(createAction(ActionNames.CREATE_ENTITY, {
                entityName: "media",
                ...data
            }))
        },
        updateRecord: ({ entityId, ...data }) => {
            return dispatch(createAction(ActionNames.UPDATE_ENTITY, {
                entityName: "media",
                entityId,
                ...data
            }))
        },
        createStorage: ({ file, type, name }) => {
            return dispatch(createAction(ActionNames.CREATE_ENTITY, {
                entityName: 'storage',
                file,
                type,
                name,
                fileCode: "mediaFile"
            }));
        }
    }
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {

    return {}
};
Main.displayName = "Media-Form";
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
    connect: [mapStateToProps, bindAction],
    localize: true
});
