import React from 'react'
import './styles.scss'
import { Layout, Button, Carousel } from 'antd'

var view = function () {
    return (
        <div className="card">
            <div className="slider">
                <Carousel>
                    <div className="slide">
                        <span className="image"/>
                    </div>
                    <div className="slide">
                        <span className="image"/>
                    </div>
                    <div className="slide">
                        <span className="image"/>
                    </div>
                    <div className="slide">
                        <span className="image"/>
                    </div>
                </Carousel>
            </div>
            <div className="details">
                <p className="name">Lorem ipsum dolor</p>
                <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                <p className="price">$ 20.00</p>
            </div>
            <div className="actions">
                <Button className="green-btn">Add to Cart</Button>
            </div>
        </div>
    )
}
export default view
