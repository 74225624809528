import React from "react";
import "./styles.scss";
import { Avatar, Icon, Row, Col, Menu, Tooltip } from "antd";
import { getProfilePicture } from "../../utils/firebase";
import Link from "../../components/link";
import { getEmail } from "../../utils/firebase";
import level from "../../images/level-one.png";
import merchant from "../../images/merchant.png";
import Settings from "../../containers/settings";
import { getSinceYear } from "../container-helpers/common";
import UserLevel from '../user-level'
var view = function () {
  const { mode } = this.state;
  const { auth, user } = this.props;

  return (
    <div className="profile">
      <div className="content">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={8} lg={5} xl={5} className="column">
            <div className="details">
              <div className="user">
                <div className="badges">
                  <div className="badge isMerchant">
                    <img src={merchant} />
                    <span className="value">{user.role}</span>
                  </div>
                  {/* <div className="badge rating">
                                            <img src={star}/>
                                            <span className="value">Top 1%</span>
                                        </div> */}
                </div>
                <div className="info">
                    <div className="image">
                      <Avatar
                        size={150}
                        icon="user"
                        src={getProfilePicture(auth)}
                      />
                      <UserLevel/>
                    </div>
                  
                  <div className="text">
                    <p className="name">{auth.displayName}</p>
                    <p className="time">
                      Member Since {" " + getSinceYear(auth.createdAt)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="navigation">
                <Menu selectedKeys={"account"} mode={mode}>
                  <Menu.Item key="account">
                    <Link>
                      Account Settings <Icon type="right" />
                    </Link>
                  </Menu.Item>
                </Menu>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={16} lg={18} xl={18} className="column">
            <div className="information">
              <Settings />
            </div>
          </Col>
        </Row>
      </div>
      {/*   <div className="content">
                <div className="details">
                    <Avatar src={getProfilePicture(auth)}/>
                    <div className="form-details">
                        <div className="item">
                            <span className="label">Name</span>
                            <span className="colon">:</span>
                            <span className="value">{auth.displayName}</span>
                        </div>
                        <div className="item">
                            <span className="label">Email</span>
                            <span className="colon">:</span>
                            <span className="value">{getEmail(auth) || "No Email Address Found"}</span>
                        </div>
                        <div className="item">
                            <span className="label">Phone No.</span>
                            <span className="colon">:</span>
                            <span className="value">{auth.phoneNumber || "No Phone Number Found"}</span>
                        </div>
                    </div>
                    <div className="action">
                        <Link routeKey="settings">
                            <Button size="large" className="edit-btn">Edit Profile</Button>
                        </Link>
                    </div>
                </div>
            </div>*/}
    </div>
  );
};
export default view;
