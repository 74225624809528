import React, {Component} from 'react';
import ComponentView from './view';
import preProcess from 'containers/preprocess';
import {message} from 'antd';
import {ActionNames, createAction} from "app-redux/actions";
const spinningSelector='.page';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {

    /**
     * Container
     * @param props
     */
    constructor(props) {
        super(props);
        this.resendEmail=this.resendEmail.bind(this);
    }

    /**
     * Resend Email
     * @returns {Promise<void>}
     */
    async resendEmail(){
        const {sendEmail,translate}=this.props;
        window.startSpinning(spinningSelector);
        try{
            const {error,payload}=await sendEmail();
            if(error){
                throw payload.response;
            }
            message.success(translate('signUp.accountVerification.sent.success'))
        }catch(e){
            console.warn(e);
            message.error(translate('signUp.accountVerification.sent.error'))
        }
        window.stopSpinning(spinningSelector);
    }

    /**
     * ComponentDidMount Hook
     */
    componentDidMount() {

    }

    /**
     * Render Method
     * @returns {*}
     */
    render() {
        return (ComponentView.bind(this))();
    }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {
        sendEmail:()=>{
            return dispatch(createAction(ActionNames.RESEND))
        }
    }
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
    return {}
};
Main.displayName = "Email-Not-Confirm-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, bindAction],
    localize: true
});
