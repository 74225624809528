import React from "react";
import "./styles.scss";
import { Menu } from "antd";
import menus from "./config";
import Link from "core-components/link";

const { SubMenu, Item } = Menu;
var view = function () {
  const { mode } = this.props;
  return (
    <div className="navigation-list">
      <Menu mode={mode} onClick={this.handleMenuClick}>
        {menus.map((item, index) => {
          const { name, children, key, url } = item;
          if (children) {
            return (
              <SubMenu key={index} title={name}>
                {children.map((child, index) => {
                  const { name, key , url} = child;
                  return (
                    <Item key={index}>
                      {url ? (
                        <a href={url}>
                          <span>{name}</span>
                        </a>
                      ) : (
                        <Link routeKey={key}>
                          <span>{name}</span>
                        </Link>
                      )}
                    </Item>
                  );
                })}
              </SubMenu>
            );
          } else {
            return (
              <Item key={index}>
                {url ? (
                  <a href={url}>
                    <span>{name}</span>
                  </a>
                ) : (
                  <Link routeKey={key}>
                    <span>{name}</span>
                  </Link>
                )}
              </Item>
            );
          }
        })}
      </Menu>
    </div>
  );
};
export default view;
