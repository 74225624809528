import React, { Component } from "react";
import ComponentView from "./view";
import preProcess from "../../preprocess";
import { Form, message } from "antd";
import { getObjectsDiff } from "utils/common";
import { createAction, ActionNames } from "app-redux/actions";

const spinningSelector = ".new-form";

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.setValidations();
  }

  /**
   * Sets Validations for fields
   */
  setValidations() {
    const { data, translate } = this.props;
    this.validations = {
      title: {
        rules: [
          {
            required: true,
            message: translate("common.field.error.required"),
          },
        ],
        initialValue: data ? data.title : null,
      },
      description: {
        rules: [
          {
            required: true,
            message: translate("common.field.error.required"),
          },
        ],
        initialValue: data ? data.description : null,
      },
      shortDescription: {
        rules: [
          {
            required: true,
            message: translate("common.field.error.required"),
          },
        ],
        initialValue: data ? data.shortDescription : null,
      },
      image: {
        rules: [
          {
            required: true,
            message: translate("common.field.error.required"),
          },
        ],
        initialValue: data ? data.image : null,
      },
      tags: {
        rules: [
          {
            required: true,
            message: translate("common.field.error.required"),
          },
        ],
        initialValue: data ? data.tags : null,
      },
      type: {
        rules: [
          {
            required: true,
            message: translate("common.field.error.required"),
          },
        ],
        initialValue: data ? data.type : null,
      },
    };
  }

  /**
   * ComponentDidMount Hook
   */
  componentDidMount() {}

  handleSubmit(event) {
    event.preventDefault();
    const {
      form,
      translate,
      createRecord,
      hideModal,
      createStorage,
      updateRecord,
      data,
      getTableData,
    } = this.props;
    const { validateFieldsAndScroll } = form;
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      const { image } = values;
      window.startSpinning(spinningSelector);
      try {
        let action;
        if (image && image.dataUri && image.type && image.name) {
          const { dataUri, type, name } = image;
          action = await createStorage({
            file: dataUri,
            type,
            name,
          });
          if (action.error) {
            throw action.payload.response;
          }
          values.image = encodeURI(action.payload.data.url);
        }
        
        if (data) {          
          action = await updateRecord({
            ...values,
            key: data.key,
          });
        } else {
          action = await createRecord(values);
        }
        if (!action.error) {
          message.success(translate("common.changes.save.success"));
          hideModal();
        } else {
          throw action.payload.response;
        }
        if (getTableData instanceof Function) {
          getTableData();
        }
      } catch (e) {
        if (e.status === 404) {
          message.error("Record Already Exist");
        } else {
          message.error(translate("common.changes.save.error"));
        }
      }
      window.stopSpinning(spinningSelector);
    });
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    createRecord: (data) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "newsfeed",
          ...data,
        })
      );
    },
    updateRecord: ({ entityId, ...data }) => {
      const key = data.key;
      delete data.key;
      return dispatch(
        createAction(ActionNames.UPDATE_ENTITY, {
          entityName: "newsfeed",
          entityId: key,
          ...data,
        })
      );
    },
    createStorage: ({ file, type, name }) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "storage",
          file,
          type,
          name,
          fileCode: "newsfeedFile",
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};
Main.displayName = "User-Form";
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
