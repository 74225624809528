import React, { Component } from 'react'
import preProcess from 'containers/preprocess'
import './styles.scss'
import {} from 'antd'
import { Row } from 'antd'
import { Col } from 'antd'
import { Button } from 'antd'
import Share from 'core-components/share'
import { Input } from 'antd'
import Link from 'core-components/link'
import { Icon } from 'antd'
import LoyaltyPoints from '../loyalty-points'
import point from '../../images/point.png'

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {

    return (
        <div className="refer-modal-content">
            <div className="offer">
                <p className="title">Refer your friends and earn 50 loyality points.</p>
                <div className="points-count">
                    <div className="points">
                        <img src={point}/>
                        <span className="value">50</span>
                    </div>
                </div>
            </div>
            <div className="copy-link">
                <p className="label">Copy refer link</p>
                <div className="form">
                    <Input className="input" placeholder="Post share link"/>
                    <Link className="link">
                        <Icon type="copy"/>
                    </Link>
                </div>
            </div>
            <div className="share">
                <div className="option social">
                    <p className="label">Share refer link on social network</p>
                    <Share/>
                </div>
            </div>
        </div>
    )
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {}
}
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {

    return {}
}
Main.displayName = 'Sample-Container'
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, null],
    localize: true
})
