import { message } from "antd";

export default {
  entity: "levels",
  entityName: "Levels",
  form: {
    elements: [
      {
        type: "number",
        name: "number",
        label: "level.title.number",
        required: true,
        inputProps : {
          min : 1,
          max : 11
        }
      },
      {
        type: "text",
        name: "title",
        label: "level.title.title",
        required: true,
      },
      {
        type: "number",
        name: "minPointBalance",
        label: "level.title.minPointBalance",
        required: true,
      },
      {
        type: "number",
        name: "maxPointBalance",
        label: "level.title.maxPointBalance",
        required: true,
      },
      {
        type: "number",
        name: "minPointsFromPurchases",
        label: "level.title.minPointsFromPurchases",
        required: true,
      },
      {
        type: "file",
        name: "image",
        label: "level.title.image",
        required: false,
        inputProps: {
          accept: "image/*",
          multiple: false,
        },
        fileCode: "levelImage"
      },
    ],
    onError : (err,{translate})=>{
      if(err && err.response && err.response.status === 409){
        message.error(translate("level.error.duplicate"));
      } else {
        message.error(translate("common.changes.save.error"));
      }
    }
  },
  pagination: {
    type: "server",
  },
  defaultSort: {
    sort: "createdAt",
    sortType: "asc",
  },
  columns: ({ sharedState: { translate } }) => [
    {
      title: translate("level.title.number"),
      dataIndex: "number",
    },
    {
      title: translate("level.title.title"),
      dataIndex: "title",
    },   
    {
      title: translate("level.title.minPointBalance"),
      dataIndex: "minPointBalance",
    },
    {
      title: translate("level.title.maxPointBalance"),
      dataIndex: "maxPointBalance",
    },
    {
      title: translate("level.title.minPointsFromPurchases"),
      dataIndex: "minPointsFromPurchases",
    }
  ],
  search: {
    filters: [
      {
        title: "Title",
        type: "input",
        key: "title",
      },
    ],
    searchOnSubmit: true,
  },
};
