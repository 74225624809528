import React, { Component } from 'react'
import ComponentView from './view'
import preProcess from 'containers/preprocess'
import { Form } from 'antd'

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {


    /**
     * Container
     * @param props
     */
    constructor(props) {
        super(props);
        this.setValidations()
    }

    /**
     * ComponentDidMount Hook
     */
    componentDidMount() {
        this.translate();
    }

    translate(){
    }

    /**
     * Sets Validations for fields
     */
    setValidations() {
        const { translate } = this.props
        this.validations = {
            email: {
                rules: [
                    { required: true, message: translate('common.email.error.required') },
                    { type: 'email', message: translate('common.email.error.invalid') }
                ],
            },
        }

    }

    /**
     * On Submit of  Form
     * @param event
     */
    async handleSubmit(event) {
        event.preventDefault();

    }

    /**
     * Render Method
     * @returns {*}
     */
    render () {
        return (ComponentView.bind(this))()
    }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {}
}
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({config}) => {

    const {configuration} = config || {}
    const {WEBSITELOGO} = configuration || {}
    return {
        WEBSITELOGO
    }
}
Main.displayName = 'Footer-Container'
//Pre process the container with Redux Plugins
export default preProcess((Form.create())(Main), {
    connect: [mapStateToProps, null],
    localize: true
})
