import React from "react";
import "./styles.scss";
import { Row, Col, Card, Button } from "antd";
import Link from "../../components/link";
import UserDashboard from "./userDashboard";
import AdminDashboard from "./adminDashboard";
var view = function() {
  const { user } = this.props;
  return user && (user.role || "").toLowerCase() === "admin" ? (
    <AdminDashboard />
  ) : (
    <UserDashboard />
  );
};
export default view;
