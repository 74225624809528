import React from 'react'
import { Row, Col, Card, Button, Icon } from 'antd'
import Link from '../../../components/link'
import './styles.scss'

var view = function () {
    const { dashboard, translate } = this.props;
    return (
        <div className="dashboard">
            <div className="content">
                {/* <p className="page-title">{translate('dashboard.title')}</p> */}
                <Row gutter={16}>
                    
                </Row>
            </div>
        </div>
    )
}
export default view
