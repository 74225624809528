import React from "react";
import { Button, Tooltip, Icon, Switch } from "antd";
import UpdateEntity from "containers/update-entity";
import { formatDate } from "utils/date-util";
import AddPoints from "../containers/forms/add-points";
import ViewPoints from "../containers/forms/view-points";
export default {
  entityName: "Customers",
  entity: "users",
  getRequestParams: () => {
    return {
      role: "CUSTOMER",
    };
  },
  formProps: {
    role: "CUSTOMER",
  },
  displayType: "card",
  role: "admin",
  pagination: {
    type: "server",
  },
  selection: ({ uid }) => ({
    getCheckboxProps: (record) => ({
      disabled: record.uid === uid,
    }),
  }),
  defaultSort: {
    sort: "createdAt",
    sortType: "desc",
  },
  shouldShowActions: ({ sharedState }) => {
    return (text, row, markup) => {
      const { uid } = sharedState;
      return uid === row.uid ? <p>You</p> : markup;
    };
  },
  reducer: {
    stateKeys: [
      {
        key: "uid",
        fn: (state) => {
          return state.firebase.auth && state.firebase.auth.uid;
        },
      },
    ],
  },
  recordKey: "uid",
  search: {
    filters: [
      {
        title: "Name",
        type: "input",
        key: "displayName",
      },
      {
        title: "Email Address",
        type: "input",
        key: "email",
      },
    ],
    searchOnSubmit: true,
  },
  columns: ({ sharedState: { translate }, getTableData }) => [
    {
      title: translate("user.name.label"),
      dataIndex: "displayName",
      width: 250,
    },
    // {
    //   title: translate("user.type.label"),
    //   dataIndex: "providerData",
    //   width: 250,
    //   render: (providerData) => {
    //     const [provider] = providerData || [];
    //     const { providerId } = provider || {};
    //     return <p>{providerId || "password"}</p>;
    //   },
    // },
    {
      title: translate("user.email.label"),
      dataIndex: "email",
      width: 250,
    },
    // {
    //   title: translate("user.role.label"),
    //   dataIndex: "customClaims.role",
    //   width: 200,
    // },
    // {
    //   title: translate("user.lastSignAt"),
    //   dataIndex: "metadata.lastSignInTime",
    //   width: 200,
    // },
    {
      title: "Balance",
      dataIndex: "metadata.lastSignInTime",
      width: 200,
      render: (val, record) => {
        return <ViewPoints record={record} />;
      },
    },
    {
      title: translate("user.enabled"),
      dataIndex: "disabled",
      render: (value, record) => {
        return (
          <UpdateEntity
            entityName="users"
            entityIdPropName="uid"
            record={record}
            getChildren={({ loading, updateEntity, record }) => {
              return record.role !== "admin" ? (
                <Switch
                  loading={loading}
                  checked={!record.disabled}
                  onChange={async (checked) => {
                    await updateEntity({ disabled: !checked });
                    getTableData();
                  }}
                />
              ) : null;
            }}
          />
        );
      },
      width: 200,
    },
    {
      title: translate("user.createdAt"),
      dataIndex: "createdAt",
      render: (createdAt) => {
        return <p>{formatDate(createdAt)}</p>;
      },
      width: 250,
    },
  ],
  rowActions: [
    ({ record, getTableData }) => {
      return (
        <UpdateEntity
          entityName="users"
          entityIdPropName="uid"
          record={record}
          getChildren={({ loading, updateEntity, record }) => {
            return !record.emailVerified ? (
              <Tooltip placement="top" title={"Approve"}>
                <Button
                  className="edit"
                  onClick={async () => {
                    if (loading) {
                      return false;
                    }
                    await updateEntity({ emailVerified: true });
                    getTableData();
                  }}
                >
                  <Icon type={loading ? "loading" : "check"} />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip placement="top" title={"Approved"}>
                <Button className="edit">
                  <Icon type="check-circle" />
                </Button>
              </Tooltip>
            );
          }}
        />
      );
    },
    AddPoints,
  ],
  actionsWidth: 230, // Width of actions column (which contains edit,delete button etc.
  formId: "UserForm",
};
