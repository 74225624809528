import React from "react";
import { FileViewComponent } from "core-components/file-picker";
import { parseJSON } from "utils/common";
import { shippingTypes, productStatus } from "../config";
import DuplicateForm from "containers/duplicate-entity";
import { Button, Tooltip, Icon, Switch } from "antd";
import UpdateEntity from "containers/update-entity";
import { Select } from "antd";
export default {
  entity: "product",
  entityName: "Products",
  formId: "ProductForm",
  db: "mysql",
  pagination: {
    type: "server",
  },
  defaultSort: {
    sort: "createdAt",
    sortType: "desc",
  },
  getRequestParams: (props, additionalState) => {
    return props.role !== "admin"
      ? {
          search: `createdBy;${props.uid};eq`,
        }
      : undefined;
  },
  rowActions: [
    ({ record, getTableData ,sharedState}) => {
    
      return sharedState.role === "admin" ? (
        <UpdateEntity
          entityName="product"
          record={record}
          getChildren={({ loading, updateEntity, record }) => {
            return record.status !== productStatus[1].value ? (
              <Tooltip placement="top" title={"Approve"}>
                <Button
                  className="edit"
                  onClick={async () => {
                    if (loading) {
                      return false;
                    }
                    await updateEntity({ status: productStatus[1].value });
                    getTableData();
                  }}
                >
                  <Icon type={loading ? "loading" : "check"} />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip placement="top" title={"Approved"}>
                <Button className="edit">
                  <Icon type="check-circle" />
                </Button>
              </Tooltip>
            );
          }}
        />
      ) : null;
    },
    (props) => {
      const { record, getTableData } = props;
      const { key } = record;
      return (
        <DuplicateForm
          entityName="product"
          entityId={key.toString()}
          getData={getTableData}
          auto={true}
        />
      );
    },
  ],
  reducer: {
    stateKeys: [
      {
        key: "uid",
        fn: (state) => {
          return state.firebase.auth && state.firebase.auth.uid;
        },
      },
      {
        key: "role",
        fn: (state) => {
          return state.user && state.user.role;
        },
      },
    ],
  },
  initialize: [
    {
      entity: "product-category",
      data: { all: true },
      skipReducer: true,
      key: "categories",
    },
  ],
  columns: ({ sharedState: { translate } }) => [
    {
      title: translate("product.title.sku"),
      dataIndex: "sku",
    },
    {
      title: translate("product.title.name"),
      dataIndex: "name",
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (value, { type }) => {
        return (
          <div className="image">
            <FileViewComponent
              url={(parseJSON(value) || [])[0]}
              type={"image"}
            />
          </div>
        );
      },
    },
    {
      title: translate("product.title.category"),
      dataIndex: "categoryName",
    },
    {
      title: translate("product.title.status"),
      dataIndex: "status",
    },
  ],
  search: (props, additionalState) => {
    return {
      filters: [
        {
          title: "Name",
          type: "input",
          key: "name",
        },
        {
          title: "Category",
          type: "select",
          key: "category",
          props: {
            dropdownStyle: {
              width: 200,
            },
          },
          options: (additionalState.categories || []).map(
            ({ name, key }, index) => {
              return (
                <Select.Option key={index} value={key}>
                  {name}
                </Select.Option>
              );
            }
          ),
        },
        {
          title: "Status",
          type: "select",
          key: "status",
          options: productStatus.map(({ label, value }, index) => {
            return (
              <Select.Option key={index} value={value}>
                {label}
              </Select.Option>
            );
          }),
        },
      ],
      searchOnSubmit: true,
    };
  },
};
