export default {
    entity:'theme',
    entityName:'Themes',
    formId:'ThemeForm',
    defaultSort: {
        sort: 'createdAt',
        sortType: 'asc'
    },
    pagination:{
        type:'server'
    },
    search:{
        searchOnSubmit:true,
        filters:[{
            title:'Key',
            key:'key',
            type:'input'
        }]
    },
    columns:[{
        title:'Code',
        dataIndex:'key'
    },{
        title:'Color',
        dataIndex:'value'
    }]
};