import React, { Component } from "react";
import ComponentView from "./view";
import preProcess from "../../preprocess";
import { createAction, ActionNames } from "../../../redux/actions";
import { getCurrentRoute } from "../../container-helpers/routing";
import { Form, message } from "antd";
import { goToRoute } from "../../../routes";

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
      visible: false,
      mode: "horizontal",
      value: "",
      dataSource: [],
    };
    this.toggle = this.toggle.bind(this);
    this.getCurrentRoute = getCurrentRoute.bind(this);
    this.setValidations();
  }

  toggle() {
    const { setUiProperty, ui } = this.props;
    setUiProperty({
      name: "drawer",
      value: !ui.drawer,
    });
  }

  /**
   * Sets Validations for fields
   */
  setValidations() {
    const { translate } = this.props;
    this.validations = {
      number: {
        rules: [{ required: true, message: "Please enter tracking number" }],
      },
    };
  }

  /**
   * ComponentDidMount Hook
   */
  componentDidMount() {
    const { getUserLevel,emitter } = this.props;
    const { width } = window.screen;
    this.setState({
      mode: width < 1280 ? "inline" : "horizontal",
    });
    window.onresize = () => {
      const { width } = window.screen;
      this.setState({
        mode: width < 1280 ? "inline" : "horizontal",
      });
    };
    window.addEventListener("scroll", (event) => {
      if (window.pageYOffset > 200) {
        this.setState({
          scrolled: true,
        });
      } else {
        this.setState({
          scrolled: false,
        });
      }
    });
    emitter.addListener("USER_FETCHED",()=>{
      getUserLevel();
    })    
  }

  handleSubmit(e) {
    e.preventDefault();
  }
  /**
   * Logout
   */
  logout() {
    const { firebase } = this.props;
    this.closeProfilePopoverMenu();
    firebase.logout();
    goToRoute("login", {
      forceRefresh: true,
    });
  }
  /**
   * Close Popover menu
   */
  closeProfilePopoverMenu() {
    if (this.profilePopoverMenu) {
      this.profilePopoverMenu.tooltip.setState({
        visible: false,
      });
    }
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    setUiProperty: (data) => {
      return dispatch(createAction(ActionNames.UI_SET_PROPERTY, data));
    },
    getPointBalance: () => {
      const action = createAction(ActionNames.GET_ENTITY, {
        entityName: "points-request",
        entityId: "getPointBalance",
      });
      action.type = action.type + "_points-request";
      return dispatch(action);
    },
    getUserLevel: () => {
      const action = createAction(ActionNames.GET_ENTITY, {
        entityName: "user-level",
        entityId: "current",
      });
      action.type = action.type + "_user-level";
      return dispatch(action);
    },
    
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ ui, config, firebase, user,emitter }) => {
  const { configuration } = config || {};
  const { WEBSITELOGO } = configuration || {};
  return {
    ui,
    WEBSITELOGO,
    auth: firebase.auth,
    user,
    emitter
  };
};

Main.displayName = "Header-Container";
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
  connect: [mapStateToProps, bindAction],
  localize: true,
  withRouter: true,
  firebase: true,
});
