import React, { Component } from "react";
import preProcess from "containers/preprocess";
import "./styles.scss";
import { Button } from "antd";
import Link from 'core-components/link';

/**
 * @description headerTopLink Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ user }) => {
  let headerTopLink = null;
  if (user) {
    if (user.role !== "CUSTOMER") {
      headerTopLink = (
        <Link routeKey="home" queryParams={{ merchant: true }}>
          <Button className="green-btn small-btn">{
              `${user.role.toUpperCase()} Portal`
          }</Button>
        </Link>
      );
    }
  } else {
    headerTopLink = (
      <Link routeKey="Register" queryParams={{ merchant: true }}>
        <Button className="green-btn small-btn">Merchant Registration</Button>
      </Link>
    );
  }
  return headerTopLink;
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user }) => {
  return { user };
};
Main.displayName = "HeaderTopLink-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
