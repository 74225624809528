import React, { Component } from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Select, Button } from 'antd';
import ItemImage from '../../images/organic1.jpg'
import VisaCard from '../../images/visa-card.png'

const { Option } = Select;

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
    return (
        <div className="order-details">
            <div className="container">
                <div className="heading">
                    <p className="title">Orders Details</p>
                </div>
                <div className="sub-heading">
                    <div className="about">
                        <p>Ordered on 19 july 2020</p>
                        <span>|</span>
                        <p>Order# 000-000-000</p>
                    </div>
                    <div className="invoice">
                        <p>Invoice</p>
                    </div>
                </div>
                <div className="detailed-summary">
                    <div className="section">
                        <div className="shipping-address">
                            <p className="bold">Shipping Address</p>
                            <p>Person's Name</p>
                            <p>Address </p>
                            <p>State</p>
                            <p>Country</p>
                        </div>
                        <div className="payment-method">
                            <p className="bold">Payment Method</p>
                            <div className="flex">
                                <div className="image">
                                    <img src={VisaCard} />
                                </div>
                                <p>***********341</p>
                            </div>
                        </div>
                    </div>
                    <div className="section order-summary">
                        <p className="bold">Order Summary</p>
                        <div className="flex">
                            <p>Item Subtotal</p>
                            <p> $00.00</p>
                        </div>
                        <div className="flex">
                            <p>Shipping</p>
                            <p> $00.00</p>
                        </div>
                        <div className="flex">
                            <p>Total</p>
                            <p> $00.00</p>
                        </div>
                        <div className="flex">
                            <p>Promotion Applied</p>
                            <p> $00.00</p>
                        </div>
                        <div className="flex">
                            <p className="bold">Grand Total</p>
                            <p className="bold"> $00.00</p>
                        </div>
                    </div>
                </div>
                <div className="order">
                    <div className="orders">
                        <div className="order-summary">
                            <p className="type">2 SHIPMENTS</p>
                        </div>

                        <div className="order-placed">
                            <div className="sections">
                                <div className="delivery">
                                    <p>Delivered yesterday</p>
                                </div>
                                <div className="details">
                                    <div className="image">
                                        <img src={ItemImage} />
                                    </div>
                                    <div className="about-order">
                                        <p className="item-name">Item name in detail</p>
                                        <p className="price">₹00.00</p>
                                    </div>
                                </div>
                            </div>
                            <div className="sections">
                                <Button className="green-btn-text">Track package</Button>
                                <Button className="green-btn-text">Return or replace item</Button>
                                <Button className="green-btn-text">Share gift receipt</Button>
                                <Button className="green-btn-text">Write a review</Button>
                            </div>
                        </div>

                        <div className="order-placed">
                            <div className="sections">
                                <div className="delivery">
                                    <p>Delivered yesterday</p>
                                </div>
                                <div className="details">
                                    <div className="image">
                                        <img src={ItemImage} />
                                    </div>
                                    <div className="about-order">
                                        <p className="item-name">Item name in detail Item name in detail Item name in detailItem name in detail Item name in detail Item name in detail Item name in detail Item name in detail</p>
                                        <p className="price">₹00.00</p>
                                    </div>
                                </div>
                            </div>
                            <div className="sections">
                                <Button className="green-btn-text">Track package</Button>
                                <Button className="green-btn-text">Return or replace item</Button>
                                <Button className="green-btn-text">Share gift receipt</Button>
                                <Button className="green-btn-text">Write a review</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {}
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {

    return {}
};
Main.displayName = "Sample-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, null],
    localize: true
});
