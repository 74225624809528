import React, { useEffect, useState } from 'react'
import './styles.scss'
import {
    Row,
    Col,
    Form,
    Input,
    Checkbox,
    Button,
    message,
    InputNumber,
} from 'antd'
import Banner from 'core-components/banner'
import preProcess from 'containers/preprocess'
import { createAction, ActionNames } from 'app-redux/actions'
import { goToRoute } from '../../routes'
import PaymentTrigger from '../payment-trigger'
import Packages from '../packages'
import {
    convertCentsToCurrency,
    getAmountInCents,
    getAmountInCurrency,
} from '../container-helpers/common'

const FormItem = Form.Item
/**
 * @description Buy Points Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({form, user, purchasePoints, getPointBalance}) => {
    const {
        getFieldDecorator,
        validateFieldsAndScroll,
        getFieldValue,
        resetFields,
    } = form
    const points = getFieldValue('points')
    const pointValue = user.guaranteedPointValue || 0
    let amount = 0
    if (points) {
        amount = convertCentsToCurrency(points * pointValue)
    }
    useEffect(() => {
        if (user.role !== 'MERCHANT') {
            goToRoute('landing')
        } else {
            if (
                user.merchantType !== 'PARTNER' ||
                (user.merchantType === 'PARTNER' && !user.startupPackage)
            ) {
                goToRoute('packages')
            }
        }
    }, [])
    const handleSubmit = async (event) => {
        event.preventDefault()
        validateFieldsAndScroll(async (errors) => {
            if (errors) {
                return
            }
        })
    }
    const onSuccess = async (response) => {
        const {key, data} = response
        const {nonce, ...transactionData} = data
        const {error} = await purchasePoints({
            points,
            transactionInfo: {
                nonce,
                method: key,
                data: transactionData,
            },
        })
        if (!error) {
            getPointBalance()
            message.success('Package purchased successfully')
            resetFields()
        } else {
            message.error('There was an error while processing your transaction')
        }
    }

    return (
        <div className="request buy-points">
            <div className="content">
                <Banner title={'Buy more Coins'}/>
                <Packages showAsComponent={true}/>
                {pointValue && (
                    <div className="section custom">
                        <div className="container">
                            <div className="heading">
                                <p className="title">or Buy Custom Coins</p>
                            </div>
                            <div className="details">
                                <Row gutter={48}>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <div className="form">
                                            <p className="label">Enter the Coins below</p>
                                            <p className="message">
                                                You have a guaranteed price per coin :<span className="bold">{' '}
                                                {getAmountInCents(pointValue)}</span>
                                            </p>
                                            <p className="final-amount">
                                                Amount : {getAmountInCurrency(amount)}
                                            </p>
                                            <Form onSubmit={handleSubmit.bind(this)}>
                                                <div className="inline">
                                                    <FormItem hasFeedback={true}>
                                                        {getFieldDecorator('points', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: 'This field is required',
                                                                },
                                                            ],
                                                        })(
                                                            <InputNumber
                                                                placeholder="Enter the number of coins to be purchased"
                                                                className="input"
                                                            />
                                                        )}
                                                    </FormItem>
                                                </div>
                                                {points ? (
                                                    <PaymentTrigger
                                                        label="Purchase"
                                                        amount={amount}
                                                        onSuccess={onSuccess}
                                                    />
                                                ) : (
                                                    <div className="actions">
                                                        <Button htmlType="submit" className="green-btn">
                                                            Purchase
                                                        </Button>
                                                    </div>
                                                )}
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {
        purchasePoints: (data) => {
            return dispatch(
                createAction(ActionNames.CREATE_ENTITY, {
                    entityName: 'points-request/purchasePoints',
                    ...data,
                })
            )
        },
        getPointBalance: () => {
            const action = createAction(ActionNames.GET_ENTITY, {
                entityName: 'points-request',
                entityId: 'getPointBalance',
            })
            action.type = action.type + '_points-request'
            return dispatch(action)
        },
    }
}
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({user}) => {
    return {user}
}
Main.displayName = 'Buy-Points-Container'
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
    connect: [mapStateToProps, bindAction],
    localize: true,
})
