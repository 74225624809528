import React, { Component } from 'react'
import preProcess from 'containers/preprocess'
import './styles.scss'
import { Row, Col, Avatar, Icon, Menu } from 'antd'
import Link from 'core-components/link'
import PostCard from '../../containers/postCard'
import { ModalTrigger } from 'core-components/modal'

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const posts = [
    {
        image: '',
        title: 'Post title',
        author: 'Author Name',
        role: 'Merchant',
        shares: 250,
        route: '',
    },
    {
        image: '',
        title: 'Post title',
        author: 'Author Name',
        role: 'Merchant',
        shares: 250,
        route: '',
    },
    {
        image: '',
        title: 'Post title',
        author: 'Author Name',
        role: 'Merchant',
        shares: 250,
        route: '',
    },
]
const Main = () => {
    return (
        <div className="social-page">
            <div className="content">
                <div className="links">
                    <div className="navigation">
                        <Menu selectedKeys={'home'}>
                            <Menu.Item key="home">
                                <Link>
                                    Home <Icon type="right"/>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="trending">
                                <Link>
                                    Trending <Icon type="right"/>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="account">
                                <Link>
                                    Your Posts <Icon type="right"/>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="newPost">
                                <ModalTrigger modalId="PostForm" modalProps={{footer: null, title: 'New Post'}}>
                                    <Link>
                                        Create New Post <Icon type="right"/>
                                    </Link>
                                </ModalTrigger>
                            </Menu.Item>
                        </Menu>
                    </div>
                </div>
                <div className="data">
                    <div className="section">
                        <p className="label">Recommended Posts</p>
                        <Row gutter={24}>
                            {posts.map((item, index) => {
                                return (
                                    <Col
                                        key={index}
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={8}
                                        xl={8}
                                        className="column"
                                    >
                                        <PostCard data={item}/>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                    <div className="section">
                        <p className="label">Merchant Posts</p>
                        <Row gutter={24}>
                            {posts.map((item, index) => {
                                return (
                                    <Col
                                        key={index}
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={8}
                                        xl={8}
                                        className="column"
                                    >
                                        <PostCard data={item}/>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {}
}
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
    return {}
}
Main.displayName = 'Social-Container'
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, null],
    localize: true,
})
