import React, { Component } from 'react'
import ComponentView from './view'
import preProcess from '../preprocess'
import { Form, message } from 'antd'
import { getObjectsDiff } from '../../utils/common'
import { PROFILE_IMAGES_PATH } from '../../config'
import { getEmail, uploadImage } from 'utils/firebase'
import { createAction, ActionNames } from '../../redux/actions'

const spinningSelector = '.new-form'

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {

    /**
     * Container
     * @param props
     */
    constructor (props) {
        super(props)
        this.state = {
            value: '',
            dataSource: [],
        }
        this.onPayment=this.onPayment.bind(this);
        this.setValidations()
    }

    /**
     * Sets Validations for fields
     */
    setValidations () {
        const {translate, data} = this.props
        this.validations = {
            name: {
                rules: [
                    {required: true, message: translate('common.firstName.error.required')}
                ],
                initialValue: data ? data.displayName : null
            },
            email: {
                rules: [
                    {required: !data && true, message: translate('common.email.error.required')},
                    {type: 'email', message: translate('common.email.error.invalid')}
                ],
                initialValue: data ? getEmail(data) : null
            },
            password: {
                rules: [{required: !data && true, message: translate('common.password.error.required')}]
            },
            number: {
                rules: [{required: !data && true, message: translate('common.number.error.required')},
                    {type: 'number', message: translate('common.number.error.invalid')}]
            },
            photoURL: {
                initialValue: data ? data.photoURL : null
            },
            role: {
                rules: [
                    {required: true, message: 'Role is mandatory'}
                ],
                initialValue: data ? ((data.customClaims || {}).role) : ''
            }
        }
    }

    /**
     * ComponentDidMount Hook
     */
    async componentDidMount () {
        const {getRoles} = this.props
        await getRoles()
    }

    handleSubmit (event) {
        event.preventDefault()
        const {validateFieldsAndScroll} = this.props.form
        const {translate, createUser, getTableData, hideModal, updateUser, data} = this.props
        validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return
            }
            /**
             * If no external provider is used, email or phone is mandatory
             */
            if (data && !data.phoneNumber && !data.email && data.providerData.length == 0) {
                message.error(translate('common.phoneOrEmail.error.required'))
                return
            }
            window.startSpinning(spinningSelector)
            try {
                let action
                //If image has been changed
                if (values.photoURL instanceof File) {
                    values.photoURL = await uploadImage(values.photoURL, `${PROFILE_IMAGES_PATH}/${data.uid}.png`)
                }
                if (data) {
                    let updatedValues = getObjectsDiff(values, data)
                    action = await updateUser({
                        ...updatedValues,
                        uid: data.uid,
                    })
                } else {
                    action = await createUser(values)
                }
                //Update profile

                if (!action.error) {
                    message.success(translate('common.changes.save.success'))
                    hideModal()
                } else {
                    throw action.payload
                }
                if (getTableData instanceof Function) {
                    getTableData()
                }
            } catch (err) {
                err = (err.response || {}).data || {}
                if (err.code && err.code == 'auth/email-already-exists') {
                    message.error(translate('signUp.duplicate.email'))
                } else {
                    message.error(translate('common.changes.save.error'))
                }
            }
            window.stopSpinning(spinningSelector)

        })
    }

    onSearch (searchText) {
        this.setState({
            dataSource: !searchText ? [] : [searchText, searchText.repeat(2), searchText.repeat(3)],
        })
    };

    onSelect (value) {
        console.log('onSelect', value)
    }

    onChange (value) {
        this.setState({value})
    };

    onChangeRadio (e) {
        console.log('radio checked', e.target.value)
        this.setState({
            value: e.target.value,
        })
    };

    onChangeDatePicker (date, dateString) {
        console.log(date, dateString)
    }

    onChangeSwitch (checked) {
        console.log(`switch to ${checked}`)
    }

    async onPayment(key,data){
        const {onPayment}=this.props;
        window.startSpinning(spinningSelector);
        try{
            const {error,payload}=await onPayment(key,data);
            if(error){
                throw payload.response;
            }
            message.success('Payment Successful');
        }catch(e){
            console.log(e);
            message.error('Error while processing payment');
        }
        window.stopSpinning(spinningSelector);
    }

    /**
     * Render Method
     * @returns {*}
     */
    render () {
        return (ComponentView.bind(this))()
    }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {
        createUser: (data) => {
            return dispatch(createAction(ActionNames.CREATE_ENTITY, {
                entityName: 'users',
                ...data
            }))
        },
        updateUser: (data) => {
            const entityId = data.uid
            delete data.uid
            return dispatch(createAction(ActionNames.UPDATE_ENTITY, {
                entityName: 'users',
                entityId,
                ...data
            }))
        },
        getRoles: () => {
            const action = createAction(ActionNames.GET_ENTITIES, {
                entityName: 'roles'
            })
            action.type = action.type + '_roles'
            return dispatch(action)
        }
    }
}
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({roles}) => {

    return {
        roles: roles.data
    }
}
Main.displayName = 'User-Form'
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
    connect: [mapStateToProps, bindAction],
    localize: true
})
