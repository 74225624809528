import React from "react";
import "./styles.scss";
import { Input, Button, Form } from "antd";

const { Item } = Form;
/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
  const { form, translate } = this.props;
  const { getFieldDecorator } = form;
  const { password, verificationCode } = this.validations;
  return (
    <div className="general-form verify">
      <div className="content">
        <div className="form">
          <div className="fields">
            <p className="title">
              {translate("common.verification.placeholder")}
            </p>
            <Form onSubmit={this.handleSubmit.bind(this)}>
              <Item>
                {getFieldDecorator(
                  "verificationCode",
                  verificationCode
                )(
                  <Input
                    maxLength={200}
                    className="input"
                    placeholder="Security Code"
                  />
                )}
              </Item>
              <Item>
                {getFieldDecorator(
                  "password",
                  password
                )(
                  <Input
                    maxLength={200}
                    placeholder="New Password"
                    type="password"
                    className="input"
                  />
                )}
              </Item>
              <br />
              <div className="action">
                <Button
                  htmlType={"submit"}
                  size="large"
                  className="btn green-btn"
                >
                  {translate("common.submit")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default view;
