import React, { useEffect, useState } from 'react'
import preProcess from 'containers/preprocess'
import './styles.scss'
import { Tooltip } from 'antd'
import { createAction, ActionNames } from 'app-redux/actions'

/**
 * @description User-Level Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({user}) => {
    return user && user.role === 'CUSTOMER' ? (
        <div className="level">
            <Tooltip title={user.levelDescription}>
                {/* <img src={level}/> */}
                <p className="shape">{user.level}</p>
            </Tooltip>
        </div>
    ) : null
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {}
}
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({user}) => {
    return {user}
}
Main.displayName = 'User-Level-Container'
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, bindAction],
    localize: true,
})
