import React, { useEffect, useState } from "react";
import preProcess from "containers/preprocess";
import "./styles.scss";
import { Popover, Tooltip } from "antd";
import Link from "core-components/link";
import point from "../../images/point.png";
import freePoint from "../../images/leaf.png";
import { createAction, ActionNames } from "app-redux/actions";
/**
 * @description Loyalty-Points Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ user, getPointBalance }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      if (user) {
        setLoading(false);
        await getPointBalance();
        setLoading(true);
      }
    })();
  }, []);
  const loyaltyPointsPopover = user && user.emailVerified ? (
    <div className="loyalty-points-popover">
      <div className="lp-header">
        <p>Loyalty Coins/Leaves</p>
      </div>
      <div className="lp-content">
        <ul>
          <li>
            <div className="points-count">
              <p className="text">Coins: </p>
              <div className="points">
                <img src={point} />
                <span className="value">{user.pointBalance}</span>
              </div>
            </div>
          </li>
          <li>
            <div className="points-count">
              <p className="text">Leaves: </p>
              <div className="points free">
                <img src={freePoint} />
                <span className="value">{user.freePointBalance}</span>
              </div>
            </div>
          </li>

          {user.role === "CUSTOMER" && (
            <li>
              <Link routeKey="request-points">Request Coins</Link>
            </li>
          )}
          {user.role === "MERCHANT" && (
            <li>
              <Link
                routeKey={
                  user.merchantType === "STANDARD" ? "packages" : "buy-points"
                }
              >
                Buy More Coins
              </Link>
            </li>
          )}
          {/* <li>
            <Link routeKey="performers">Top Performers</Link>
          </li> */}
        </ul>
      </div>
    </div>
  ) : null;
  return user && user.emailVerified ? (
    <Popover
      placement="bottomRight"
      content={loyaltyPointsPopover}
      trigger="click"
    >
      <div className="points-count">
        <Tooltip title="Coins">
          <div className="points">
            <img src={point} />
            <span className="value">{user.pointBalance}</span>
          </div>
        </Tooltip>
        <Tooltip title="Leaves">
          <div className="points free">
            <img src={freePoint} />
            <span className="value">{user.freePointBalance}</span>
          </div>
        </Tooltip>
      </div>
    </Popover>
  ) : null;
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    getPointBalance: () => {
      const action = createAction(ActionNames.GET_ENTITY, {
        entityName: "points-request",
        entityId: "getPointBalance",
      });
      action.type = action.type + "_points-request";
      return dispatch(action);
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user }) => {
  return { user };
};
Main.displayName = "Loyalty-Points-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
