import React from 'react'
import './styles.scss'
import bg from '../../images/bgg.png'

var view = function () {
    const {title, children, size, background} = this.props
    return (
        <div className={'banner ' + (size === 'large' ? 'large' : null)}
             style={{
                 'backgroundImage': background ? 'url(' + background + ')' : 'url(' + bg + ')',
             }}
        >
            <div className="overlay"/>
            <div className="container">
                {
                    children ? (
                        <div className="content">
                            {children}
                        </div>

                    ) : <div className="content">
                        <p className="title">{title}</p>
                        <p className="line"/>
                    </div>
                }
            </div>
        </div>
    )
}
export default view
