/**
 * Contains Routing for the application
 */
import React from 'react'
import { Route, IndexRoute, Router, Redirect } from 'react-router'
import { history } from './redux/config'
import { browserHistory } from 'react-router'
import About from './containers/about'
import Crud from './containers/crud'
import ConfirmEmail from './containers/confirmEmail'
import Concept from './containers/concept'
import Catalogue from './containers/catalogue'
import Cart from './containers/cart'
import Checkout from './containers/checkout'
import Dashboard from './containers/dashboard'
import Demos from './containers/demos'
import EmailNotConfirm from './containers/emailNotConfirm'
import Error404 from './containers/errors/404'
import Error401 from './containers/errors/401'
import Error500 from './containers/errors/500'
import ForgotPassword from './containers/forgotPassword'
import Home from './containers/home'
import Landing from './containers/landing'
import Login from './containers/login'
import Main from './containers/main'
import NewsFeed from './containers/newsfeed'
import OrderConfirmation from'./containers/orderConfirmation'
import OrderDetails from'./containers/orderDetails'
import OrderHistory from'./containers/orderHistory'
import PostDetails from './containers/postDetails'
import Packages from './containers/packages'
import Performers from './containers/performers'
import Payment from './containers/payment'
import Profile from './containers/profile'
import ProductDetails from './containers/productDetails'
import RequestPoints from './containers/requestPoints'
import Register from './containers/register'
import ResetPassword from './containers/resetPassword'
import VerifyOtp from './containers/verifyOtp'
import Settings from './containers/settings'
import Support from './containers/support'
import Social from './containers/social'
import UnderConstruction from './containers/underConstruction'
import StaticPage from './containers/static-page'
import BuyPoints from 'containers/buy-points';
import { ModalUtils } from 'core-components/modal'
import { compile } from 'path-to-regexp'

import CrudEntityRoutes from './crud-entity-config'

//Import Modals here
import CrudForm from 'containers/crud-form'
import UserForm from 'containers/forms/user-form'
import RoleForm from 'containers/forms/role-form'
import ConfigurationForm from 'containers/forms/configuration-form'
import GatewayForm from 'containers/forms/payment-gateway-form'
import StaticPageForm from 'containers/forms/static-page-form'
import PdfTemplateForm from 'containers/forms/pdf-template-form'
import TemplateForm from 'containers/forms/template-form'
import MediaForm from 'containers/forms/media-form'
import PageConfigurationForm from 'containers/forms/system-page-form'
import LanguageForm from 'containers/forms/language-form'
import SMSTemplateForm from 'containers/forms/sms-form'
import ThemeForm from 'containers/forms/theme-form'
import PermissionForm from 'containers/forms/permission-form'
import NewsfeedForm from 'containers/forms/newsfeed-form'
import ProductForm from 'containers/forms/product-form'
import PostForm from 'containers/forms/post-form'
/**
 * Route keys & Paths Map
 * @type {{Object}}
 */
const MainRoute = {
    component: Main,
    children: {
        'home': {
            url: 'home',
            component: Home,
            routeProps: {
                header: 'secured'
            },
            children: {
                'dashboard': {
                    url: '',
                    component: Dashboard
                },
                'settings': {
                    url: 'settings',
                    component: Settings
                },
                'demos': {
                    url: 'demos',
                    component: Demos
                }
                // 'theme': {
                //     url: 'theming',
                //     component: Theming
                // }
            }
        },
        'dummy': {
            url: 'dummy',
            routeProps: {public: true},
            component: Login,
            children: {
                'child': {
                    url: 'child/:id',
                    routeProps: {public: true},
                    component: Login
                }
            }
        },
        'confirmEmail': {
            url: 'confirm-email',
            component: ConfirmEmail,
            routeProps: {public: true, guestOnly: true, header: false}
        },
        'emailNotConfirm': {
            url: 'email-not-confirm',
            component: EmailNotConfirm,
            routeProps: {header: 'secured'}
        },
        'login': {
            url: 'login',
            component: Login,
            routeProps: {
                public: true,
                guestOnly: true
            }
        },
        'Register': {
            url: 'signUp',
            component: Register,
            routeProps: {
                public: true,
                guestOnly: true
            }
        },
        'resetPassword': {
            url: 'reset-password',
            component: ResetPassword,
            routeProps: {
                public: true,
                guestOnly: true
            }
        },
        'forgotPassword': {
            url: 'forgot-password',
            component: ForgotPassword,
            routeProps: {
                public: true
            }
        },
        'verifyOtp': {
            url: 'verifyOtp',
            component: VerifyOtp,
            routeProps: {
                public: true
            }
        },
        'error404': {
            url: 'error404',
            component: Error404,
            routeProps: {public: true}
        },
        'error500': {
            url: 'error500',
            component: Error500,
            routeProps: {public: true},
            header: true
        },
        'error401': {
            url: 'error401',
            component: Error401,
            routeProps: {public: true}
        },
        'underConstruction': {
            url: 'underConstruction',
            component: UnderConstruction,
            routeProps: {public: true}
        },
        'landing': {
            url: 'landing',
            component: Landing,
            routeProps: {
                public: true,
                //guestOnly: true
            }
        },
        'about': {
            url: 'about',
            component: About,
            routeProps: {
                public: true
            }
        },
        'concept': {
            url: 'concept',
            component: About,
            routeProps: {
                public: true
            }
        },
        'services': {
            url: 'services',
            component: About,
            routeProps: {
                public: true
            }
        },
        'packages': {
            url: 'packages',
            component: Packages,
            routeProps: {
                //public: true
                role: 'MERCHANT'
            }
        },
        'buy-points': {
            url: 'buy-points',
            component: BuyPoints,
            routeProps: {
                //public: true
                role: 'MERCHANT'
            }
        },
        'performers': {
            url: 'performers',
            component: Performers,
            routeProps: {
                public: true
            }
        },
        'payment': {
            url: 'payment',
            component: Payment,
            routeProps: {
                //public: true
            }
        },
        'request-points': {
            url: 'request-points',
            component: RequestPoints,
            routeProps: {
                role : 'CUSTOMER'
            }
        },
        'newsfeed': {
            url: 'newsfeed',
            component: (props) => <NewsFeed title="Newsfeed" type="newsfeed" {...props}/>,
            routeProps: {
                public: true
            }
        },
        'guides': {
            url: 'guides',
            component: (props) => <NewsFeed title="Campaigns" type="concept" {...props}/>,
            routeProps: {
                public: true
            }
        },
        'postDetails': {
            url: 'post/:id',
            component: PostDetails,           
            routeProps: {
                public: true,
                getMetaTags : ({newsfeed_single},renderProps,req)=>{                                
                    return newsfeed_single ? [{
                        name : "og:image",
                        value : newsfeed_single.image
                    },{
                        name : "og:title",
                        value : newsfeed_single.title
                    }/*,{
                        name : "og:description",
                        value : newsfeed_single.title
                    }*/,{
                        name : "og:url",
                        value : req.protocol + '://' + req.get('host') + req.originalUrl
                    }] : [];
                },
            }
        },
        'support': {
            url: 'support',
            component: Support,
            routeProps: {
                public: true
            }
        },
        'profile': {
            url: 'profile',
            component: Profile,
        },
        'catalogue': {
            url: 'catalogue',
            component: Catalogue,
            routeProps: {
                public: true
            }
        },
        'product': {
            url: 'product',
            component: ProductDetails,
            routeProps: {
                public: true
            }
        },
        'cart': {
            url: 'cart',
            component: Cart,
            routeProps: {
                public: true
            }
        },
        'checkout': {
            url: 'checkout',
            component: Checkout,
            routeProps: {
                public: true
            }
        },
        'orderHistory': {
            url: 'orderHistory',
            component: OrderHistory,
            routeProps: {
                public: true
            }
        },

        'orderConfirmation': {
            url: 'orderConfirmation',
            component: OrderConfirmation,
            routeProps: {
                public: true
            }
        },
        'orderDetails': {
            url: 'orderDetails',
            component: OrderDetails,
            routeProps: {
                public: true
            }
        },
        'social': {
            url: 'social',
            component: Social,
            routeProps: {
                public: true
            }
        },
    }
}

/**
 * Configure Crud Entity Screens
 */
Object.keys(CrudEntityRoutes).map(key => {
    let config = {
        url: key,
        component: Crud,
        routeProps: {
            ...CrudEntityRoutes[key],
            ...MainRoute.children.home.routeProps
        }
    }

    if (CrudEntityRoutes[key].entityPage) {
        config.children = {}
        config.children['details'] = {
            url: ':id',
            ...CrudEntityRoutes[key].entityPage,
            ...MainRoute.children.home.routeProps
        }
    }
    MainRoute.children.home.children[key] = config
})

/**
 * Constructs a route based on route data and parent Route URL
 */
const constructRoutes = (routeObjects, routeUrl, parentKey, parentProps) => {
    routeObjects = routeObjects || {}
    const routeKeys = Object.keys(routeObjects)
    routeUrl = routeUrl || ''
    if (!routeKeys || !Array.isArray(routeKeys) || routeKeys.length <= 0) {
        return null
    }

    let output = routeKeys.map((routeKey, index) => {
        let route = routeObjects[routeKey]
        const routeProps = route.routeProps || {}
        let props = {
            key: index,
            /**
             * Create HOC to pass route props
             * disable createContainer to disable the creation of default div with class route-container
             * @param componentProps
             * @returns {*}
             */
            component: !route.to ? componentProps => {
                return route.createContainer === false ? <route.component {...componentProps} {...routeProps} /> : (
                    <div className={'route-container page'}>
                        <route.component {...componentProps} {...routeProps} />
                    </div>
                )
            } : undefined
        }
        if (route.url != '') {
            props.path = routeUrl + '/' + route.url
        }

        props = {
            ...props,
            ...routeProps,
            pageConfiguration: getPageConfiguration(props.path)
        }
        if (typeof parentProps != 'undefined') {
            props.header = parentProps.header || props.header
        }
        props.routeKey = parentKey ? parentKey + '.' + routeKey : routeKey
        return route.url !== '' ? (
            <Route {...props}>
                {route.to && <Redirect to={route.to}/>}
                {constructRoutes(route.children, props.path, routeKey, routeProps)}
            </Route>
        ) : (
            <IndexRoute {...props}>
                {constructRoutes(route.children, props.path, routeKey, routeProps)}
            </IndexRoute>
        )
    })
    return output
}

const createStaticRoutes = (staticPages) => {
    let pages = {};
    (staticPages || []).forEach((page, index) => {
        pages[page.route] = {
            url: page.route,
            component: StaticPage,
            routeProps: {
                html: page.html || '',
                title: page.title || '',
                public: true
            }
        }
    })
    return pages
}

/**
 * todo : might not work for nested urls
 */
export function getRouteUrls () {
    const children = getAllRoutes()
    let urls = []
    Object.keys(children).forEach(key => {
        const {url, children: routeChildren} = children[key]
        if (url && urls.indexOf(url) === -1) {
            urls.push(url)
        }
        if (routeChildren) {
            Object.keys(routeChildren).forEach(key => {
                const {url: childUrl} = routeChildren[key]
                if (url && childUrl) {
                    urls.push(url + '/' + childUrl)
                }
            })
        }
    })
    return urls
}

/**
 * Route Declarations
 * Set publicHeader = false to disable publicHeader
 * Set public = true to allow guest access to a route
 * @type {XML}
 */
export const routes = (store) => {
    const {staticPages} = window.app.config || {}
    MainRoute.children.staticPages = {
        component: ({children}) => (<div>{children}</div>),
        url: 'staticPages',
        routeProps: {
            public: true
        },
        children: createStaticRoutes(staticPages)
    }
    return (
        <Router>
            <Route path="/" component={MainRoute.component}>
                {constructRoutes(MainRoute.children)}
                <Route path="*" component={Error404}/>
            </Route>
        </Router>
    )

}

/**
 * Returns the route link url for a given route key
 * @param key
 * @returns {*}
 */
export function getRouteUrl (key, params) {

    if (!key) {
        return null
    }
    let links = (key || '').split('.')
    let url = ''
    let routes = getAllRoutes()
    if (!routes[links[0]]) {
        return null
    }
    links.map(link => {
        if (routes[link]) {
            if (url === '/') {
                url = url + routes[link].url
            } else {
                url = url + '/' + routes[link].url
            }
            //getLinkUrl(routes[link].url);
            routes = routes[link].children || {}
        } else {
            console.log(
                'No such route key present ' + link + ' while parsing routeKey - ' + key
            )
        }
    })
    url = url.replace('//', '/')
    if (params) {
        return compile(url)(params)
    } else {
        return url
    }
}

export function getLinkUrl (url) {
    let parts = url.split('(')
    if (parts.length > 1) {
        return parts[0]
    }
    parts = url.split(':')
    if (parts.length > 1) {
        return parts[0]
    }
    return url
}

/**
 * Returns the route Object route key
 * @param key
 * @returns {*}
 */
export function getRoute (key) {
    if (!key) {
        return null
    }
    let links = (key || '').split('.')
    let route = null
    let routes = getAllRoutes()
    if (!routes[links[0]]) {
        return null
    }
    links.map(link => {
        if (routes[link]) {
            route = routes[link]
            routes = routes[link].children || {}
        } else {
            console.log(
                'No such route key present ' + link + ' while parsing routeKey - ' + key
            )
            route = null
        }
    })
    return route
}

/**
 * Goes to a specific route
 * @param route
 * @param config - Config for Browser History
 */
export function goToRoute (route, config) {
    config = config || {}
    let url = getRouteUrl(route, config.routeParams || {})
    if (url && url != '') {
        if (config.forceRefresh) {
            window.location.pathname = url
            if (config.search) {
                window.location.search = config.search
            }
        } else {
            browserHistory.push({
                pathname: url,
                search: '',
                ...config
            })
        }
    } else {
        //404 Handling
        //browserHistory.push("/404");
        goToRoute('home')
    }
}

/**
 * Go Back
 */
export function goBack () {
    history.goBack()
}

/**
 * Add Listener to route change
 * it is the responsibility of the
 * component which adds listener to remove
 * listener using unlisten method that is
 * returned
 * @param listener
 * @returns {*}
 */
export function addOnRouteChange (listener) {
    if (history && listener) {
        return history.listen((action, location) => {
            /**
             * This is done because the current route in
             * react-redux-router prop routes in component is
             * not updated in time
             */
            setTimeout(listener.bind(this, action, location))
        })
    }
}

const Modals = {
    RoleForm,
    UserForm,
    ConfigurationForm,
    GatewayForm,
    StaticPageForm,
    TemplateForm,
    MediaForm,
    PdfTemplateForm,
    PageConfigurationForm,
    LanguageForm,
    SMSTemplateForm,
    ThemeForm,
    PermissionForm,
    NewsfeedForm,
    Payment,
    ProductForm,
    PostForm
}
Object.keys(CrudEntityRoutes).forEach((key, index) => {
    const entity = CrudEntityRoutes[key].entity
    const form = CrudEntityRoutes[key].form
    if (entity && form) {
        Modals[entity + 'Form'] = (props) => {
            return <CrudForm {...props} {...form} entity={entity}/>
        }
    }
})
setTimeout(() => {
    ModalUtils.setModalScenes(Modals)
})

function getAllRoutes () {
    return MainRoute.children
}

export function getPageConfiguration (path) {
    let config = ((window.app.config && window.app.config.pageConfigurations) || []).find((config) => {return ('/' + config.routeUrl) === path})
    config = config || {}
    let output = {};
    (config.properties || []).forEach(({name, value}) => {
        output[name] = value
    })
    return output
}