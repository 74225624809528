import React, { Component } from 'react'
import ComponentView from './view'
import preProcess from '../preprocess'
import { createAction, ActionNames } from '../../redux/actions'

/**
 * @description Home Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {

    /**
     * Container
     * @param props
     */
    constructor (props) {
        super(props)
        const {width} = window.screen
        this.state = {
            mode: width <= 762 ? 'horizontal' : 'vertical'
        }
    }

    /**
     * ComponentDidMount Hook
     */
    componentDidMount () {
    }

    /**
     * Render Method
     * @returns {*}
     */
    render () {
        return (ComponentView.bind(this))()
    }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {

        sampleAction: () => {
            return dispatch(createAction(ActionNames.SAMPLE_ACTION))
        }

    }
}
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({firebase, user}) => {
    return {
        auth: firebase.auth,
        user
    }

}
Main.displayName = 'Profile'
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, bindAction],
    localize: true,
    firebase: true
})
