import React from "react";
import { merchantTypes, packageTypes } from "../config";

export default {
  entity: "packages",
  entityName: "Packages",
  form: {
    elements: [
      {
        type: "text",
        name: "title",
        label: "packages.title.title",
        required: true,
      },
      {
        type: "select",
        name: "merchantType",
        label: "packages.title.merchantType",
        required: true,
        data: merchantTypes,
      },
      {
        type: "select",
        name: "packageType",
        label: "packages.title.type",
        required: true,
        data: packageTypes,
      },
      {
        type: "number",
        name: "points",
        label: "packages.title.points",
        required: true,
      },
      {
        type: "number",
        name: "pointValue",
        label: "packages.title.pointValue",
        required: true,
      },
    ],
  },
  pagination: {
    type: "server",
  },
  defaultSort: {
    sort: "createdAt",
    sortType: "asc",
  },
  columns: ({ sharedState: { translate } }) => [
    {
      title: translate("packages.title.title"),
      dataIndex: "title",
    },
    {
      title: translate("packages.title.merchantType"),
      dataIndex: "merchantType",
      render: (val) => <span className="captialize">{val}</span>,
    },
    {
      title: translate("packages.title.type"),
      dataIndex: "packageType",
      render: (val) => <span className="captialize">{val}</span>,
    },
    {
      title: translate("packages.title.points"),
      dataIndex: "points",
    },
    {
      title: translate("packages.title.pointValue"),
      dataIndex: "pointValue",
    },
  ],
  search: {
    filters: [
      {
        title: "Title",
        type: "input",
        key: "title",
      },
    ],
    searchOnSubmit: true,
  },
};
