import React, { useState } from "react";
import preProcess from "containers/preprocess";
import "./styles.scss";
import { Button, message, Icon, Tooltip } from "antd";
import { createAction, ActionNames } from "app-redux/actions";
import ConfirmInput, { ConfirmForm } from "../../components/confirm-input";
import ModalTrigger from "../../components/modal/modal-trigger";
import Component from "../localize";

/**
 * @description Refresh Index
 * @type Container
 * @author Inderdeep
 */
const Main = function ({
  auto,
  entityId,
  entityName,
  apiRequest,
  data,
  onSuccess,
  onError,
  extraItems,
  getData
}) {
  const spinningSelector = auto ? ".main-container" : ".duplicate-form";
  const onSubmit = async (form) => {
    try {
      const { value, values, hideModal } = form || {};
      window.startSpinning(spinningSelector);
      let requestData = {
        id : entityId,
        entityName,
        ...data,
      };
      if (!auto) {
        requestData = {
          ...requestData,
          key: value,
          ...values,
        };
      }
      console.log({ requestData });
      const { payload } = await apiRequest(requestData);

      if (payload.status === 200) {
        message.success("Request initiated successfully");
      } else {
        throw payload;
      }
      window.stopSpinning(spinningSelector);
      if (!auto && hideModal instanceof Function) {
        hideModal();
      }
      if (onSuccess instanceof Function) {
        onSuccess();
      }
      if (getData instanceof Function) {
        getData();
      }
    } catch (e) {
      if (e.response.status === 409) {
        message.error("This key already exists in our system");
      } else {
        console.error("Error while initiating your request", e);
        message.error("Error while initiating your request");
      }
      window.stopSpinning(spinningSelector);
      if (onError instanceof Function) {
        onError();
      }
    }
  };
  const confirmConfig = {
    inputLabel: "Enter new Key",
    placeholder: "New Key",
    extraItems,
    onSubmit: ({ value, ...values }, { hideModal }) => {
      return onSubmit({ value, values, hideModal });
    },
  };
  const trigger = (
    <Tooltip title="Duplicate">
      <Button
        className="btn yellow-btn-text"
        onClick={
          auto
            ? () => {
                onSubmit();
              }
            : undefined
        }
      >
        <Icon type="copy" theme="outlined" />
      </Button>
    </Tooltip>
  );
  return !auto ? (
    <ModalTrigger
      content={<ConfirmForm {...confirmConfig} />}
      modalProps={{
        title: "Duplicate",
        footer: null,
        className: "duplicate-form",
      }}
    >
      {trigger}
    </ModalTrigger>
  ) : (
    trigger
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    apiRequest: (data) => {
      return dispatch(createAction(ActionNames.DUPLICATE_ENTITY, data));
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};
Main.displayName = "Duplicate-Entity";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
