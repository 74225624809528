import React from 'react';
export default {
  entity: "gateway",
  pagination: {
    type: "server"
  },
  defaultSort: {
    sort: "createdAt",
    sortType: "desc"
  },
  selection: false,
  addAction: false,
  deleteAction: false,
  entityName: "Payment Gateways",
  formId: "GatewayForm",
  search: false,
  columns: ({ sharedState: { translate } }) => [
    {
      title: translate('payment.key.label'),
      dataIndex: "key"
    }, {
      title: translate('payment.enabled.label'),
      dataIndex: 'enabled',
      render: enabled => {
        return (
          <p>{enabled ? translate('payment.enabled.label') : translate('payment.disabled')}</p>
        )
      }
    }
  ]
}