import React, { Component } from 'react'
import './styles.scss'
import { Tooltip } from 'antd'
import facebook from '../../images/fb.png'
import pinterest from '../../images/pinterest.png'
import linkedin from '../../images/linkedin.png'
import twitter from '../../images/tw.png'

/**
 * @description Share Component
 * @type Container
 * @author Inderdeep
 */
const Main = ({link, text}) => {
    return (
        <div className="share-actions">
            <ul>
                <li>
                    <Tooltip title="Share on Facebook">
                        <a target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=${link}`}>
                        <span className="social">
                            <img src={facebook}/>
                        </span>
                        </a>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip title="Share on Twitter">
                        <a target='_blank' href={`http://twitter.com/share?text=${text || ''}&url=${link}`}>
                        <span className="social">
                            <img src={twitter}/>
                        </span>
                        </a>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip title="Share on Pinterest">
                        <a target='_blank' href={`http://pinterest.com/pin/create/button/?url=${link}`}>
                        <span className="social">
                            <img src={pinterest}/>
                        </span>
                        </a>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip title="Share on LinkedIn">
                        <a target='_blank' href= {`https://www.linkedin.com/shareArticle?mini=true&url=${link}&source=LinkedIn`}>
                        <span className="social">
                            <img src={linkedin} className="linkedIn"/>
                        </span>
                        </a>
                    </Tooltip>
                </li>
            </ul>
        </div>
    )
}
Main.displayName = 'Share-Component'
//Pre process the container with Redux Plugins
export default Main