import React, { useState, useEffect } from 'react'
import './styles.scss'
import { Button, message } from 'antd'
import DropIn from 'braintree-web-drop-in-react'

/**
 * @description Braintree Component
 * @type Container
 * @author Inderdeep
 */
const Main = ({apiSecret, additionalData, onSuccess, ...props}) => {
    const [instance, setInstance] = useState(null)
    let [clientToken, setClientToken] = useState(apiSecret)
    let [response, setResponse] = useState(null)
    const fetchClientToken = async () => {
        if (additionalData && additionalData.getClientToken instanceof Function) {
            try {
                clientToken = await additionalData.getClientToken()
                setClientToken(clientToken)
            } catch (e) {
                console.error('Error while fetching client token ', {e})
            }
        }
        if (!clientToken) {
            console.error('Client Token not present for braintree')
        }
    }
    const purchase = async () => {
        if (instance) {
            // if (response) {
            //   onSuccess instanceof Function && onSuccess(response);
            //   return;
            // }
            try {
                const response = await instance.requestPaymentMethod()
                if (!response.nonce) {
                    message.error(
                        'There was a error while authorizing this payment method. Please select another payment method'
                    )
                    setResponse(null)
                    return
                }
                setResponse(response)
                onSuccess instanceof Function && onSuccess(response)
            } catch (e) {
                console.error('Error while getting nonce', {e})
            }
        }
    }
    useEffect(() => {
        fetchClientToken()
    }, [])
    return clientToken ? (
        <div className="braintree">
            <div className="select-method">
                <DropIn
                    options={{
                        ...props,
                        authorization: clientToken,
                        vaultManager: true,
                        ...(additionalData && additionalData.options
                            ? additionalData.options
                            : {}),
                    }}
                    onPaymentOptionSelected={() => {
                        setResponse(null)
                    }}
                    onInstance={(obj) => {
                        obj.on('paymentMethodRequestable', () => {
                            setResponse(null)
                        })
                        setInstance(obj)
                    }}
                />
            </div>
            {instance && (
                <div className="action">
                    <Button className="green-btn" onClick={purchase}>
                        Proceed
                    </Button>
                </div>
            )}
        </div>
    ) : null
}
Main.displayName = 'Braintree'
//Pre process the container with Redux Plugins
export default Main
