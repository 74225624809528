import React, { Component } from 'react'
import ComponentView from './view'
import preProcess from '../../preprocess'
import { Form, message } from 'antd/lib/index'
import { createAction, ActionNames } from '../../../redux/actions'
import { delay } from '../../../utils/common'
import { getRouteUrl } from '../../../routes';
const spinningSelector = '.general-form.login'

/**
 * @description Login Form Container
 * @type Container
 * @author Jasjot
 */
class Main extends Component {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {};
        this.toggleForm = this.toggleForm.bind(this);
        this.socialLogin = this.socialLogin.bind(this);
    }


    /**
     * toggleForm function switches between modals of join form
     * @param name
     */
    toggleForm(name) {
        const { setUiProperty } = this.props;
        setUiProperty({
            name: 'activeTab',
            value: name
        });
    }


    /**
     * Sets Validations for fields
     */
    setValidations() {
        const { translate } = this.props
        this.validations = {
            email: {
                rules: [
                    { required: true, message: translate('common.email.error.required') },
                    { type: 'email', message: translate('common.email.error.invalid') }
                ],
            },
            password: {
                rules: [{ required: true, message: translate('common.password.error.required') }],
            }
        }

    }

    /**
     * ComponentDidMount Hook
     */
    async componentDidMount() {
    }
    socialLogin(id) {
        const { routes } = this.props;
        const { guestOnly } = routes[routes.length - 1];
        if (guestOnly) {
            window.location.href = `/connect/${id}?nextPage=${getRouteUrl('home')}`;
        } else {
            window.location.href = `/connect/${id}?nextPage=${window.location.pathname}`;
        }
    }


    /**
     * On Submit of  Form
     * @param event
     */
    async handleSubmit(event) {
        event.preventDefault();
        const { validateFieldsAndScroll } = this.props.form;
        const { firebase, translate, emitter, getProfile } = this.props;
        validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return;
            }
            window.startSpinning(spinningSelector)
            if (values.rememberMe) {
                firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
            } else {
                firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
            }
            let loggedIn = false;
            let isDisabled = false;
            try {
                await firebase.login(values);
                loggedIn = true;
            } catch (e) {
                console.error({e});
                if(e.code === "auth/user-disabled"){
                    isDisabled = true;
                }
            }

            window.stopSpinning(spinningSelector);
            if (loggedIn) {
                emitter.emit('AUTHENTICATED');
                await delay(1);
                if(this.props.auth && this.props.auth.emailVerified){
                    message.success(translate('login.success'));
                }                
            } else {
                if(isDisabled){
                    message.error(translate('login.disabled'))
                } else {
                    message.error(translate('login.invalid'));
                }                
            }
        })

    }

    /**
     * Render Method
     * @returns {*}
     */
    render() {
        return (ComponentView.bind(this))()
    }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {
        setUiProperty: data => {
            return dispatch(createAction(ActionNames.UI_SET_PROPERTY, data));
        },
        login: data => {
            return dispatch(createAction(ActionNames.LOGIN, data));
        }
    }
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ emitter, ui,firebase }) => {
    return {
        emitter,
        ui,
       auth :  firebase.auth
    }
};
Main.displayName = 'Login-Form'
//Pre process the container with Redux Plugins
export default preProcess((Form.create())(Main), {
    connect: [mapStateToProps, bindAction],
    localize: true,
    firebase: true,
    withRouter: true
})
