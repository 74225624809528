import React, { Component } from 'react'
import preProcess from 'containers/preprocess'
import './styles.scss'
import { Row, Col, Button, Icon, Input, Radio, Tooltip } from 'antd'
import Link from 'core-components/link'
import point from '../../images/point.png'

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
    return (
        <div className="checkout">
            <div className="container">
                <div className="heading">
                    <div className="title">
                        <p>Checkout</p>
                    </div>
                    <div className="actions">
                        <Button className="continue green-btn-text">Continue Shopping</Button>
                        <Button className="checkout green-btn">Make Payment</Button>
                    </div>
                </div>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <div className="section">
                            <div className="flex center">
                                <p className="section-title">Select Address</p>
                                <Button type="text" className="green-btn-text add-address">Add new address</Button>
                            </div>

                            <div className="details flex shipping">
                                <div className="address">
                                    <p className="name">Name</p>
                                    <p><Icon type="phone"/> Mobile number</p>
                                    <p><Icon type="mail"/> email@email.com</p>
                                    <p><Icon type="environment"/> Address</p>
                                    <p><Icon type="pushpin"/> City/District , State , Pincode</p>
                                    <div className="edit-address">
                                        <p><Link>edit address</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section">
                            <p className="section-title">Delivery Options</p>
                            <div className="details delivery-options">
                                <Radio.Group defaultValue={1}>
                                    <div className="radio-button">
                                        <Radio value={1} id="r1"/>
                                        <div htmlFor="r1" className="text">
                                            <p className="bold">Standard Delivery</p>
                                            <p className="light">Delivered on or before Thursday, 2 May 2019</p>
                                            <p className="light"><Icon type="exclamation-circle"/>No delivery on public
                                                holidays. </p>
                                        </div>
                                        <div className="amount">
                                            <p>FREE</p>
                                        </div>
                                    </div>
                                    <div className="radio-button">
                                        <Radio value={2} id="r2"/>
                                        <div htmlFor="r2" className="text">
                                            <p className="bold">Express Delivery</p>
                                            <p className="light">Delivered on or before Monday , 6 May 2019</p>
                                        </div>
                                        <div className="amount">
                                            <p>$00.00</p>
                                        </div>
                                    </div>
                                </Radio.Group>
                            </div>
                        </div>

                        <div className="return-to-cart">
                            <Link routeKey="cart">
                                <Icon type="left"/> Return to cart
                            </Link>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <div className="section right">
                            <div className="flex">
                                <p className="section-title">Your order</p>
                                <p className="sub-title">edit shopping bag</p>
                            </div>
                            <div className="total-amount details">
                                <div className="promocode form flex">
                                    <Input type="text" placeholder="Coupon Code" className="input"/>
                                    <Button type="text" className="green-btn-text">Apply</Button>
                                </div>
                                <div className="redeem">
                                    <div className="flex">
                                        <p className="title">Redeem Points</p>
                                        <div className="points">
                                            <Tooltip title="Your Points">
                                                <img src={point}/>
                                                <span className="value">1234</span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="points-count form flex">
                                        <Input type="text" placeholder="Enter Number of Points" className="input"/>
                                        <Button type="text" className="green-btn-text">Redeem: $20</Button>
                                    </div>
                                </div>
                                <div className="redeem-success">
                                    <p><Icon type="check-circle"/> You have successfully redeemed 50 points</p>
                                </div>
                                <div className="billing">
                                    <div className="subtotal flex">
                                        <p>Item Subtotal</p>
                                        <p>$12345</p>
                                    </div>
                                    <div className="savings flex">
                                        <p>Your Savings</p>
                                        <p className="savings-amount">-$100</p>
                                    </div>
                                    <div className="savings flex">
                                        <p>Redeemed Amount <Link>(Remove)</Link></p>
                                        <p className="savings-amount">-$20</p>
                                    </div>
                                </div>
                                <div className="total flex">
                                    <p>Total</p>
                                    <p>$12345</p>
                                </div>
                                <div className="gift-code">
                                    <Link routeKey="payment">
                                        <Button type="text" className="green-btn">Continue</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {}
}
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {

    return {}
}
Main.displayName = 'Sample-Container'
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, null],
    localize: true
})
