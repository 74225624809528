import React from 'react'
import './styles.scss'
import { Form, Button, Checkbox, Input, Select } from 'antd'

const {Option} = Select
const {Item} = Form
const {Group} = Checkbox
var view = function () {
    this.setValidations()
    const {form, hideModal, data, translate} = this.props
    const {key, description, type, permissions, entity} = this.validations
    const {getFieldDecorator, getFieldValue} = form
    const typeValue = getFieldValue('type')
    return (
        <div className="form">
            <Form onSubmit={this.handleSubmit.bind(this)}>
                {
                    data ? <div>
                            <Item hasFeedback={true} label={translate('permission.key.label')}>
                                {
                                    getFieldDecorator('key', key)(
                                        <Input placeholder={translate('permission.key.placeholder')} disabled={true}/>
                                    )
                                }
                            </Item>
                            <Item hasFeedback={true} label={translate('permission.description.label')}>
                                {
                                    getFieldDecorator('description', description)(
                                        <Input placeholder={translate('permission.key.placeholder')}/>
                                    )
                                }
                            </Item>
                        </div> :
                        <div>
                            <Item hasFeedback={true} label='Type'>
                                {
                                    getFieldDecorator('type', type)(
                                        <Select className='select' placeholder={translate('permission.select.placeholder')}>
                                            {
                                                ['CRUD', 'Custom'].map((type, index) => {
                                                    return (
                                                        <Option value={type} key={index}>
                                                            {type}
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </Item>
                            {
                                typeValue === 'CRUD' && <div>
                                    <Item hasFeedback={true} label={translate('permission.permissions.label')}>
                                        {
                                            getFieldDecorator('permissions', permissions)(
                                                <Group
                                                    placeholder={translate('permission.permissions.placeholder')}
                                                >
                                                    {
                                                        ['Create', 'Read', 'Update', 'Delete'].map((name, index) => {
                                                            return (
                                                                <Checkbox key={index} value={name}>
                                                                    {name}
                                                                </Checkbox>
                                                            )
                                                        })
                                                    }
                                                </Group>
                                            )
                                        }
                                        <Checkbox onChange={this.onChange}>
                                            Select All
                                        </Checkbox>
                                    </Item>
                                    <Item hasFeedback={true} label={translate('permission.entity.label')}>
                                        {
                                            getFieldDecorator('entity', entity)(
                                                <Input className='input uppercase'
                                                       placeholder={translate('permission.entity.placeholder')}/>
                                            )
                                        }
                                    </Item>
                                </div>
                            }
                            {
                                typeValue === 'Custom' &&
                                <div>
                                    <Item hasFeedback={true} label={translate('permission.key.label')}>
                                        {
                                            getFieldDecorator('key', key)(
                                                <Input className='input uppercase'
                                                       placeholder={translate('permission.key.placeholder')}/>
                                            )
                                        }
                                    </Item>
                                    <Item label={translate('permission.description.label')}>
                                        {
                                            getFieldDecorator('description', description)(
                                                <Input className='input'
                                                       placeholder={translate('permission.description.placeholder')}/>
                                            )
                                        }
                                    </Item>
                                </div>
                            }

                        </div>
                }
                <div>
                    <div className="actions">
                        <Button htmlType={'submit'} type="primary"
                                className="btn green-btn">{translate('media.submit')}</Button>
                        <Button htmlType={'button'} onClick={hideModal}
                                className="btn red-btn-text">{translate('media.cancel')}</Button>
                    </div>
                </div>
            </Form>
        </div>
    )
}
export default view
