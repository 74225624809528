/**
 * This exports all the general client app configuration
 */

import * as TranslationsConfig from "./translations";

export const Translations = TranslationsConfig.default;
export { default as SocialSignIn } from "./social-signin";
export const PROFILE_IMAGES_PATH = "profileImages";
export const merchantTypes = [
  {
    label: "Partner Merchant",
    value: "PARTNER",
  },
  {
    label: "Standard Merchant",
    value: "STANDARD",
  },
];
export const packageTypes = [
  {
    label: "Start Up Package",
    value: "startup",
  },
  {
    label: "Top up Package",
    value: "topup",
  },
];


export const shippingTypes = [
  {
    label: "Fixed",
    value: "fixed",
  },
  {
    label: "Percentage",
    value: "percentage",
  },
  {
    label: "Api",
    value: "api",
  },
];


export const productStatus = [
  {
    "label": "Pending",
    "value": "Pending"
  }, {
    "label": "Approved",
    "value": "Approved"
  }
]

export const MERCHANT_PRE_CONFIRMATION_LINK = "https://site.ecopence.com/merchant-registration/";