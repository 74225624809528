import React, { useEffect, useState } from "react";
import "./styles.scss";
import Banner from "../../components/banner";
import { Row, Col, Icon, message } from "antd";
import { createAction, ActionNames } from "app-redux/actions";
import { getAmountInCurrency, convertCentsToCurrency } from "../container-helpers/common";
import preProcess from "containers/preprocess";
import point from "../../images/point.png";
import PaymentTrigger from "../payment-trigger";
import { goToRoute } from "../../routes";

/**
 * @description Packages Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({
  packages,
  getProfile,
  getPointBalance,
  user,
  purchasePackage,
  showAsComponent
}) => {
  const { setup, startupPackage, merchantType, role } = user;
  packages = packages.filter((item) => {
    return item.merchantType.toLowerCase() === merchantType.toLowerCase();
  });
  useEffect(() => {
    if (role !== "MERCHANT") {
      goToRoute("landing");
    } 
  }, []);
  if (role !== "MERCHANT") {
    return null;
  }
  const purchase = async (item, response) => {
    const packageId = item.key;
    const { key, data } = response;
    const { nonce, ...transactionData } = data;
    const { error } = await purchasePackage({
      packageId,
      transactionInfo: {
        nonce,
        method: key,
        data: transactionData,
      },
    });
    if (!error) {
      getPointBalance();
      if (item.packageType === "startup") {
        //Re get profile to update startup package
        getProfile();
      }
      message.success("Package purchased successfully");
      goToRoute("landing");
    } else {
      message.error("There was an error while processing your transaction");
    }
  };
  return (
    <div className="packages">
      <div className="content">
       {!showAsComponent && <Banner title={"Packages"} />}
        <div className="section">
          <div className="container">
            <div className="heading">
              <p className="title">
                {!startupPackage
                  ? "Please select a Startup Package to suit your needs"
                  : "Please select a Top Up Package to suit your needs"}
              </p>
            </div>
            <div className="list">
              <Row gutter={48}>
                {packages
                  .filter((item) => {
                    if (!!startupPackage) {
                      return item.packageType !== "startup";
                    } else {
                      return item.packageType === "startup";
                    }
                  })
                  .map((item, index) => {
                    const { points, pointValue, title } = item;
                    let amount = pointValue * points;
                    if (setup) {
                      amount = amount * (1 + setup);
                    }
                    amount = convertCentsToCurrency(amount);
                    amount = amount.toFixed();
                    return (
                      <Col xs={24} sm={12} md={8} lg={8} xl={6} key={index}>
                        <div className="package">
                          <div className="points">
                            {/* <img src={star}/>
                                            <span className="value">Best Selling</span> */}
                            <img src={point} />
                            <span className="value">{points}</span>
                          </div>
                          <div className="features">
                            <ul>
                              <li>{title}</li>
                              <li>{points} loyalty points</li>
                            </ul>
                          </div>
                          <div className="package-footer">
                            <div className="price">
                              <p>{getAmountInCurrency(amount)}</p>
                            </div>
                            <PaymentTrigger
                              amount={amount}
                              onSuccess={(response) => {
                                return purchase(item, response);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    purchasePackage: (data) => {
      return dispatch(
        createAction(ActionNames.CREATE_ENTITY, {
          entityName: "points-request/purchasePoints",
          ...data,
        })
      );
    },
    getPointBalance: () => {
      const action = createAction(ActionNames.GET_ENTITY, {
        entityName: "points-request",
        entityId: "getPointBalance",
      });
      action.type = action.type + "_points-request";
      return dispatch(action);
    },
    getProfile: (data) => {
      return dispatch(createAction(ActionNames.GET_PROFILE, data));
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ config, user }) => {
  const { packages } = config || {};
  return {
    packages: packages.packages,
    user,
  };
};
Main.displayName = "Packages-Container";
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
