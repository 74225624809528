export const TYPE_1 = 0;
export const TYPE_2 = 1;
export const TYPE_3 = 2;
export default {
  concept: [
    { type: TYPE_1 },
    { type: TYPE_2, count: 3 },
    { type: TYPE_3, data: ["bulb", "hourglass", "team", "thunderbolt"] },
  ],
  about: [
    { type: TYPE_1 },
    { type: TYPE_2, count: 3 },
    { type: TYPE_3, data: ["bulb", "hourglass", "team", "thunderbolt"] },
    { type: TYPE_1 },
    { type: TYPE_2, count: 3 }
  ],
  services: [
    { type: TYPE_1 },
    { type: TYPE_1 },
    { type: TYPE_1 },
    { type: TYPE_1 },
    { type: TYPE_1 },
    { type: TYPE_1 },
  ],
};
