import React from "react";
import { ModalTrigger } from "core-components/modal";
import { Button, Icon, Tooltip } from "antd";
import SeoForm from "../containers/seo-form";
import DuplicateForm from "containers/duplicate-entity";

export default {
  //addAction: false,
  //deleteAction: false,
  selection: false,
  entity: "page-configuration",
  entityName: "System Pages",
  columns: [
    {
      title: "URL",
      dataIndex: "routeUrl",
    },
    {
      title: "Page Title",
      dataIndex: "pageTitle",
    },
  ],
  pagination: {
    type: "server",
  },
  defaultSort: {
    sort: "createdAt",
    sortType: 'desc'
  },
  formId: "PageConfigurationForm",
  search: false,
  rowActions: [
    (props) => {
      const { record, getData } = props;
      const { key } = record;
      return (
        <DuplicateForm
          entityName="page-configuration"
          entityId={key}
          getData={getData}
          auto={true}
        />
      );
    },
    (props) => {
      const { record, getData } = props;
      const { key, metaTags } = record;
      return (
        <ModalTrigger
          modalProps={{
            title: "Meta Tags",
            footer: null,
            className: "have-form-sections",
          }}
          content={
            <SeoForm
              title="Manage Meta Tags"
              getData={getData}
              entityId={key}
              data={metaTags}
              getRules={(key, num, translate) => [
                {
                  required: true,
                  message: translate(
                    "systemPage.metaTags." + key + ".required"
                  ),
                },
              ]}
              entityName="page-configuration"
              fieldName="metaTags"
              fields={(translate) => [
                {
                  key: "name",
                  label: translate("systemPage.metaTags.name.label"),
                  placeholder: translate(
                    "systemPage.metaTags.name.placeholder"
                  ),
                },
                {
                  key: "value",
                  label: translate("systemPage.metaTags.value.label"),
                  placeholder: translate(
                    "systemPage.metaTags.value.placeholder"
                  ),
                },
              ]}
            />
          }
        >
          <Tooltip title="Manage Meta Tags">
            <Button className="btn yellow-btn-text">
              <Icon type="tags" />
            </Button>
          </Tooltip>
        </ModalTrigger>
      );
    },
    (props) => {
      const { record, getData } = props;
      const { key, properties } = record;
      return (
        <ModalTrigger
          modalProps={{
            title: "Properties",
            footer: null,
            className: "have-form-sections",
          }}
          content={
            <SeoForm
              title="Manage Properties for this page"
              getData={getData}
              entityId={key}
              data={properties}
              getRules={(key, num, translate) => [
                {
                  required: true,
                  message: translate(
                    "systemPage.metaTags." + key + ".required"
                  ),
                },
              ]}
              entityName="page-configuration"
              fieldName="properties"
              fields={(translate) => [
                {
                  key: "name",
                  label: translate("systemPage.metaTags.name.label"),
                  placeholder: translate(
                    "systemPage.metaTags.name.placeholder"
                  ),
                },
                {
                  key: "value",
                  label: translate("systemPage.metaTags.value.label"),
                  placeholder: translate(
                    "systemPage.metaTags.value.placeholder"
                  ),
                },
              ]}
            />
          }
        >
          <Tooltip title="Properties">
            <Button className="btn yellow-btn-text">
              <Icon type="radar-chart" />
            </Button>
          </Tooltip>
        </ModalTrigger>
      );
    },
  ],
};
