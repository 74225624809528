import React, { Component } from 'react'
import preProcess from 'containers/preprocess'
import './styles.scss'
import {} from 'antd'
import { Icon, Tooltip } from 'antd'
import Link from 'core-components/link'
import { getProfilePicture } from 'utils/firebase'
import { Avatar } from 'antd'
import PostActions from '../../containers/postActions'

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({data}) => {
    return (
        <div className="card">
            <Link routeKey="postDetails">
                <div className="content">
                    <div className="image">
                        <img src={data.image}/>
                    </div>
                    <div className="post-details">
                        <Avatar size={40} icon="user"/>
                        <div className="text">
                            <p className="title">{data.title}</p>
                            <div className="author">
                                <p className="name">{data.author}, {data.role}</p>
                            </div>
                            <div className="stats">
                                <p>{data.shares} Shares</p>
                                <p>2 days ago</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
            <PostActions/>
        </div>
    )
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
    return {}
}
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {

    return {}
}
Main.displayName = 'Sample-Container'
//Pre process the container with Redux Plugins
export default preProcess(Main, {
    connect: [mapStateToProps, null],
    localize: true
})
